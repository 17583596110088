import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Question from './Question';
import LoaderWithText from '../../utils/LoaderWithText';
import ExceededTimeModal from './ExceededTimeLimitModal';
import ExamRestrictions from './Restrictions';
import GuidelinesModal from './GuidelinesModal';
import JobDetailDrawer from '../JobDetailDrawer';
import { useHelperBot } from '../HelperBot';

// Constants
const API_BASE_URL = process.env.REACT_APP_DEV_API;
const WITHDRAW_REASON =
  'You had already attempted this assessment. Reloading was not allowed, and your application was rejected. Please contact HR if you believed this was an error.';

const MultiChoiceQuestions = () => {
  // Router hooks
  const { jobId, appId, testProcessId, orgName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // State management
  const [state, setState] = useState({
    questions: [],
    timeAllowedForQuestions: null,
    selectedOptions: {},
    loading: true,
    isGuidelineModalVisible: false,
    showSubmitModal: false,
    applicationStatus: '',
    isDrawerOpen: false
  });

  // Destructure state for easier access
  const {
    questions,
    timeAllowedForQuestions,
    selectedOptions,
    loading,
    isGuidelineModalVisible,
    showSubmitModal,
    applicationStatus,
    isDrawerOpen
  } = state;

  // Memoized values
  const testType = useMemo(
    () =>
      location.pathname.includes('aptitude')
        ? 'aptitude-mcq'
        : 'skill-based-mcq',
    [location.pathname]
  );

  const orgId = useMemo(() => JSON.parse(localStorage.getItem('org'))?.id, []);

  const { helperMessage } = useHelperBot();

  // API helper functions
  const getAccessToken = useCallback(() => {
    const session = localStorage.getItem('session');
    if (!session) {
      throw new Error('Access token not found in local storage');
    }
    return JSON.parse(session).accessToken;
  }, []);

  const apiRequest = useCallback(async (endpoint, options) => {
    const accessToken = getAccessToken();
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        ...options.headers
      }
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'API request failed');
    }

    return data;
  }, []);

  // Fetch test process
  const fetchCandidateTestProcess = useCallback(async () => {
    try {
      const data = await apiRequest(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}/candidate/test-process`,
        { method: 'GET' }
      );
      return data.data || [];
    } catch (error) {
      console.error('Fetch test process error:', error);
      toast.error(error.message);
      return [];
    }
  }, [orgId, jobId, appId, apiRequest]);

  // Check if MCQ test is already started
  const hasMCQTestAlreadyStarted = useCallback(async () => {
    const testDetails = await fetchCandidateTestProcess();
    if (!testDetails.length) {
      return false;
    }

    const testTypeToCheck =
      testType === 'aptitude-mcq'
        ? 'APTITUDE_MCQ_TEST'
        : 'SKILL_BASED_MCQ_TEST';

    const testDetailsToCheck = testDetails.find(
      (test) => test.testProcess.type === testTypeToCheck
    );

    return testDetailsToCheck?.status === 'IN_PROGRESS';
  }, [fetchCandidateTestProcess, testType]);

  // Handle withdraw application
  const handleWithdrawApplication = useCallback(async () => {
    try {
      await apiRequest(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}/withdraw-application`,
        {
          method: 'POST',
          body: JSON.stringify({ reason: WITHDRAW_REASON })
        }
      );

      toast.success('Application withdrawn successfully');
      navigate(`/${orgName}/appliedjob`);
    } catch (error) {
      console.error('Withdraw application error:', error);
      toast.error(error.message);
    }
  }, [orgId, jobId, appId, orgName, navigate, apiRequest]);

  // Fetch questions
  const fetchQuestions = useCallback(async () => {
    try {
      const data = await apiRequest(
        `/v1/organization/${orgId}/job/${jobId}/application/${appId}/candidate/test-process/${testProcessId}/start`,
        { method: 'POST' }
      );

      const fetchedQuestions = data.details.questions || [];
      setState((prev) => ({
        ...prev,
        questions: fetchedQuestions,
        timeAllowedForQuestions: fetchedQuestions.length * 30
      }));
    } catch (error) {
      console.error('Fetch questions error:', error);
      toast.error(error.message);
      navigate(`/${orgName}/404`);
    }
  }, [orgId, jobId, appId, testProcessId, orgName, navigate, apiRequest]);

  // Initialize test
  const initializeTest = useCallback(async () => {
    try {
      const isTestStarted = await hasMCQTestAlreadyStarted();

      if (isTestStarted) {
        await handleWithdrawApplication();
        return;
      }

      await fetchQuestions();
      setState((prev) => ({
        ...prev,
        loading: false,
        isGuidelineModalVisible: !localStorage.getItem('isReloaded')
      }));
    } catch (error) {
      console.error('Test initialization error:', error);
      toast.error('Failed to initialize test');
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [hasMCQTestAlreadyStarted, handleWithdrawApplication, fetchQuestions]);

  // Handle option change
  const handleOptionChange = useCallback((questionId, option) => {
    setState((prev) => ({
      ...prev,
      selectedOptions: {
        ...prev.selectedOptions,
        [questionId]: option
      }
    }));
  }, []);

  // Handle submit
  const handleSubmit = useCallback(
    async (e, isAutoSubmit = false) => {
      setState((prev) => ({ ...prev, loading: true }));
      toast.success('Submitting your answers, please wait.');

      try {
        const answers = questions.map((question) => {
          const baseAnswer = {
            questionId: question._id ?? question.question,
            answer: selectedOptions[question._id || question.question] || ''
          };

          if (question?.section && testType === 'aptitude-mcq') {
            return {
              question: question.question,
              answer: selectedOptions[question.question] || '',
              section: question.section
            };
          }

          return baseAnswer;
        });

        const data = await apiRequest(
          `/v1/organization/${orgId}/job/${jobId}/application/${appId}/candidate/test-process/${testProcessId}/submit/${testType}`,
          {
            method: 'POST',
            body: JSON.stringify({ answers })
          }
        );

        setState((prev) => ({
          ...prev,
          loading: false,
          applicationStatus: isAutoSubmit ? data.status : prev.applicationStatus
        }));

        if (!isAutoSubmit) {
          navigate(
            `/${orgName}/analyze-candidate/MCQ?jobId=${jobId}&appId=${appId}`
          );
        }
      } catch (error) {
        console.error('Submit error:', error);
        toast.error(error.message);
        setState((prev) => ({ ...prev, loading: false }));
      }
    },
    [
      questions,
      selectedOptions,
      testType,
      orgId,
      jobId,
      appId,
      testProcessId,
      orgName,
      navigate,
      apiRequest
    ]
  );

  // Effects
  useEffect(() => {
    helperMessage(
      "You're on fire! Just one last little challenge—let's nail this test, and we're all set. You've got this!"
    );
  }, [helperMessage]);

  useEffect(() => {
    initializeTest();
  }, [initializeTest]);

  useEffect(() => {
    if (showSubmitModal) {
      handleSubmit(null, true);
    }
  }, [showSubmitModal, handleSubmit]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && isGuidelineModalVisible) {
        setState((prev) => ({ ...prev, isGuidelineModalVisible: false }));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isGuidelineModalVisible]);

  // Handlers
  const handleNavigationForAutoSubmit = useCallback(() => {
    if (applicationStatus) {
      navigate(
        `/${orgName}/analyze-candidate/MCQ?jobId=${jobId}&appId=${appId}`
      );
    }
  }, [applicationStatus, orgName, jobId, appId, navigate]);

  const toggleDrawer = useCallback(() => {
    setState((prev) => ({ ...prev, isDrawerOpen: !prev.isDrawerOpen }));
  }, []);

  // Render helpers
  const renderLoader = () => (
    <div className="z-[999] fixed inset-0 flex justify-center items-center bg-white">
      <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin" />
    </div>
  );

  const renderHeader = () => (
    <div className="w-full sticky top-20 bg-white flex flex-row justify-between md:flex-row items-center md:items-center gap-6 mt-6 p-6 border-b-2 rounded-t">
      <div>
        <p className="text-base font-medium text-base-content">TalentSwap</p>
        <h6 className="mt-1 text-3xl font-medium text-base-content">
          Final Assessment Form
        </h6>
      </div>
      <div className="px-2">
        {timeAllowedForQuestions && (
          <LoaderWithText
            initialTime={timeAllowedForQuestions}
            onComplete={() =>
              setState((prev) => ({ ...prev, showSubmitModal: true }))
            }
            showColorChange={true}
          />
        )}
      </div>
    </div>
  );

  const renderQuestions = () => (
    <div className="mt-4 mb-4">
      {questions.map((question, index) => (
        <Question
          key={
            testType === 'skill-based-mcq' ? question._id : question.question
          }
          index={index + 1}
          questionId={
            testType === 'skill-based-mcq' ? question._id : question.question
          }
          question={question.question}
          options={question.options}
          selectedOption={
            selectedOptions[
              testType === 'skill-based-mcq' ? question._id : question.question
            ]
          }
          onOptionChange={handleOptionChange}
        />
      ))}
    </div>
  );

  const renderSubmitButton = () => (
    <div className="mt-8 flex flex-col md:flex-row items-center gap-5 md:gap-0 justify-center md:justify-between">
      <div />
      <button
        disabled={loading}
        type="button"
        onClick={() => handleSubmit()}
        className="bg-primary-600 w-fit hover:bg-primary-700 transition hover:duration-500 py-3 px-5 text-base font-semibold text-white rounded flex items-center gap-3"
      >
        {loading ? (
          <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin" />
        ) : (
          'Submit'
        )}
      </button>
    </div>
  );

  // Main render
  return (
    <>
      <div className="bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover h-[45vh] relative bg-primary-700" />

      {loading ? (
        renderLoader()
      ) : isGuidelineModalVisible ? (
        <GuidelinesModal
          closeModal={() =>
            setState((prev) => ({ ...prev, isGuidelineModalVisible: false }))
          }
        />
      ) : (
        <>
          <ExamRestrictions />
          <div className="container mx-auto px-5 md:px-0 -mt-[140px] relative z-[0] mb-20">
            <div>
              {renderHeader()}
              <div className="mt-[-30px] p-8 rounded-b bg-white shadow-lg">
                {renderQuestions()}
                {renderSubmitButton()}
              </div>
            </div>
          </div>
        </>
      )}

      {showSubmitModal && (
        <ExceededTimeModal handleSubmit={handleNavigationForAutoSubmit} />
      )}

      <button
        className="fixed right-0 top-1/2 transform -translate-y-1/2 bg-primary-600 text-white py-3 px-2 rounded-l-md z-50"
        style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}
        onClick={toggleDrawer}
      >
        Job Description
      </button>

      <JobDetailDrawer
        isOpen={isDrawerOpen}
        onClose={() => setState((prev) => ({ ...prev, isDrawerOpen: false }))}
      />
    </>
  );
};

export default MultiChoiceQuestions;
