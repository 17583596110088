import axios from 'axios';

const redirectToLogin = () => {
  const org = localStorage.getItem('org');
  const orgName = org ? JSON.parse(org).name : '';
  localStorage.clear();
  localStorage.setItem('openLoginModal', true);
  window.location.href = `/${orgName}`;
};

// Axios interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      redirectToLogin();
    }
    return Promise.reject(error);
  }
);

// Fetch Interceptor
const originalFetch = window.fetch;
window.fetch = async (url, options = {}) => {
  try {
    const response = await originalFetch(url, options);
    if (response.status === 401) {
      redirectToLogin();
    }
    return response;
  } catch (error) {
    console.error('Error in fetch interceptor', error);
  }
};
