import React, { useEffect, useRef, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const JobDetailDrawer = ({ isOpen, onClose }) => {
  const drawerRef = useRef(null);
  const orgId = JSON.parse(localStorage.getItem('org'))?.id;
  const { jobId, orgName } = useParams();
  const [jobData, setJobData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 10);
      document.body.style.overflow = 'hidden'; // Prevent background scrolling
    } else {
      setIsAnimating(true);
      setTimeout(() => {
        setIsVisible(false);
        setIsAnimating(false);
        document.body.style.overflow = ''; // Restore background scrolling
      }, 300);
    }

    return () => {
      document.body.style.overflow = ''; // Ensure scrolling is restored on unmount
    };
  }, [isOpen]);

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      onClose();
    }
  };

  const fetchJobDetails = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || 'An error occurred');
        return;
      }

      const data = await response.json();
      setJobData(data.data);
    } catch (error) {
      console.error('Error fetching Job details:', error);
      toast.error('Failed to fetch job details');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (jobId) {
      fetchJobDetails();
    }
  }, [jobId]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={`fixed inset-0 bg-black z-50 flex justify-end items-center transition-all duration-300 ease-in-out ${
        !isAnimating && isOpen ? 'bg-opacity-50' : 'bg-opacity-0'
      } ${isOpen ? '' : 'pointer-events-none'}`}
    >
      <div
        ref={drawerRef}
        className={`bg-white w-4/5 max-w-3xl h-full rounded-l-lg shadow-lg transform transition-transform duration-300 ease-in-out flex flex-col ${
          !isAnimating && isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-4 border-b border-base-content/10">
          <button
            onClick={onClose}
            className="text-xl flex items-center text-primary-600 hover:text-primary-700"
          >
            <FaArrowLeft className="mr-2" /> Close
          </button>
        </div>
        <div className="flex-grow overflow-y-auto p-8">
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : (
            <>
              <div>
                <p className="text-base font-medium text-base-content">
                  {orgName.charAt(0).toUpperCase() + orgName.slice(1)}
                </p>
                <h6 className="mt-1 text-3xl font-medium text-base-content">
                  {jobData?.title}
                </h6>
              </div>

              {jobData?.description && (
                <div className="mt-8 pb-8 border-b border-base-content/10">
                  <h6 className="mt-8 text-xl font-bold text-base-content">
                    Overview
                  </h6>
                  <p className="mt-3 text-base-content/80 text-justify">
                    {jobData.description}
                  </p>
                </div>
              )}

              {jobData?.responsibilities && (
                <div className="mt-8">
                  <h6 className="text-xl font-bold text-base-content mb-2">
                    Requirements
                  </h6>
                  <ul
                    className="ml-6 mt-3 flex flex-col gap-3 text-justify"
                    style={{ listStyle: 'disc' }}
                  >
                    {jobData.responsibilities.split('. ').map((resp, index) => (
                      <li key={index}>{resp}</li>
                    ))}
                  </ul>
                </div>
              )}

              {jobData?.qualifications && (
                <div className="mb-8 mt-8">
                  <h6 className="text-xl font-bold mb-2">Qualifications</h6>
                  <ul
                    className="ml-6 mt-3 flex flex-col gap-3 text-justify"
                    style={{ listStyle: 'disc' }}
                  >
                    {jobData.qualifications.split('. ').map((qual, index) => (
                      <li key={index}>{qual}</li>
                    ))}
                  </ul>
                </div>
              )}

              {Array.isArray(jobData?.skills) && jobData.skills.length > 0 && (
                <div className="mt-8">
                  <h6 className="text-xl font-bold text-base-content">
                    Skill &amp; Experience
                  </h6>
                  <p className="skills-container leading-7 mt-3 flex gap-1 flex-wrap">
                    {jobData.skills.map((skill, index) => (
                      <span
                        key={index}
                        className="skill-tag bg-primary-600 text-white px-2 py-1.5 rounded text-sm"
                      >
                        {skill}
                      </span>
                    ))}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetailDrawer;
