import React, { useEffect, useState } from 'react';
import {
  blockClicks,
  convertToRoman
} from '../../../../../utils/CommonFunctions';
import DragAndDrop from '../DragAndDrop';
import { toast } from 'react-toastify';
import { useHelperBot } from '../../../../HelperBot';

const Questionnaire = ({
  questionGroup,
  page,
  nextPage,
  previousPage,
  totalPages,
  negativeOptions,
  positiveOptions,
  answers,
  setAnswers,
  submitAnswers
}) => {
  const setOptions = (data1, data2) => {
    let newAnswers = [...answers];

    // Create a new copy of the current page's answer to avoid modifying the state directly
    let updatedAnswer = {
      ...newAnswers[page],
      positiveOptions: data1,
      negativeOptions: data2
    };

    // Update the specific page's answer
    newAnswers[page] = updatedAnswer;
    setAnswers(newAnswers);
  };

  // const [positiveOptions, setPositiveOptions] = useState([]);
  // const [negativeOptions, setNegativeOptions] = useState([]);
  const [internalPage, setInternalPage] = useState(0);

  const [fadeOutItems, setFadeOutItems] = useState([]);

  const [hasSortedOnce, setHasSortedOnce] = useState(false);

  const hasSorted = () => {
    setHasSortedOnce(true);
  };
  const { helperMessage } = useHelperBot();
  useEffect(() => {
    if (internalPage == 0) {
      let arr = [
        'Okay, next up, we’re diving into some deeper stuff. For each question, pick which options feel positive and which feel negative to you. Take your time—this is all about how you see the world.',
        'This part’s a bit different, but don’t worry! Just go with what feels right for you.',
        'Great job! We’re almost there—just a couple more questions to go.'
      ];
      helperMessage(arr[Math.floor(Math.random() * arr.length)]);
      return;
    }
    if (internalPage == 1) {
      let arr = [
        'Now that you’ve sorted the options into positive and negative, let’s dig a little deeper. Just rank each list from most to least important. You’re almost at the finish line!',
        'You’re doing fantastic! This is the final step, and it’ll really help us understand what makes you tick.',
        'Almost there—just a few more rankings to go, and we’ll be all set. You’re nailing this!'
      ];
      helperMessage(arr[Math.floor(Math.random() * arr.length)]);
      return;
    }
  }, [internalPage, page]);

  useEffect(() => {
    const allOptionsValid = questionGroup.options.every(
      (option) =>
        positiveOptions.includes(option) || negativeOptions.includes(option)
    );
    if (allOptionsValid) {
      setInternalPage(1);
    }
  }, [positiveOptions, negativeOptions]);

  const nextInternalPage = () => {
    if (internalPage === 1) {
      //questions end
      if (positiveOptions.length == 0) {
        toast.error('Atlease 1 item is required in positive column.');
        return;
      }
      if (negativeOptions.length == 0) {
        toast.error('Atlease 1 item is required in negative column.');
        return;
      }
      if (hasSortedOnce === false) {
        toast.error('Kindly sort the options.');
        return;
      }
      if (totalPages - 1 === page) {
        submitAnswers();
        return;
      }
      nextPage();
      setHasSortedOnce(false);
      setInternalPage(0);
    } else {
      const allOptionsValid = questionGroup.options.every(
        (option) =>
          positiveOptions.includes(option) || negativeOptions.includes(option)
      );
      if (allOptionsValid) {
        setInternalPage(1);
      } else {
        toast.error('All items must be selected to Proceed');
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const previousInternalPage = () => {
    if (internalPage === 0) {
      if (page == 0) {
        return;
      }
      previousPage();
      setInternalPage(1);
    } else {
      setInternalPage(0);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // const handleSelection = (item, selection) => {
  //   // Handle the logic when an item is selected as positive or negative
  //   let temp = [];
  //   console.log(`${item} was rated as ${selection}`);
  //   if (positiveOptions.includes(item)) {
  //     temp = [...positiveOptions?.filter((value) => item !== value)];
  //     // setPositiveOptions(positiveOptions.filter((value) => item !== value));
  //   }
  //   if (negativeOptions.includes(item)) {
  //     temp = [...negativeOptions?.filter((value) => item !== value)];
  //     // setNegativeOptions(negativeOptions.filter((value) => item !== value));
  //   }
  //   if (selection === "positive") {
  //     setPositiveOptions([...temp, item]);
  //   } else {
  //     setNegativeOptions([...temp, item]);
  //   }
  // };

  const handleSelection = (item, selection) => {
    let tempPositive = positiveOptions.length ? [...positiveOptions] : [];
    let tempNegative = negativeOptions.length ? [...negativeOptions] : [];

    if (positiveOptions.includes(item)) {
      // setPositiveOptions([
      //   ...positiveOptions.filter((value) => item !== value),
      // ]);
      tempPositive = [...positiveOptions.filter((value) => item !== value)];
    }

    if (negativeOptions.includes(item)) {
      // setNegativeOptions([
      //   ...negativeOptions.filter((value) => item !== value),
      // ]);
      tempNegative = [...negativeOptions.filter((value) => item !== value)];
    }

    if (selection === 'positive') {
      const newPositiveOptions = positiveOptions.includes(item)
        ? positiveOptions.filter((value) => item !== value)
        : [...tempPositive, item];
      setOptions(newPositiveOptions, tempNegative);
    } else {
      const newNegativeOptions = negativeOptions.includes(item)
        ? negativeOptions.filter((value) => item !== value)
        : [...tempNegative, item];

      setOptions(tempPositive, newNegativeOptions);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };

  return (
    <>
      <div className="border-b-2 border-gray-300 pb-4 mb-4 max-sm:px-2">
        <h2 className="text-xl font-semibold">
          PART {convertToRoman(page + 1)} - {questionGroup.perspective}{' '}
          Perception
        </h2>

        {!internalPage ? (
          <>
            <p className="mt-2">
              {/* <span className="font-semibold">Q {page + 1}: </span> */}
              {questionGroup.question}
            </p>
            <ul className="list-disc ml-5 mt-2">
              <li>
                If you think the statement is{' '}
                <span className="text-green-600 font-semibold">positive</span>,
                click the{' '}
                <span className="text-green-600 font-semibold">(+)</span>.
              </li>
              <li>
                If you think the statement is{' '}
                <span className="text-red-600 font-semibold">negative</span>,
                click the{' '}
                <span className="text-red-600 font-semibold">(-)</span>.
              </li>
              <li>
                Once you select a positive or negative, the statement will
                disappear. You can adjust your selections on the next screen, if
                desired.
              </li>
              <li>
                Once you finish your selections, you will automatically be
                directed to the next screen.
              </li>
            </ul>
          </>
        ) : (
          <>
            <p className="mt-2">
              {questionGroup.perspective == 'General' ? (
                <>
                  Rank the items below{' '}
                  <strong>using a general perspective</strong>. These should{' '}
                  <strong>NOT</strong> be ranked by your view of them within
                  your own personal life.
                </>
              ) : (
                <>
                  Rank the items below{' '}
                  <strong>using your personal perspective</strong>. These should
                  be ranked by your view of them within your own personal life.
                </>
              )}
              {/* The statements you choose as positive are in green list. The statements you choose as negative are in red list. */}
            </p>
          </>
        )}
      </div>

      <form id="page1-form" onSubmit={handleSubmit}>
        {!internalPage ? (
          <>
            <div className="space-y-4 p-2">
              {questionGroup.options
                .filter(
                  (item) =>
                    !(
                      positiveOptions.includes(item) ||
                      negativeOptions.includes(item)
                    )
                )
                .map((option, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex items-center justify-between bg-gray-50 p-4 rounded-lg shadow-md transition-opacity duration-500 ${
                        fadeOutItems.includes(option)
                          ? 'opacity-0'
                          : 'opacity-100'
                      }`}
                    >
                      <span
                        className={`text-md ${
                          positiveOptions.includes(option)
                            ? 'text-green-500'
                            : ''
                        } ${
                          negativeOptions.includes(option) ? 'text-red-500' : ''
                        }`}
                      >
                        {option}
                      </span>
                      <div className="flex space-x-4">
                        <button
                          type="button"
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg"
                          onClick={() => {
                            setFadeOutItems([...fadeOutItems, option]);
                            blockClicks(500);

                            // You might also want to remove the item completely after the fade-out effect
                            setTimeout(() => {
                              // Remove the option from the list, or perform any other action
                              handleSelection(option, 'positive'); // or "negative"
                            }, 500);

                            // handleSelection(option, "positive");
                          }}
                        >
                          +
                        </button>
                        <button
                          type="button"
                          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg"
                          onClick={() => {
                            setFadeOutItems([...fadeOutItems, option]);
                            blockClicks(500);

                            // You might also want to remove the item completely after the fade-out effect
                            setTimeout(() => {
                              // Remove the option from the list, or perform any other action
                              handleSelection(option, 'negative'); // or "negative"
                            }, 500);
                            // handleSelection(option, "negative")
                          }}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            {/* <div className="flex justify-between mt-2">
              <div className="w-1/2 pr-2">
                <h3 className="text-lg font-semibold text-green-600">
                  MOST positive at the top
                </h3>
                <div className="bg-gray-50 p-4 rounded-lg shadow-md space-y-2">
                  {positiveOptions.map((item) => (
                    <div
                      key={item}
                      className="flex items-center justify-between"
                    >
                      <span className="text-md">{item}</span>
                      <button
                        type="button"
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded-lg"
                        onClick={() => handleSelection(item, "negative")}
                      >
                        →
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-1/2 pl-2">
                <h3 className="text-lg font-semibold text-red-600">
                  MOST negative at the top
                </h3>
                <div className="bg-gray-50 p-4 rounded-lg shadow-md space-y-2">
                  {negativeOptions.map((item) => (
                    <div
                      key={item}
                      className="flex items-center justify-between"
                    >
                      <span className="text-md">{item}</span>
                      <button
                        type="button"
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-lg"
                        onClick={() => handleSelection(item, "positive")}
                      >
                        {" "}
                        ←
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
            <DragAndDrop
              positiveOptions={positiveOptions}
              negativeOptions={negativeOptions}
              setOptions={setOptions}
              hasSorted={hasSorted}
            />
          </>
        )}
        {internalPage === 0 ? (
          <></>
        ) : (
          <div className="flex justify-between mt-8 px-2">
            <div></div>
            {/* <button
            type="button"
            className={`bg-gray-300 ${
              (page == 0 && internalPage == 0) || internalPage === 1
                ? ""
                : "hover:bg-gray-400"
            }  text-gray-800 font-bold py-2 px-4 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed`}
            disabled={(page == 0 && internalPage == 0) || internalPage === 1}
            onClick={previousInternalPage}
          >
            Previous
          </button> */}
            <button
              type="submit"
              className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded-lg"
              onClick={nextInternalPage}
            >
              Next
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default Questionnaire;
