import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react';
import './styles.css';
import botImg from '../../images/bot.png';
import { useDispatch, useSelector } from 'react-redux';

const data = {
  job_listing: {
    url: '/',
    helperText: [
      'Hey! Let’s find something awesome for you. Use the filters or just type what you’re looking for—I’m right here with you. Ready?'
    ]
  },
  job_detail: {
    url: 'job',
    helperText: [
      'This one looks interesting, doesn’t it? Take your time to check it out, and if it feels right, hit that apply button. I’ve got your back!'
    ]
  },
  loginPrompt: {
    url: 'job',
    modal: 'loginModal',
    helperText: [
      'Okay, we’re almost there! Just log in or sign up real quick so we can keep going. I promise it’ll only take a sec!'
    ]
  },
  resume_upload: {
    url: 'job',
    modal: 'resumeUploadModal',
    helperText: [
      'Alright, let’s get your resume in there! You can upload a new one or pick one you’ve used before. Don’t worry, I’ll wait!'
    ]
  },
  resume_analysis: {
    url: 'job',
    modal: 'resumeUploadModalAnalysing',
    helperText: [
      'Hang tight, I’m just giving your resume a quick look-over. We’re almost done!',
      'Still here? Great! I’m just making sure everything looks perfect.',
      'Almost there, just putting on the final touches. You’re doing awesome!'
    ]
  },
  application_form: {
    url: 'application_form',
    helperText: [
      'I’ve filled in most of this for you—just a few more details, and we’re good to go. You’re so close!'
    ]
  },
  application_submission: {
    url: 'application_submission',
    helperText: [
      'I’m matching up your info with the job. Hang on just a little longer, we’re getting there!',
      'Still with me? Awesome! I’m just making sure you’re a great fit.',
      'Almost done! We’ll be moving on in just a sec.'
    ]
  },
  input_questions: {
    url: 'input_questions',
    helperText: [
      'Let’s knock out these quick questions together. The more you share, the better we can match you up. Ready? Let’s do this!'
    ]
  },
  skill_rating: {
    url: 'skill_rating',
    helperText: [
      'How do you feel about your skills? Rate yourself honestly—it’s just between us, and it’ll help a lot!'
    ]
  },
  skill_analysis: {
    url: 'skill_analysis',
    helperText: [
      'I’m checking your skills against the job. Hang tight, we’re almost through!',
      'Just making sure everything lines up. You’re doing great!',
      'Okay, almost there! We’ll be moving on in just a moment.'
    ]
  },
  mcq_test: {
    url: 'mcq_test',
    helperText: [
      'You’re on fire! Just one last little challenge—let’s nail this test, and we’re all set. You’ve got this!'
    ]
  },
  redirect_personality_test: {
    url: 'redirect_personality_test',
    helperText: [
      'We’ve got a few personality tests to help us understand you even better. Ready to dive in? Let’s go!'
    ]
  },
  disc_test: {
    url: 'disc_test',
    helperText: [
      'This first test is all about how you see yourself. For each question, just pick the option that feels the most like you and the one that feels the least like you. Easy, right? Let’s get started!',
      'Just 24 questions here—take your time and trust your gut. It’s all about getting a true picture of you!',
      'You’re doing great! Remember, there are no right or wrong answers, just what feels right to you.'
    ]
  },
  motivators_test: {
    url: 'motivators_test',
    helperText: [
      'Now, let’s explore what drives you! For each question, you’ll see a list of options. Just sort them from what you feel most strongly about to what matters least. No pressure, just go with your instincts!',
      'This is about what really motivates you, so be honest with yourself. You’ve got this!',
      'Keep going! These insights will help us find the roles where you’ll really thrive.'
    ]
  },
  hvp_test: {
    url: 'hvp_test',
    helperText: {
      first_part: [
        'Okay, next up, we’re diving into some deeper stuff. For each question, pick which options feel positive and which feel negative to you. Take your time—this is all about how you see the world.',
        'This part’s a bit different, but don’t worry! Just go with what feels right for you.',
        'Great job! We’re almost there—just a couple more questions to go.'
      ],
      second_part: [
        'Now that you’ve sorted the options into positive and negative, let’s dig a little deeper. Just rank each list from most to least important. You’re almost at the finish line!',
        'You’re doing fantastic! This is the final step, and it’ll really help us understand what makes you tick.',
        'Almost there—just a few more rankings to go, and we’ll be all set. You’re nailing this!'
      ]
    }
  }
};

// src/HelperBotContext.js

const HelperBotContext = createContext();

export const useHelperBot = () => {
  return useContext(HelperBotContext);
};

export const HelperBotProvider = ({ children }) => {
  const dispatch = useDispatch();
  // const isBotMessageVisible = useSelector((state) => state.isBotMessageVisible);
  // const isFirstRender = useSelector((state) => state.isFirstRender);
  const [notifications, setNotifications] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isBotMessageVisible, setIsBotMessageVisible] = useState(true);
  const [isManullyOpened, setIsManullyOpened] = useState(false);

  // useEffect(() => {
  //   console.log("isFirstRender:", isFirstRender);
  //   if (isFirstRender) {
  //     if (notifications.length) {
  //       console.log("render first time");
  //       // dispatch({ type: "SET_IS_FIRST_RENDER", payload: false });
  //       setIsFirstRender(false);
  //       showNotification();
  //       setTimeout(() => {
  //         removeNotification();
  //       }, [5000]);
  //     }
  //   }
  // }, [isFirstRender, notifications]);

  useEffect(() => {
    if (isBotMessageVisible) {
      if (notifications.length) {
        showNotification();
      }
    }
    // });
  }, [isBotMessageVisible]);

  const helperMessage = useCallback(
    (message, type = 'info', duration = 5000) => {
      const id = Math.random().toString(36).substr(2, 9);
      setNotifications([]);
      setNotifications((prev) => [...prev, { id, message, type }]);

      if (isManullyOpened) {
        return;
      }

      setTimeout(() => {
        removeNotification();
      }, duration);

      // setTimeout(() => {
      // setNotifications((prev) =>
      //   prev.filter((notification) => notification.id !== id)
      // );
      // }, duration);
    },
    []
  );

  const removeNotification = () => {
    // dispatch({ type: "SET_IS_BOT_MESSAGE_VISIBLE", payload: false });
    setIsBotMessageVisible(false);
    const messageContainer = document.querySelector('.messageContainer');
    if (!messageContainer) {
      return;
    }

    // messageContainer.style.transform = "translateY(100%)";
    messageContainer?.classList.remove('smooth-scale-fade-in');
    messageContainer?.classList.add('smooth-scale-fade-out');

    // const messageSubContainer = document.querySelector(".messageSubContainer");

    // messageSubContainer.classList.remove("fade-in");
    // messageSubContainer.classList.add("fade-out");

    setTimeout(() => {
      // setNotifications([]);
      messageContainer.classList.add('hidden');
    }, 800);
  };

  const showNotification = () => {
    // dispatch({ type: "SET_IS_BOT_MESSAGE_VISIBLE", payload: true });
    setIsBotMessageVisible(true);
    const messageContainer = document.querySelector('.messageContainer');

    if (!messageContainer) {
      return;
    }

    messageContainer?.classList.remove('hidden');
    messageContainer?.classList.remove('smooth-scale-fade-out');
    messageContainer?.classList.add('smooth-scale-fade-in');

    // const messageSubContainer = document.querySelector(".messageSubContainer");

    // messageSubContainer.classList.remove("fade-out");
    // messageSubContainer.classList.add("fade-in");
  };

  const toggleNotification = (e) => {
    e.stopPropagation();
    if (isBotMessageVisible) {
      removeNotification();
    } else {
      showNotification();
      setIsManullyOpened(true);
    }
  };

  // const closeMessage = (e) => {

  //   console.log("closeMessage called");
  //
  //   setTimeout(() => {
  //     // messageContainer?.style.display = "none";
  //     setNotifications([]);
  //   }, 700);
  // };

  const showAssistant = JSON.parse(localStorage.getItem('org'))?.showAssistant;

  return (
    <HelperBotContext.Provider value={{ helperMessage }}>
      {children}
      {showAssistant && (
        <div className="notification-container">
          {notifications.map((notification) => (
            <div
              class="bg-gray-100 flex justify-center items-center h-screen"
              key={notification.id}
            >
              <div class="fixed md:bottom-24 bottom-[68px] md:right-12 right-6 max-w-xs w-full smooth-scale-fade-in messageContainer message-bubble">
                <div class="bg-gradient-to-r from-[#39c38a] to-[#64dafb] text-white p-5 rounded-2xl shadow-lg relative fade-in messageSubContainer">
                  <button
                    class="absolute top-3 right-3 bg-white bg-opacity-20 hover:bg-opacity-40 text-white rounded-full w-6 h-6 flex items-center justify-center leading-none cursor-pointer transition-all"
                    onClick={(e) => {
                      e.stopPropagation(); // Stop event propagation
                      removeNotification(); // Dismiss the notification
                    }}
                  >
                    ×
                  </button>
                  <div class="text-base font-semibold pr-5">
                    {notification.message}
                  </div>
                  {/* <div class="mt-4 flex space-x-2">
                  <button
                    class="bg-white text-purple-500 font-semibold py-2 px-4 rounded-full shadow-md hover:bg-purple-500 hover:text-white transition-all"
                    onclick="selectOption('Help')"
                  >
                    Help
                  </button>
                  <button
                    class="bg-white text-purple-500 font-semibold py-2 px-4 rounded-full shadow-md hover:bg-purple-500 hover:text-white transition-all"
                    onclick="selectOption('Support')"
                  >
                    Support
                  </button>
                  <button
                    class="bg-white text-purple-500 font-semibold py-2 px-4 rounded-full shadow-md hover:bg-purple-500 hover:text-white transition-all"
                    onclick="selectOption('Chat')"
                  >
                    Chat
                  </button>
                </div> */}
                </div>
              </div>
            </div>
          ))}
          <img
            src={botImg}
            className="fixed bottom-2 right-2 md:h-24 h-16 cursor-pointer"
            onClick={toggleNotification}
          ></img>
        </div>
      )}
    </HelperBotContext.Provider>
  );
};

{
  /* <div
            key={notification.id}
            className={`notification ${notification.type}`}
          >
            {notification.message}
          </div> */
}
