import React, { useEffect, useState } from 'react';
import { convertToRoman } from '../../../../../utils/CommonFunctions';
import DragAndDrop from '../DragAndDrop';
import { toast } from 'react-toastify';

const Questionnaire = ({
  questionGroup,
  page,
  nextPage,
  previousPage,
  totalPages,
  orderedOptions,
  answers,
  setAnswers,
  submitAnswers
}) => {
  const setOptions = (data1) => {
    let newAnswers = [...answers];

    // Create a new copy of the current page's answer to avoid modifying the state directly
    let updatedAnswer = {
      ...newAnswers[page],
      orderedOptions: data1
    };

    // Update the specific page's answer
    newAnswers[page] = updatedAnswer;
    setAnswers(newAnswers);
  };
  const [hasSortedOnce, setHasSortedOnce] = useState(false);

  const hasSorted = () => {
    setHasSortedOnce(true);
  };

  const handleNext = () => {
    if (hasSortedOnce === false) {
      toast.error('Kindly sort the options.');
      hasSorted();
      return;
    }
    if (totalPages - 1 === page) {
      submitAnswers();
      return;
    }
    nextPage();
    setHasSortedOnce(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // const handleSelection = (item, selection) => {
  //   // Handle the logic when an item is selected as positive or negative
  //   let temp = [];
  //   console.log(`${item} was rated as ${selection}`);
  //   if (positiveOptions.includes(item)) {
  //     temp = [...positiveOptions?.filter((value) => item !== value)];
  //     // setPositiveOptions(positiveOptions.filter((value) => item !== value));
  //   }
  //   if (negativeOptions.includes(item)) {
  //     temp = [...negativeOptions?.filter((value) => item !== value)];
  //     // setNegativeOptions(negativeOptions.filter((value) => item !== value));
  //   }
  //   if (selection === "positive") {
  //     setPositiveOptions([...temp, item]);
  //   } else {
  //     setNegativeOptions([...temp, item]);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };

  return (
    <>
      <div className="border-b-2 border-gray-300 pb-4 mb-4 max-sm:px-2">
        <h2 className="text-xl font-semibold ">
          PART {convertToRoman(page + 1)}
        </h2>
      </div>

      <form id="page1-form" onSubmit={handleSubmit}>
        <DragAndDrop
          setOptions={setOptions}
          orderedOptions={orderedOptions}
          question={questionGroup.question}
          hasSorted={hasSorted}
        />

        <div className="flex justify-between mt-8 px-2">
          <div></div>
          <button
            type="submit"
            className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded-lg"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default Questionnaire;
