import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useParams,
  useNavigate
} from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import Nav from './components/Nav';
import Footer from './components/Footer';
import JobDetail from './components/JobDetail';
import { useSelector } from 'react-redux';
import UserJoblist from './components/JobList';
import Questions from './components/Questions';
import Ratings from './components/Ratings';
import MultiChoiceQuestions from './components/Multichoice';
import ProtectedRoute from './utils/ProtectedRoute';
import AcceptancePage from './components/AcceptancePage';
import RejectionPage from './components/RejectionPage';
import AppreciationPage from './components/AppreciationPage';
import LoaderPageToAnalyzeCandidate from './components/LoaderPageToAnalyzeCandidate';
import AppliedJobDetail from './components/AppliedJobDetail';
import analyzingDocs from './images/analyzingDocs.gif';
import Error404 from './utils/Error404';
import AppliedJobs from './components/AppliedJobs';
import setThemeColor from './utils/themeColors';
import DISCAssessment from './components/Assesments/PersonalityAssesments/DiscAssesment';
import HVPQuestionnaire from './components/Assesments/PersonalityAssesments/HVPQuestionnaire';
import MotivatorsQuestionnaire from './components/Assesments/PersonalityAssesments/MotivatorsQuestionnaire';
import PersonalityTestProgression from './components/AppliedJobDetail/PersonalityTestProgression';
import PersonalityAssesments from './components/Assesments/PersonalityAssesments';

const OrganizationLoader = () => {
  const { orgName } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const validateOrganization = async () => {
      try {
        const storedOrg = localStorage.getItem('org');
        if (storedOrg) {
          const parsedOrg = JSON.parse(storedOrg);
          if (parsedOrg.name !== orgName) {
            dispatch({ type: 'CLEAR_APPLIED_JOBS_OBJ' });
          }
        }

        const response = await axios.get(
          `${process.env.REACT_APP_DEV_API}/v1/organizationId/${orgName}`
        );
        if (response.data.message === 'Organization retrieved successfully') {
          const orgData = {
            id: response.data.data.id,
            name: orgName,
            themeColor: response.data.data.themeColor,
            horizontalLogo:
              response.data.data?.logo?.horizontalLogoSignedUrl || '',
            showAssistant: response.data.data.showAssistant
          };

          setThemeColor(response.data.data.themeColor.toLowerCase());
          localStorage.setItem('org', JSON.stringify(orgData));
          localStorage.setItem('currency', response.data.data.currency);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error validating organization:', error);
        navigate(`/${orgName}/404`);
      }
    };

    validateOrganization();
  }, [orgName, navigate]);

  if (isLoading) {
    return <div></div>;
  }

  return <Outlet />;
};

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preloadedAnalyzingDocs, setPreloadedAnalyzingDocs] = useState(null);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.removeItem('currentPage');
      localStorage.removeItem('search');
      localStorage.removeItem('prevFilters');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = analyzingDocs;
    img.onload = () => setPreloadedAnalyzingDocs(analyzingDocs);
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <Router>
      <Routes>
        <Route path="/:orgName" element={<OrganizationLoader />}>
          <Route element={<MainLayout onOpenModal={openModal} />}>
            <Route element={<ProtectedRoute allowedRoles={['CANDIDATE']} />}>
              <Route path="accept" element={<AcceptancePage />} />
              <Route
                path="appreciation-personality-test"
                element={<AppreciationPage />}
              />
              <Route
                path="appliedjob/:jobId/:appId"
                element={<AppliedJobDetail />}
              />
              <Route
                path="personality-tests/:personalityTestId"
                element={<PersonalityAssesments />}
              />
              <Route path="appliedjob/" element={<AppliedJobs />} />
              <Route path="reject" element={<RejectionPage />} />
              <Route
                path="disc-assessment/:personalityTestId"
                element={<DISCAssessment />}
              />
              <Route
                path="hvp-plus-assessment/:personalityTestId"
                element={<HVPQuestionnaire />}
              />
              <Route
                path="motivators-assessment/:personalityTestId"
                element={<MotivatorsQuestionnaire />}
              />
              <Route path="questions/:jobId/:appId" element={<Questions />} />
              <Route path="ratings/:jobId" element={<Ratings />} />
              <Route
                path="preassesment/:jobId/:appId/:testProcessId"
                element={<MultiChoiceQuestions />}
              />
              <Route
                path="aptitude/:jobId/:appId/:testProcessId"
                element={<MultiChoiceQuestions />}
              />
              <Route
                path="analyze-candidate/:panel"
                element={
                  <LoaderPageToAnalyzeCandidate
                    analyzingDocsGif={preloadedAnalyzingDocs}
                  />
                }
              />
            </Route>
            <Route index element={<UserJoblist />} />
            <Route path="job/:jobId" element={<JobDetail />} />
          </Route>
          <Route path="*" element={<Error404 />} />
          <Route path="404" element={<Error404 />} />
        </Route>
        <Route path="*" element={<Error404 />} />
        <Route path="/404" element={<Error404 />} />
      </Routes>
    </Router>
  );
}

function MainLayout({ onOpenModal }) {
  return (
    <>
      <div className="min-h-[100vh] relative">
        <Nav onOpenModal={onOpenModal} />
        <div className="pb-28">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
