import React, { useState, useEffect } from 'react';
import './Slider.css';

const Slider = ({
  min,
  max,
  minExperience,
  maxExperience,
  onSliderValueChange
}) => {
  const [minValue, setMinValue] = useState(minExperience || min);
  const [maxValue, setMaxValue] = useState(maxExperience || max);

  useEffect(() => {
    setMinValue(minExperience || min);
    setMaxValue(maxExperience || max);
  }, [minExperience, maxExperience, min, max]);

  const handleMinChange = (event) => {
    const value = Math.min(Number(event.target.value), maxValue - 1);
    setMinValue(value);
    onSliderValueChange({ minExperience: value, maxExperience: maxValue });
  };

  const handleMaxChange = (event) => {
    const value = Math.max(Number(event.target.value), minValue + 1);
    setMaxValue(value);
    onSliderValueChange({ minExperience: minValue, maxExperience: value });
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-md">
        <div className="flex justify-between mb-4 text-gray-700">
          <p>Min: {minValue}</p>
          <p>Max: {maxValue}</p>
        </div>
        <div className="relative w-full h-8">
          <div className="absolute w-full h-4 bg-gray-300 rounded-full"></div>
          <div
            className="absolute h-4 bg-primary-500 rounded-full"
            style={{
              left: `${((minValue - min) / (max - min)) * 100}%`,
              width: `${((maxValue - minValue) / (max - min)) * 100}%`
            }}
          ></div>
          <input
            type="range"
            min={min}
            max={max}
            value={minValue}
            onChange={handleMinChange}
            className="absolute w-full h-2 bg-transparent pointer-events-none slider-thumb"
            style={{ zIndex: minValue > max - 100 ? 1 : 0 }}
          />
          <input
            type="range"
            min={min}
            max={max}
            value={maxValue}
            onChange={handleMaxChange}
            className="absolute w-full h-2 bg-transparent pointer-events-none slider-thumb"
          />
        </div>
      </div>
    </div>
  );
};

export default Slider;
