export const getTextBasedOnTest = (test) => {
  if (test === 'DISC') {
    return (
      <p className="text-base text-gray-600">
        Understand your dominant behavioral traits such as Dominance, Influence,
        Steadiness, and Conscientiousness. Essential for leadership and
        teamwork.
      </p>
    );
  }
  if (test === 'Motivators') {
    return (
      <p className="text-base text-gray-600">
        Discover what drives you and motivates your decisions in various
        situations. Align your core values with your career goals.
      </p>
    );
  }
  if (test === 'HVP Plus') {
    return (
      <p className="text-base text-gray-600">
        Evaluate your values and how they influence your decision-making.
        Understand your ethical and moral compass for long-term success.
      </p>
    );
  }
};
