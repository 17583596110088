import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import jobSpecificDetails from '../../constants/jobSpecificDetails';

const JobcardWithStatus = ({
  job,
  title,
  appId,
  workLocationType,
  jobId,
  jobDescriptionSummary,
  minimumExperience,
  jobStatus
}) => {
  const navigate = useNavigate();
  const { orgName } = useParams();

  const handleApplyClick = () => {
    navigate(`/${orgName}/appliedjob/${jobId}/${appId}`, {
      state: {
        title,
        jobDescriptionSummary
      }
    });
  };

  return (
    <div className="shadow rounded p-6 border border-gray-300 bg-white group hover:bg-gray-50 transition-colors duration-500">
      <div className="flex flex-col gap-4 items-start sm:flex-row sm:items-center sm:justify-between">
        <div>
          <h2 className="mt-1 text-xl font-semibold text-gray-900">{title}</h2>
          <div className="mt-4 flex items-center gap-2.5 flex-wrap"></div>
        </div>
        <div className="flex" style={{ height: '50%' }}>
          <button
            className="flex items-center min-w-[122px] gap-1.5 py-2.5 px-4 bg-primary-600 rounded cursor-pointer group-hover:bg-primary-700 text-white group-hover:border-primary-600 transition-all duration-300"
            onClick={handleApplyClick}
          >
            View Details
          </button>
        </div>
      </div>
      <p className="mt-5 text-justify	">{jobStatus || ''}</p>
      <div className="flex justify-end">
        <span className="bg-transparent text-gray-400 w-fit rounded text-xs pt-4">
          Posted{' '}
          {jobSpecificDetails[
            jobSpecificDetails.length - 1
          ].valueRenderingFunction(
            job[jobSpecificDetails[jobSpecificDetails.length - 1].name]
          )}
        </span>
      </div>
    </div>
  );
};

export default JobcardWithStatus;
