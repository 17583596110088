import React, { useEffect, useState, useRef } from 'react';
import Jobcard from '../JobCard';
import { useLocation } from 'react-router-dom';
import Header from '../Header';
import {
  formatStringForFrontend,
  formatStringToCamelCase
} from '../../utils/CommonFunctions';
import Slider from '../../utils/Slider';
import { toast } from 'react-toastify';
import { RiEqualizerLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import JobcardWithStatus from '../JobCardWithStatus';
import { useParams, useNavigate } from 'react-router-dom';
import { CiFileOff } from 'react-icons/ci';
import noJobFound from '../../images/no-jobs-found.png';
import { useHelperBot } from '../HelperBot';

const availableFilters = {
  'Job Type': ['PERMANENT', 'CONTRACTUAL', 'INTERN'],
  Experience: 'Experience',
  'Work Hours': ['FULL_TIME', 'PART_TIME'],
  'Work Location': ['ON_SITE', 'REMOTE', 'HYBRID']
};
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const UserJoblist = () => {
  const dispatch = useDispatch();
  const { orgName } = useParams();
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [highlightedJobId, setHighlightedJobId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isPaginationInProgress, setIsPaginationInProgress] = useState(false);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
  const [search, setSearch] = useState('');
  const [prevSearch, setPrevSearch] = useState('');
  const [filters, setFilters] = useState({
    minExperience: 0,
    maxExperience: 20
  });
  const [prevFilters, setPrevFilters] = useState({
    minExperience: 0,
    maxExperience: 20
  });
  const [showFiltersInDropDown, setShowFiltersInDropDown] = useState(false);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [isJobAvailable, setIsJobAvailable] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const dropdownRef = useRef(null);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  const { helperMessage } = useHelperBot();
  useEffect(() => {
    helperMessage(
      'Hey! Let’s find something awesome for you. \n Use the filters or just type what you’re looking for—I’m right here with you. Ready?'
    );
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch({ type: 'SET_JOB_LIST_TOGGLE', payload: 'all' });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const isDefaultExperience =
      prevFilters.minExperience === 0 && prevFilters.maxExperience === 20;

    const hasPrevFilters = Object.keys(prevFilters).some(
      (key) =>
        key !== 'minExperience' && key !== 'maxExperience' && prevFilters[key]
    );

    const isExperienceChanged =
      prevFilters.minExperience !== 0 || prevFilters.maxExperience !== 20;

    setFiltersApplied(
      !isDefaultExperience || hasPrevFilters || isExperienceChanged
    );
  }, [prevFilters]);

  useEffect(() => {
    if (!loading) {
      const searchParams = new URLSearchParams(location.search);
      const scrollToJobId = location.state?.jobId;
      // const toJobId = location.state?.jobId;
      if (scrollToJobId) {
        const jobElement = document.getElementById(scrollToJobId);
        if (jobElement) {
          jobElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setHighlightedJobId(scrollToJobId);
          setTimeout(() => {
            navigate(location.pathname, { replace: true, state: {} });
            setHighlightedJobId(null);
          }, 1000);
        }
      }
    }
  }, [location, loading]);

  useEffect(() => {
    const fetchAllApplicationUserHaveAppliedFor = async () => {
      try {
        const session = localStorage.getItem('session');
        const accessToken = session ? JSON.parse(session).accessToken : null;

        if (!accessToken) {
          return;
        }
        let url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/applied-applications`;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        };
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.statusCode === 200) {
          let jobsObj = {};
          data?.data?.applications.forEach((application) => {
            jobsObj[application.job] = { ...application };
          });
          dispatch({ type: 'SET_APPLIED_JOBS_OBJ', payload: jobsObj });
        } else {
          console.error('Failed to fetch applications :', data.message);
        }
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };
    fetchAllApplicationUserHaveAppliedFor();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsFilterDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSliderValueChange = ({ minExperience, maxExperience }) => {
    handleFiltersChange('Experience', { minExperience, maxExperience });
  };

  const createFetchJobsURL = (url, customFilters = null, customSearch = '') => {
    const searchValue = customSearch ? customSearch : search;

    if (searchValue) {
      url += `&search=${searchValue}`;
    }
    let filtersToCheckFrom = customFilters ? customFilters : filters;
    Object.keys(filtersToCheckFrom).forEach((filter) => {
      if (!filtersToCheckFrom[filter] && filter !== 'minExperience') {
        return;
      }
      if (filter === 'minExperience' || filter === 'maxExperience') {
        url += `&${filter}=${filtersToCheckFrom[filter]}`;
      } else if (typeof filtersToCheckFrom[filter] === 'string') {
        url += `&${formatStringToCamelCase(filter)}=${
          filtersToCheckFrom[filter]
        }`;
      } else {
        const commaSeparatedArray = filtersToCheckFrom[filter].join(',');
        if (commaSeparatedArray) {
          url += `&${formatStringToCamelCase(filter)}=${encodeURIComponent(
            commaSeparatedArray
          )}`;
        }
      }
    });

    return url;
  };

  const fetchJobs = async (
    page = 1,
    customFilters = null,
    isFilter = false,
    customSearch = ''
  ) => {
    setCurrentPage(page);
    setLoading(true);
    if (isFilter) {
      setLoadingFilter(true);
    }
    try {
      let url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job?page=${page}&limit=10&status=ACTIVE&sortBy=createdAt&sortOrder=desc`;

      url = createFetchJobsURL(url, customFilters, customSearch);

      const response = await fetch(url);
      const data = await response.json();
      if (data.statusCode === 200) {
        setJobs(data.data);
        setTotalJobs(data.total);
        if (data.total > 0) {
          setIsJobAvailable(true);
        }
        setTotalPages(data.totalPages);
        setIsPaginationInProgress(false);
        if (search !== prevSearch) {
          setPrevSearch(search);
        }
        if (isFilter) {
          setPrevFilters({ ...customFilters });
        }
        if (isFilter) {
          toast.success('Filters Applied Successfully');
        }
        scrollToTop();
      } else {
        console.error('Failed to fetch jobs :', data.message);
        toast.error(data.message || 'Failed to fetch jobs');
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
      toast.error('Error fetching jobs');
    } finally {
      setLoading(false);
      if (isFilter) {
        setLoadingFilter(false);
      }
    }
  };

  useEffect(() => {
    if (!isPaginationInProgress && isInitialLoadDone) {
      fetchJobs(1, prevFilters);
    }
  }, [search]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1216) {
        setShowFiltersInDropDown(true);
      } else {
        setShowFiltersInDropDown(false);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const isPageReloaded =
      performance.getEntriesByType('navigation')[0].type === 'reload';

    const savedFilters = localStorage.getItem('prevFilters');
    const savedPage = localStorage.getItem('currentPage');
    const savedSearch = localStorage.getItem('search');

    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      setFilters(parsedFilters);
      setPrevFilters(parsedFilters);

      const isDefaultFilters =
        parsedFilters.minExperience === 0 &&
        parsedFilters.maxExperience === 20 &&
        Object.keys(parsedFilters).length === 2;

      setFiltersApplied(!isDefaultFilters);
    }
    if (savedPage) {
      setCurrentPage(JSON.parse(savedPage));
    }
    if (savedSearch) {
      setSearch(savedSearch);
      setPrevSearch(savedSearch);
    }

    if (!isPaginationInProgress) {
      setIsPaginationInProgress(true);
      fetchJobs(
        savedPage ? JSON.parse(savedPage) : 1,
        savedFilters ? JSON.parse(savedFilters) : null,
        false,
        savedSearch
      );
      setIsInitialLoadDone(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('prevFilters', JSON.stringify(prevFilters));
  }, [prevFilters]);

  useEffect(() => {
    localStorage.setItem('currentPage', JSON.stringify(currentPage));
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem('search', search);
  }, [search]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setIsPaginationInProgress(true);
      fetchJobs(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setFilters(prevFilters);
    if (currentPage < totalPages) {
      setIsPaginationInProgress(true);
      fetchJobs(currentPage + 1, prevFilters);
    }
  };

  const handleUpdateSearch = (searchVal) => {
    setSearch(searchVal);
    toast.success('Fetched jobs successfully.');
  };

  const existInPrevFilters = (filter, value) => {
    if (typeof prevFilters[filter] === 'string') {
      return prevFilters[filter] === value;
    } else {
      let exist = false;
      value.forEach((v) => {
        if (!!prevFilters[filter] && prevFilters[filter].includes(v)) {
          exist = true;
        }
      });
      return exist;
    }
  };

  const handleFiltersChange = (filter, value, reset = false) => {
    let updatedFilters = { ...filters };
    let requireApiCall = false;

    if (filter === 'Experience') {
      if (reset) {
        updatedFilters.minExperience = 0;
        updatedFilters.maxExperience = 20;
        requireApiCall =
          prevFilters.minExperience !== 0 || prevFilters.maxExperience !== 20;
      } else if (value) {
        updatedFilters.minExperience = value.minExperience;
        updatedFilters.maxExperience = value.maxExperience;
      }
    } else if (typeof availableFilters[filter] === 'string') {
      updatedFilters[filter] = value;
    } else {
      if (!value) {
        requireApiCall = existInPrevFilters(filter, updatedFilters[filter]);
        delete updatedFilters[filter];
      } else {
        if (!updatedFilters[filter]) {
          updatedFilters[filter] = [value];
        } else {
          if (updatedFilters[filter].includes(value)) {
            updatedFilters[filter] = updatedFilters[filter].filter(
              (f) => f !== value
            );
            if (updatedFilters[filter].length === 0) {
              delete updatedFilters[filter];
            }
          } else {
            updatedFilters[filter] = [...updatedFilters[filter], value];
          }
        }
      }
    }
    setFilters(updatedFilters);
    if (reset && requireApiCall) {
      fetchJobs(currentPage, updatedFilters, true);
    }
  };

  const getNoJobsMessage = () => {
    const defaultFilters = {
      minExperience: 0,
      maxExperience: 20
    };

    const hasFiltersApplied = Object.keys(prevFilters).some(
      (key) => prevFilters[key] !== defaultFilters[key]
    );

    if (search && hasFiltersApplied) {
      return 'No jobs found matching your search criteria and filters.';
    }
    if (search) {
      return 'No jobs found matching your search criteria.';
    }
    if (hasFiltersApplied) {
      return 'No jobs found matching your selected filters.';
    }
    return 'No jobs available at the moment.';
  };

  const handleApplyFilters = () => {
    if (!filters || Object.keys(filters).length === 0) {
      return;
    }
    setIsFilterDropdownOpen(false);
    setPrevFilters({ ...filters });
    fetchJobs(1, filters, true);
    setFiltersApplied(true);
  };

  return (
    <>
      <Header
        loading={loading}
        search={search}
        prevSearch={prevSearch}
        callJobListApi={handleUpdateSearch}
      />
      <div className="container mx-auto my-8 xl:my-20 px-5 md:px-0">
        <div className="grid grid-cols-12 lg:gap-12">
          {showFiltersInDropDown ? (
            <div className={'dropdown-container relative z-[1]'}>
              <div
                className="flex gap-[10px] align-center cursor-pointer"
                onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
              >
                <div
                  className="pt-[4px]"
                  style={{ transform: 'rotateY(180deg)' }}
                >
                  <RiEqualizerLine size={24} />
                </div>

                <h3 className="font-semibold text-2xl">Filters</h3>
              </div>
              <div
                ref={dropdownRef}
                className={`absolute top-[-15px] left-[-15px] w-auto bg-white min-w-[300px] p-[15px] border-[1px] border-gray-100 rounded mb-4 ${
                  isFilterDropdownOpen
                    ? 'slide-in-left'
                    : ' !left-[-12vw] slide-out-left hidden'
                }`}
              >
                <div className="flex justify-between items-center mb-4">
                  <div
                    className="flex gap-[10px] align-center cursor-pointer"
                    onClick={() =>
                      setIsFilterDropdownOpen(!isFilterDropdownOpen)
                    }
                  >
                    <div
                      className="pt-[4px]"
                      style={{ transform: 'rotateY(180deg)' }}
                    >
                      <RiEqualizerLine size={24} />
                    </div>

                    <h3 className="font-semibold text-2xl">Filters</h3>
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center z-50"
                    onClick={() =>
                      setIsFilterDropdownOpen(!isFilterDropdownOpen)
                    }
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="flex justify-end mb-8">
                  <button
                    className={`text-primary-500 text-sm ${
                      filtersApplied
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed opacity-50'
                    }`}
                    onClick={() => {
                      if (filtersApplied) {
                        const resetFilters = {
                          minExperience: 0,
                          maxExperience: 20
                        };
                        setFilters(resetFilters);
                        setPrevFilters(resetFilters);
                        fetchJobs(1, resetFilters);
                        setFiltersApplied(false);
                        toast.success('Filters Reset Successfully');
                      }
                    }}
                  >
                    Reset All
                  </button>
                </div>

                <button
                  className={`flex w-full justify-center my-4 xl:mx-auto filter-btn border py-2 px-4 rounded font-bold bg-primary-600 hover:bg-primary-700 text-white ml-auto ${
                    !filters || (!!filters && Object.keys(filters).length === 0)
                      ? 'cursor-not-allowed opacity-50'
                      : 'hover:bg-primary-700'
                  }`}
                  onClick={handleApplyFilters}
                >
                  {loadingFilter ? (
                    <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                  ) : (
                    <div>Apply Filters</div>
                  )}
                </button>

                {Object.keys(availableFilters).map((filter) => (
                  <div
                    key={filter}
                    className="jobs-filter bg-gray-100 p-6 rounded mb-4"
                  >
                    <div className="flex items-center justify-between">
                      <label className="text-lg font-semibold">
                        {formatStringForFrontend(filter)}
                      </label>
                      {filter === 'Experience' &&
                        (filters.minExperience !== 0 ||
                          filters.maxExperience !== 20) && (
                          <button
                            onClick={() => {
                              handleFiltersChange(
                                'Experience',
                                { minExperience: 0, maxExperience: 20 },
                                true
                              );
                              handleSliderValueChange({
                                minExperience: 0,
                                maxExperience: 20
                              });
                            }}
                            className="text-primary-500 text-sm cursor-pointer"
                          >
                            Reset
                          </button>
                        )}
                      {filter !== 'Experience' &&
                        filters[filter]?.length > 0 && (
                          <button
                            onClick={() =>
                              handleFiltersChange(filter, '', true)
                            }
                            className="text-primary-500 text-sm cursor-pointer"
                          >
                            Reset
                          </button>
                        )}
                    </div>
                    <div className="mt-6 flex flex-col gap-3">
                      {filter === 'Experience' ? (
                        <Slider
                          min={0}
                          max={20}
                          minExperience={filters.minExperience}
                          maxExperience={filters.maxExperience}
                          onSliderValueChange={handleSliderValueChange}
                        />
                      ) : (
                        availableFilters[filter].map((option) => (
                          <div
                            key={option}
                            className="filter-checkbox flex items-center gap-3"
                          >
                            <input
                              type="checkbox"
                              id={option}
                              name={filter}
                              value={option}
                              checked={
                                filters?.[filter]
                                  ? filters[filter].includes(option)
                                  : false
                              }
                              onChange={() =>
                                handleFiltersChange(filter, option)
                              }
                              className=" text-primary-500 h-6 w-6 cursor-pointer focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 "
                            />
                            <label
                              htmlFor={option}
                              className="text-base opacity-60"
                            >
                              {formatStringForFrontend(option)}
                            </label>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                ))}

                <button
                  className={`flex w-full justify-center my-4 xl:mx-auto filter-btn border py-2 px-4 rounded font-bold bg-primary-600 hover:bg-primary-700 text-white ml-auto ${
                    !filters || (!!filters && Object.keys(filters).length === 0)
                      ? 'cursor-not-allowed opacity-50'
                      : 'hover:bg-primary-700'
                  }`}
                  onClick={handleApplyFilters}
                >
                  {loadingFilter ? (
                    <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                  ) : (
                    <div>Apply Filters</div>
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className={'col-span-12 xl:col-span-3'}>
              <div className="flex justify-between items-center mb-8">
                <h3 className="font-semibold text-2xl">Filters</h3>
                <button
                  className={`text-primary-500 text-sm ${
                    filtersApplied
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed opacity-50'
                  }`}
                  onClick={() => {
                    if (filtersApplied) {
                      const resetFilters = {
                        minExperience: 0,
                        maxExperience: 20
                      };
                      setFilters(resetFilters);
                      setPrevFilters(resetFilters);
                      fetchJobs(1, resetFilters);
                      setFiltersApplied(false);
                      toast.success('Filters Reset Successfully');
                    }
                  }}
                >
                  Reset All
                </button>
              </div>

              <button
                className={`flex w-full justify-center my-4 xl:mx-auto filter-btn border py-2 px-4 rounded font-bold bg-primary-600 text-white ml-auto ${
                  !filters || (!!filters && Object.keys(filters).length === 0)
                    ? 'cursor-not-allowed opacity-50'
                    : 'hover:bg-primary-700'
                }`}
                onClick={handleApplyFilters}
              >
                {loadingFilter ? (
                  <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                ) : (
                  <div>Apply Filters</div>
                )}
              </button>

              {Object.keys(availableFilters).map((filter) => (
                <div
                  key={filter}
                  className={'jobs-filter bg-gray-100 p-6 rounded mb-4 '}
                >
                  <div className="flex items-center justify-between">
                    <label className="text-lg font-semibold">
                      {formatStringForFrontend(filter)}
                    </label>
                    {filter === 'Experience' &&
                      (filters.minExperience !== 0 ||
                        filters.maxExperience !== 20) && (
                        <button
                          onClick={() => {
                            handleFiltersChange(
                              'Experience',
                              { minExperience: 0, maxExperience: 20 },
                              true
                            );
                            handleSliderValueChange({
                              minExperience: 0,
                              maxExperience: 20
                            });
                          }}
                          className="text-primary-500 text-sm cursor-pointer"
                        >
                          Reset
                        </button>
                      )}
                    {filter !== 'Experience' && filters[filter]?.length > 0 && (
                      <button
                        onClick={() => handleFiltersChange(filter, '', true)}
                        className="text-primary-500 text-sm cursor-pointer"
                      >
                        Reset
                      </button>
                    )}
                  </div>
                  <div className="mt-6 flex flex-col gap-3">
                    {typeof availableFilters[filter] === 'string' ? (
                      <>
                        <Slider
                          min={0}
                          max={20}
                          minExperience={filters.minExperience}
                          maxExperience={filters.maxExperience}
                          onSliderValueChange={handleSliderValueChange}
                        />
                      </>
                    ) : (
                      availableFilters[filter].map((option) => (
                        <div
                          key={option}
                          className="filter-checkbox flex items-center gap-3"
                        >
                          <input
                            type="checkbox"
                            id={option}
                            name={filter}
                            value={option}
                            checked={
                              filters?.[filter]
                                ? filters[filter].includes(option)
                                : false
                            }
                            onChange={() => handleFiltersChange(filter, option)}
                            className="text-primary-500 h-6 w-6 cursor-pointer focus:ring-0 focus:ring-offset-0 focus-visible:ring-0"
                          />
                          <label
                            htmlFor={option}
                            className="text-base opacity-60"
                          >
                            {formatStringForFrontend(option)}
                          </label>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              ))}

              <button
                className={`flex w-full justify-center my-4 xl:mx-auto filter-btn border py-2 px-4 rounded font-bold bg-primary-600 text-white ml-auto ${
                  !filters || (!!filters && Object.keys(filters).length === 0)
                    ? 'cursor-not-allowed opacity-50'
                    : 'hover:bg-primary-700'
                }`}
                onClick={handleApplyFilters}
              >
                {loadingFilter ? (
                  <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                ) : (
                  <div>Apply Filters</div>
                )}
              </button>
            </div>
          )}
          <div className="col-span-12 mt-4 lg:mt-0 xl:col-span-9">
            {/* {totalJobs !== 0 && ( */}
            <div className="job-list-heading flex justify-between items-center flex-col md:flex-row">
              <h3 className="text-2xl font-semibold">Latest Jobs</h3>
              {totalJobs !== 0 && (
                <p className="text-base text-gray-600 py-4 md:py-0">
                  {totalJobs} Jobs Found
                </p>
              )}
            </div>
            <div
              className={`md:mt-8 flex flex-col gap-6 ${
                isFilterDropdownOpen ? 'min-h-[1000px]' : ''
              }`}
            >
              <>
                {totalJobs == 0 && (
                  <div className="flex justify-center flex-col items-center py-8">
                    <img
                      src={noJobFound}
                      alt="no-job-found"
                      className="max-w-[30%]"
                    />
                    <h2 className="mt-4 text-center text-xl font-semibold text-gray-700">
                      {getNoJobsMessage()}
                    </h2>
                  </div>
                )}
                {isJobAvailable &&
                  jobs.map((job) => (
                    <>
                      <div id={job._id} key={job._id}>
                        <Jobcard
                          key={job._id}
                          job={job}
                          jobId={job._id}
                          title={job.title}
                          maxSalary={job.maximumSalary}
                          minSalary={job.minimumSalary}
                          workLocationType={job.workLocationType}
                          jobDescriptionSummary={job.jobDescriptionSummary}
                          minimumExperience={job.minimumExperience}
                          isHighlighted={job._id === highlightedJobId}
                        />
                      </div>
                    </>
                  ))}
              </>
            </div>

            {totalPages > 1 && (
              <div className="flex justify-center mt-4">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  style={{ cursor: currentPage === 1 && 'not-allowed' }}
                  className={`mx-2 px-4 py-2 border rounded ${
                    currentPage === 1
                      ? 'cursor-not-allowed text-gray-500'
                      : 'hover:bg-gray-200'
                  }`}
                >
                  Previous
                </button>
                <span className="mx-2 px-4 py-2 border rounded">
                  {currentPage} / {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  style={{
                    cursor: currentPage === totalPages && 'not-allowed'
                  }}
                  className={`mx-2 px-4 py-2 border rounded ${
                    currentPage === totalPages
                      ? 'cursor-not-allowed text-gray-500'
                      : 'hover:bg-gray-200'
                  }`}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {loading && (
        <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
          <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      )} */}
    </>
  );
};

export default UserJoblist;
