const resetPassword = (email, otp, password, recaptchaValue) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/iam/reset-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email,
            otp,
            newPassword: password,
            gRecaptchaResponse: recaptchaValue
          })
        }
      );

      const data = await response.json();

      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(new Error(data.message || 'An error occurred'));
      }
    } catch (error) {
      console.error('Error while resetting the password:', error);
      reject(new Error('An error occurred, please try again.'));
    }
  });
};

export default resetPassword;
