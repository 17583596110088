import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AppreciationPage = () => {
  const navigate = useNavigate();
  const { orgName } = useParams();

  const handleHomeClick = () => {
    navigate(`/${orgName}`);
  };

  return (
    <>
      <div className="bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover h-[150px] relative bg-primary-600 z-[1]"></div>
      <div className="flex flex-col items-center justify-center mt-[10vh] px-[5%] py-0">
        <h1 className="text-4xl font-bold text-green-700 mb-4">
          Thank You for Taking the Test!
        </h1>
        <p className="text-lg mb-8 py-0 px-[5%]">
          Thank you for completing the test! We appreciate the time and effort
          you have invested and are excited to see your results.
        </p>
        <button
          onClick={handleHomeClick}
          className="px-6 py-3 bg-green-700 text-white rounded hover:bg-green-800"
        >
          Go to Home
        </button>
      </div>
    </>
  );
};

export default AppreciationPage;
