import React, { useState, useEffect } from 'react';
import {
  FaFileAlt,
  FaUpload,
  FaClipboardList,
  FaLightbulb,
  FaUserAstronaut
} from 'react-icons/fa';
import { FaInfoCircle } from 'react-icons/fa';

const InstructionsModal = ({
  isOpen,
  onClose,
  onProceedToApply,
  testProcess
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  const AssesmentSvg = () => (
    <svg
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      class="h-5 w-5"
      viewBox="0 0 24 24"
    >
      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
      <path d="M22 4L12 14.01l-3-3"></path>
    </svg>
  );

  const progressionLsit = [
    {
      title: 'Resume Upload',
      subtitle: 'Upload your resume in PDF format.',
      Icon: FaUpload
    },
    {
      title: 'Application Form',
      subtitle: 'Fill out a detailed application form.',
      Icon: FaFileAlt
    },
    {
      title: 'Questionnaire',
      subtitle: 'Answer questions based on your application.',
      Icon: FaClipboardList
    },
    // {
    //   title: 'Assessments',
    //   subtitle: 'You will be required to complete certain assessments as part of the application process.',
    //   Icon: AssesmentSvg
    // },
    ...(testProcess.find((test) => test.type === 'SKILL_BASED_MCQ_TEST')
      ? [
          {
            title: 'Skill Based MCQ Test',
            subtitle:
              'You will be required to complete a skill based MCQ test.',
            Icon: AssesmentSvg
          }
        ]
      : []),
    ...(testProcess.find((test) => test.type === 'APTITUDE_MCQ_TEST')
      ? [
          {
            title: 'Aptitude MCQ',
            subtitle: 'You will be required to complete an aptitude MCQ test.',
            Icon: FaLightbulb
          }
        ]
      : []),
    ...(testProcess.find((test) => test.type === 'PERSONALITY_TEST')
      ? [
          {
            title: 'Personality Assessment',
            subtitle: 'You will be required to complete a personality test.',
            Icon: FaUserAstronaut
          }
        ]
      : [])
  ];
  //

  const handleClose = () => {
    setLoading(false);
    onClose();
  };

  const handleProceedToApply = () => {
    setLoading(true);
    onProceedToApply();
    setLoading(false);
  };

  const handleOpenModal = () => {
    setLoading(true);
    // onSubmit();
    setLoading(false);
  };

  useEffect(() => {
    const container = document.getElementById('instructionsModal');
    if (!container) {
      return;
    }

    const focusableElements = container.querySelectorAll(
      'input, button, select, textarea, a[href]'
    );
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    const handleKeyDown = (e) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // Shift + Tab
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        // Tab
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    };

    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-[2] flex items-center justify-center overflow-y-hidden bg-black bg-opacity-50"
          id="instructionsModal"
          tabIndex="0"
        >
          <div className="relative p-4 w-full max-w-2xl h-auto md:h-auto">
            <div className="relative p-4 bg-white rounded-lg shadow sm:p-5 overflow-y-auto max-h-[90vh] mt-[5vh]">
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5">
                <h3 className="text-lg font-semibold text-gray-900 w-full text-center">
                  Important Notice Before You Apply
                </h3>
                <button
                  type="button"
                  className="button text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center z-50"
                  onClick={handleClose}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="flex flex-col gap-3">
                <h3 className="text-md font-semibold text-gray-900">
                  The application process may take a few minutes to complete.
                </h3>

                <p className="text-sm">
                  Here's what you need to know before you proceed:{' '}
                </p>

                <div class="container mx-auto flex flex-wrap">
                  <div class="flex w-full flex-wrap">
                    <div class="md:py-6 md:pr-10">
                      {progressionLsit.map(
                        ({ title, Icon, subtitle }, index) => (
                          <div class="relative flex pb-4">
                            {index !== progressionLsit.length - 1 && (
                              <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
                                <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
                              </div>
                            )}
                            <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#296b99] text-white">
                              <Icon />
                            </div>
                            <div class="flex-grow pl-4">
                              <h2 class="title-font mb-1 text-sm font-semibold tracking-wider text-gray-900">
                                {title}
                              </h2>
                              <p class="leading-relaxed">{subtitle}</p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div
                  class="flex items-center p-4 text-sm text-primary-800 rounded-lg bg-primary-50 "
                  role="alert"
                >
                  <FaInfoCircle className="w-10 h-10 mr-4" />
                  <span>
                    <span class="font-medium">Important!</span> If you apply for
                    this job, you will not be allowed to apply for another job
                    in this organisation until your application is either
                    accepted or rejected.
                  </span>
                </div>

                <p className="font-semibold text-center">
                  Are you ready to proceed?
                </p>
              </div>
              <div className="w-full justify-center flex mt-4">
                <button
                  type="button"
                  className="button min-w-24 h-11 flex justify-center bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400 mr-4"
                  onClick={handleClose}
                >
                  Apply Later
                </button>
                <button
                  type="button"
                  className="button min-w-24 h-11 flex justify-center bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700"
                  // onClick={handleOpenModal}
                  onClick={handleProceedToApply}
                >
                  {loading ? (
                    <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                  ) : (
                    'Proceed to Apply'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InstructionsModal;
