const signup = (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/iam/sign-up`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...formData
          })
        }
      );

      const data = await response.json();

      if (data.status === 'success') {
        localStorage.setItem('user', JSON.stringify(data.data.user));
        localStorage.setItem('session', JSON.stringify(data.data.session));
        resolve({ status: 'LOGIN', role: data.data.user.role });
      } else {
        reject(new Error(data.message || 'An error occurred'));
      }
    } catch (error) {
      console.error('Error during signup:', error);
      reject(new Error('An error occurred, please try again.'));
    }
  });
};

export default signup;
