const colors = require('tailwindcss/colors');

export default function setThemeColor(color) {
  const root = document.documentElement;
  const selectedColor = colors[color] || colors.blue;

  root.style.setProperty('--color-primary', selectedColor[500]);
  root.style.setProperty('--color-primary-50', selectedColor[50]);
  root.style.setProperty('--color-primary-100', selectedColor[100]);
  root.style.setProperty('--color-primary-200', selectedColor[200]);
  root.style.setProperty('--color-primary-300', selectedColor[300]);
  root.style.setProperty('--color-primary-400', selectedColor[400]);
  root.style.setProperty('--color-primary-500', selectedColor[500]);
  root.style.setProperty('--color-primary-600', selectedColor[600]);
  root.style.setProperty('--color-primary-700', selectedColor[700]);
  root.style.setProperty('--color-primary-800', selectedColor[800]);
  root.style.setProperty('--color-primary-900', selectedColor[900]);
}
