import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { IoSearchOutline } from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useHelperBot } from '../HelperBot';
import { Spinner } from 'react-bootstrap';

const Header = ({
  search,
  loading,
  prevSearch,
  callJobListApi,
  instantSearchCallback
}) => {
  const { orgName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchVal, setSearchVal] = useState(search);
  const [isLoading, setLoading] = useState(loading);
  const [onGoingApplication, setOnGoingApplication] = useState({});
  const appliedJobsObj = useSelector((state) => state.appliedJobsObj);
  // const jobListToggle = useSelector((state) => state.jobListToggle);
  const { helperMessage } = useHelperBot();

  useEffect(() => {
    setOnGoingApplication(
      Object.values(appliedJobsObj).find(
        (app) => app.status !== 'REJECTED'
        // app.status === "REJECTED" ||
        // app.status === "APPLICATION_SUBMITTED" ||
        // app.status === "PROFILE_REVIEW_COMPLETED" ||
        // app.status === "AI_REVIEW_COMPLETED" ||
        // app.status === "ASSESSMENTS_IN_PROGRESS"

        // 'APPLICATION_SUBMITTED',
        // 'PROFILE_REVIEW_COMPLETED',
        // 'AI_REVIEW_COMPLETED',
        // 'ASSESSMENTS_IN_PROGRESS',
        // 'ASSESSMENTS_COMPLETED',
      )
    );
  }, [appliedJobsObj]);

  useEffect(() => {
    setSearchVal(search);
  }, [search]);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      callJobListApi(searchTerm);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    setSearchVal(e.target.value);
    setLoading(true);
    debouncedSearch(e.target.value);
    setLoading(false);
  };

  const handleCallForSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let shouldCallSearch = true;
    if (
      (!!searchVal || (searchVal === '' && prevSearch !== '')) &&
      search !== searchVal
    ) {
      callJobListApi(searchVal);
    }
  };

  useEffect(() => {
    if (instantSearchCallback) {
      instantSearchCallback(searchVal);
    }
  }, [searchVal]);

  const handleContinueApplication = () => {
    const { status, job, _id: id } = onGoingApplication || {};
    navigate(`/${orgName}/appliedjob/${job}/${id}`);
    let arr = [
      'I’m matching up your info with the job. Hang on just a little longer, we’re getting there!',
      'Still with me? Awesome! I’m just making sure you’re a great fit.',
      'Almost done! We’ll be moving on in just a sec.'
    ];
    helperMessage(arr[Math.floor(Math.random() * arr.length)]);
  };

  return (
    <>
      <div
        className={`bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover ${
          false &&
          onGoingApplication &&
          Object.keys(onGoingApplication).length > 0
            ? 'h-[calc(50vh+9rem)] lg:h-[calc(50vh+9rem)]'
            : 'h-[50vh] lg:h-[50vh]'
        }  relative bg-primary-600 vertical-center z-0 `}
      >
        {false &&
          onGoingApplication &&
          Object.keys(onGoingApplication).length > 0 &&
          (location.pathname === `/${orgName}` ||
            location.pathname.includes(`${orgName}/job/`)) && (
            <div className="absolute top-[4.1rem] flex items-center justify-center z-10  mx-auto w-full px-5 sm:px-0">
              <div className="container bg-white rounded-full flex items-center justify-center text-center font-semibold p-4 my-4 leading-4 text-xs md:text-base	">
                <span>
                  You have already applied to a job. To resume your
                  application,&nbsp;
                  <span
                    className="text-primary-600 cursor-pointer hover:text-primary-500 hover:underline"
                    onClick={handleContinueApplication}
                  >
                    click here
                  </span>
                  .
                </span>
              </div>
            </div>
          )}

        <div className="container mx-auto px-5 sm:px-0">
          <div className="max-w-[640px] w-full mx-auto">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white text-center leading-tight w-full mx-auto">
              Find Your Perfect Job
            </h1>
            <p className="text-sm sm:text-base md:text-lg text-white text-center pt-4 mb-8">
              Ready for a Change? Explore Openings and Apply Today!
            </p>
            <form action="" onSubmit={handleCallForSearch} className={''}>
              <div className="relative w-full">
                <svg
                  className="hidden sm:block absolute left-4 md:left-8 top-5 md:top-7 text-base-content"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.85587 1.61739C5.19014 0.725854 6.75882 0.25 8.36354 0.25H8.36358C10.5154 0.250137 12.579 1.105 14.1006 2.62655C15.6221 4.14811 16.477 6.21174 16.4771 8.36355V8.36359C16.4771 9.96831 16.0013 11.537 15.1097 12.8713C14.9533 13.1054 14.7852 13.3305 14.6065 13.5459L19.5303 18.4697C19.8232 18.7626 19.8232 19.2374 19.5303 19.5303C19.2374 19.8232 18.7625 19.8232 18.4696 19.5303L13.5458 14.6065C12.9234 15.1232 12.2239 15.5467 11.4685 15.8596C9.98591 16.4737 8.35454 16.6344 6.78065 16.3213C5.20677 16.0082 3.76107 15.2355 2.62636 14.1008C1.49165 12.9661 0.718908 11.5204 0.405843 9.94648C0.0927783 8.37259 0.253454 6.74122 0.867553 5.25866C1.48165 3.77609 2.52159 2.50892 3.85587 1.61739ZM8.36349 1.75C7.05546 1.75001 5.77681 2.13789 4.68922 2.86459C3.60162 3.5913 2.75394 4.6242 2.25337 5.83268C1.75281 7.04116 1.62183 8.37093 1.87702 9.65384C2.13221 10.9368 2.76209 12.1152 3.68702 13.0401C4.61195 13.965 5.79038 14.5949 7.07329 14.8501C8.3562 15.1053 9.68597 14.9743 10.8945 14.4738C12.1029 13.9732 13.1358 13.1255 13.8625 12.0379C14.5892 10.9503 14.9771 9.67167 14.9771 8.36364M8.36354 1.75C10.1175 1.75012 11.7997 2.44695 13.0399 3.68721C14.2802 4.92748 14.977 6.6096 14.9771 8.36359"
                    fill="currentColor"
                  ></path>
                </svg>

                <input
                  type="text"
                  placeholder="Job Title"
                  className="text-black bg-primary-content outline-none rounded pl-4 sm:pl-12 md:pl-20 pr-28  h-[60px] md:h-[72px] w-full"
                  value={searchVal}
                  onChange={handleSearchChange}
                />
                {isLoading || loading ? (
                  <div className="absolute top-[24px] right-[16px] w-16 h-16 border-4 border-primary-500 border-t-transparent rounded-full animate-spin"></div>
                ) : searchVal ? (
                  <div
                    className="absolute top-[24px] right-[16px] cursor-pointer"
                    onClick={() => {
                      debouncedSearch('');
                      setSearchVal('');
                    }}
                  >
                    <IoMdClose size={24} />
                  </div>
                ) : (
                  <></>
                )}
                {/* {!!searchVal && (
                  <div
                    className="absolute top-[24px] right-[16px] cursor-pointer"
                    onClick={() => {
                      debouncedSearch("");
                      setSearchVal("");
                    }}
                  >
                    <IoMdClose size={24} />
                  </div>
                )} */}
                {/* <button
                  type="submit"
                  className="bg-primary-600 hover:bg-primary-700 px-5 py-3 rounded text-white text-base font-medium absolute right-1.5 md:right-3 top-1.5 md:top-3"
                  onClick={handleCallForSearch}
                >
                  Search
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
