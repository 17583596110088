import React from 'react';
import LoaderWithText from '../../utils/LoaderWithText';

const ExceededTimeModal = ({ handleSubmit }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg max-w-md w-full relative">
        <div className="flex justify-center border-b relative">
          <h2 className="text-2xl font-bold mb-4 text-red-700">Time's Up!</h2>
        </div>
        <p className="mt-6 text-center">
          The allotted time to answer the questions has expired.
        </p>
        <p className="mb-6 text-center pt-4 font-bold">
          Your answers have been submitted automatically.
        </p>
        <span className="flex justify-center gap-[20px] align-center">
          <p className="text-center">Redirecting in:</p>
          <LoaderWithText
            initialTime={5}
            onComplete={() => {
              handleSubmit();
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default ExceededTimeModal;
