import React from 'react';
import { FaRegCopy, FaUpload, FaClipboardList } from 'react-icons/fa';
import { BsWindow } from 'react-icons/bs';
import { MdDeveloperBoardOff } from 'react-icons/md';
import { TbBrowserOff } from 'react-icons/tb';
import { MdOutlineScreenshotMonitor } from 'react-icons/md';
import { MdOutlineSpatialAudio } from 'react-icons/md';

const GuidelinesModal = ({ closeModal }) => {
  const modalData = [
    {
      icon: BsWindow,
      heading: 'Tab and Window Restrictions',
      points:
        'Do not change tabs or windows while answering. Opening new tabs, windows, or applications is not allowed and will be detected.'
    },
    {
      icon: FaRegCopy,
      heading: 'Copy-Paste Restriction',
      points: 'Pasting text into the answer fields is strictly prohibited.'
    },
    {
      icon: MdDeveloperBoardOff,
      heading: 'Developer Console and Printing',
      points:
        'Accessing the developer console and printing the page is not allowed.'
    },
    {
      icon: TbBrowserOff,
      heading: 'Browser Activity Monitoring',
      points:
        'Your browser activity is being monitored. Any attempt to switch tabs, open new windows, or use other applications will be detected.'
    },
    {
      icon: MdOutlineScreenshotMonitor,
      heading: 'Periodic Screenshots',
      points:
        'Periodic screenshots will be captured during the test to ensure compliance.'
    },
    {
      icon: MdOutlineSpatialAudio,
      heading: 'Audio Monitoring',
      points:
        'Audio monitoring is enabled to detect any unusual activity during the test.'
    }
  ];

  return (
    <div className=" bg-[#00000057] z-30 fixed top-0 w-[100vw] h-[90vh] sm:h-[100vh] flex justify-center items-center">
      <div className="bg-white rounded-xl p-5 shadow-lg md:w-[80%] w-[95%] max-w-[66rem] max-h-[95%] flex flex-col">
        <h1 className=" text-xl font-bold">Important Instructions</h1>
        <hr className="my-4" />
        <h2 className=" text-md font-semibold">
          You are about to be redirected to take a motivators questionnaire.
        </h2>
        <h2 className=" text-sm mt-1">
          Please read the following instructions carefully before proceeding.
        </h2>
        <hr className="my-4" />
        <div className=" overflow-y-auto">
          {modalData.map((rule) => (
            <>
              <div class="relative flex pb-4">
                <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-600 text-white">
                  {<rule.icon />}
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="title-font mb-1 text-sm font-bold tracking-wider text-gray-900">
                    {rule.heading}
                  </h2>
                  <p class="leading-relaxed">{rule.points}</p>
                </div>
              </div>
            </>
          ))}
        </div>

        <button
          className="w-fit self-end bg-primary-600 text-white px-3 py-2 rounded-md mt-4"
          onClick={closeModal}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default GuidelinesModal;
