import currencySymbols from 'currency-symbol-map';

export const formatStringForFrontend = (str) => {
  if (str === 'AI_ANALYSIS') {
    return 'AI Analysis';
  }

  const words = str.split('_');

  const formatWord = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const formattedWords = words.map((word) => {
    if (word.includes(' ')) {
      return word
        .split(' ')
        .map((w) => {
          return formatWord(w);
        })
        .join(' ');
    } else {
      return formatWord(word);
    }
  });

  return formattedWords.join(' ');
};

export const formatStringToCamelCase = (name) => {
  name = name.split(' ');
  return (
    name[0].toLowerCase() +
    name
      .slice(1)
      .map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
      .join('')
  );
};

export const hoursAgo = (pastDate) => {
  const currentDate = new Date();
  const pastDateTime = new Date(pastDate);

  const diffInMs = currentDate - pastDateTime;

  const diffInHours = diffInMs / (1000 * 60 * 60);

  return diffInHours;
};

export const timeAgo = (pastDate) => {
  const currentDate = new Date();
  const pastDateTime = new Date(pastDate);

  const diffInMs = currentDate - pastDateTime;

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? '1 year ago' : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (weeks > 0) {
    return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
  } else if (days > 0) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
    return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
  }
};

export const formatNumberWithCommas = (value) => {
  const currency = localStorage.getItem('currency') || 'INR';
  const currencySymbol = currencySymbols(currency) || '₹'; // Get currency symbol here

  if (!value) {
    return '';
  }

  // Ensure value is a string and remove non-numeric characters except decimal
  const cleanValue = value.toString().replace(/[^\d.]/g, '');

  const [integerPart, decimalPart] = cleanValue.split('.');

  // Format based on currency (INR for Indian, else Western)
  const formattedInteger =
    currency === 'INR'
      ? integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')
      : integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export const formatToCurrency = (amount) => {
  const currency = localStorage.getItem('currency') || 'INR';
  const currencySymbol = currencySymbols(currency) || '₹'; // Get currency symbol here

  if (!amount) {
    return currencySymbol;
  }

  // Reuse formatNumberWithCommas to handle number formatting
  const formattedAmount = formatNumberWithCommas(amount.toString());

  return `${currencySymbol}${formattedAmount}`;
};

export const isValidDate = (date) => {
  let dateArr = date.split('/');
  let dd = dateArr[0] ? parseInt(dateArr[0]) : null;
  let mm = dateArr[1] ? parseInt(dateArr[1]) : null;
  let yyyy = dateArr[2] ? parseInt(dateArr[2]) : null;
  if (!dd || !mm || !yyyy) {
    return false;
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-based in JavaScript
  const currentDay = currentDate.getDate();

  // Check if year is valid
  if (yyyy > currentYear) {
    return false;
  }
  if (yyyy < 1) {
    return false;
  }

  // Check if month is valid
  if (mm < 1 || mm > 12) {
    return false;
  }

  // Check if day is valid for the month
  if (dd < 1 || dd > 31) {
    return false;
  }

  // Validate days for each month
  const daysInMonth = {
    1: 31,
    2: isLeapYear(yyyy) ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
  };

  if (dd > daysInMonth[mm]) {
    return false;
  }

  // Check if the date is not in the future
  if (yyyy === currentYear) {
    if (mm > currentMonth) {
      return false;
    }
    if (mm === currentMonth && dd > currentDay) {
      return false;
    }
  }

  return true;
};

// Helper function to check for leap year
export const isLeapYear = (year) => {
  if (year % 4 !== 0) {
    return false;
  }
  if (year % 100 !== 0) {
    return true;
  }
  if (year % 400 !== 0) {
    return false;
  }
  return true;
};

export const convertToRoman = (num) => {
  // Mapping of numbers to Roman numerals
  const romanNumerals = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
    5: 'V',
    6: 'VI',
    7: 'VII',
    8: 'VIII',
    9: 'IX',
    10: 'X',
    11: 'XI',
    12: 'XII',
    13: 'XIII',
    14: 'XIV',
    15: 'XV',
    16: 'XVI',
    17: 'XVII',
    18: 'XVIII',
    19: 'XIX',
    20: 'XX',
    21: 'XXI',
    22: 'XXII',
    23: 'XXIII',
    24: 'XXIV',
    25: 'XXV',
    26: 'XXVI',
    27: 'XXVII',
    28: 'XXVIII',
    29: 'XXIX',
    30: 'XXX'
  };

  // Check if the number is within the range of 1-30
  if (num < 1 || num > 30) {
    return num;
  }

  // Return the Roman numeral for the given number
  return romanNumerals[num];
};

export const toggleScrollBarFormRootHTMLTag = (hideOverflow) => {
  const htmlElement = document.documentElement; // Get the <html> element
  if (hideOverflow) {
    // Add overflow: hidden
    htmlElement.style.overflow = 'hidden';
  } else {
    // Remove overflow: hidden
    htmlElement.style.overflow = ''; // Reset to default
  }
};

// Define and export the arrow function
export const blockClicks = (duration) => {
  // Create an overlay div
  const overlay = document.createElement('div');

  // Set styles for the overlay to cover the entire page
  Object.assign(overlay.style, {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 9999,
    cursor: 'pointer'
  });

  // Prevent click events on the overlay from propagating
  overlay.onclick = (e) => e.stopPropagation();

  // Append the overlay to the body
  document.body.appendChild(overlay);

  // Remove the overlay after the specified duration
  setTimeout(() => {
    document.body.removeChild(overlay);
  }, duration);
};

export const countryCodes = [
  { name: 'Afghanistan', code: '+93', iso: 'AF' },
  { name: 'Albania', code: '+355', iso: 'AL' },
  { name: 'Algeria', code: '+213', iso: 'DZ' },
  { name: 'Andorra', code: '+376', iso: 'AD' },
  { name: 'Angola', code: '+244', iso: 'AO' },
  { name: 'Antigua and Barbuda', code: '+1-268', iso: 'AG' },
  { name: 'Argentina', code: '+54', iso: 'AR' },
  { name: 'Armenia', code: '+374', iso: 'AM' },
  { name: 'Australia', code: '+61', iso: 'AU' },
  { name: 'Austria', code: '+43', iso: 'AT' },
  { name: 'Azerbaijan', code: '+994', iso: 'AZ' },
  { name: 'Bahamas', code: '+1-242', iso: 'BS' },
  { name: 'Bahrain', code: '+973', iso: 'BH' },
  { name: 'Bangladesh', code: '+880', iso: 'BD' },
  { name: 'Barbados', code: '+1-246', iso: 'BB' },
  { name: 'Belarus', code: '+375', iso: 'BY' },
  { name: 'Belgium', code: '+32', iso: 'BE' },
  { name: 'Belize', code: '+501', iso: 'BZ' },
  { name: 'Benin', code: '+229', iso: 'BJ' },
  { name: 'Bhutan', code: '+975', iso: 'BT' },
  { name: 'Bolivia', code: '+591', iso: 'BO' },
  { name: 'Bosnia and Herzegovina', code: '+387', iso: 'BA' },
  { name: 'Botswana', code: '+267', iso: 'BW' },
  { name: 'Brazil', code: '+55', iso: 'BR' },
  { name: 'Brunei', code: '+673', iso: 'BN' },
  { name: 'Bulgaria', code: '+359', iso: 'BG' },
  { name: 'Burkina Faso', code: '+226', iso: 'BF' },
  { name: 'Burundi', code: '+257', iso: 'BI' },
  { name: 'Cabo Verde', code: '+238', iso: 'CV' },
  { name: 'Cambodia', code: '+855', iso: 'KH' },
  { name: 'Cameroon', code: '+237', iso: 'CM' },
  { name: 'Canada', code: '+1', iso: 'CA' },
  { name: 'Central African Republic', code: '+236', iso: 'CF' },
  { name: 'Chad', code: '+235', iso: 'TD' },
  { name: 'Chile', code: '+56', iso: 'CL' },
  { name: 'China', code: '+86', iso: 'CN' },
  { name: 'Colombia', code: '+57', iso: 'CO' },
  { name: 'Comoros', code: '+269', iso: 'KM' },
  { name: 'Congo (Congo-Brazzaville)', code: '+242', iso: 'CG' },
  { name: 'Congo (Democratic Republic)', code: '+243', iso: 'CD' },
  { name: 'Costa Rica', code: '+506', iso: 'CR' },
  { name: 'Croatia', code: '+385', iso: 'HR' },
  { name: 'Cuba', code: '+53', iso: 'CU' },
  { name: 'Cyprus', code: '+357', iso: 'CY' },
  { name: 'Czech Republic', code: '+420', iso: 'CZ' },
  { name: 'Denmark', code: '+45', iso: 'DK' },
  { name: 'Djibouti', code: '+253', iso: 'DJ' },
  { name: 'Dominica', code: '+1-767', iso: 'DM' },
  { name: 'Dominican Republic', code: '+1-809', iso: 'DO' },
  { name: 'Ecuador', code: '+593', iso: 'EC' },
  { name: 'Egypt', code: '+20', iso: 'EG' },
  { name: 'El Salvador', code: '+503', iso: 'SV' },
  { name: 'Equatorial Guinea', code: '+240', iso: 'GQ' },
  { name: 'Eritrea', code: '+291', iso: 'ER' },
  { name: 'Estonia', code: '+372', iso: 'EE' },
  { name: 'Eswatini', code: '+268', iso: 'SZ' },
  { name: 'Ethiopia', code: '+251', iso: 'ET' },
  { name: 'Fiji', code: '+679', iso: 'FJ' },
  { name: 'Finland', code: '+358', iso: 'FI' },
  { name: 'France', code: '+33', iso: 'FR' },
  { name: 'Gabon', code: '+241', iso: 'GA' },
  { name: 'Gambia', code: '+220', iso: 'GM' },
  { name: 'Georgia', code: '+995', iso: 'GE' },
  { name: 'Germany', code: '+49', iso: 'DE' },
  { name: 'Ghana', code: '+233', iso: 'GH' },
  { name: 'Greece', code: '+30', iso: 'GR' },
  { name: 'Grenada', code: '+1-473', iso: 'GD' },
  { name: 'Guatemala', code: '+502', iso: 'GT' },
  { name: 'Guinea', code: '+224', iso: 'GN' },
  { name: 'Guinea-Bissau', code: '+245', iso: 'GW' },
  { name: 'Guyana', code: '+592', iso: 'GY' },
  { name: 'Haiti', code: '+509', iso: 'HT' },
  { name: 'Honduras', code: '+504', iso: 'HN' },
  { name: 'Hungary', code: '+36', iso: 'HU' },
  { name: 'Iceland', code: '+354', iso: 'IS' },
  { name: 'India', code: '+91', iso: 'IN' },
  { name: 'Indonesia', code: '+62', iso: 'ID' },
  { name: 'Iran', code: '+98', iso: 'IR' },
  { name: 'Iraq', code: '+964', iso: 'IQ' },
  { name: 'Ireland', code: '+353', iso: 'IE' },
  { name: 'Israel', code: '+972', iso: 'IL' },
  { name: 'Italy', code: '+39', iso: 'IT' },
  { name: 'Jamaica', code: '+1-876', iso: 'JM' },
  { name: 'Japan', code: '+81', iso: 'JP' },
  { name: 'Jordan', code: '+962', iso: 'JO' },
  { name: 'Kazakhstan', code: '+7', iso: 'KZ' },
  { name: 'Kenya', code: '+254', iso: 'KE' },
  { name: 'Kiribati', code: '+686', iso: 'KI' },
  { name: 'Kuwait', code: '+965', iso: 'KW' },
  { name: 'Kyrgyzstan', code: '+996', iso: 'KG' },
  { name: 'Laos', code: '+856', iso: 'LA' },
  { name: 'Latvia', code: '+371', iso: 'LV' },
  { name: 'Lebanon', code: '+961', iso: 'LB' },
  { name: 'Lesotho', code: '+266', iso: 'LS' },
  { name: 'Liberia', code: '+231', iso: 'LR' },
  { name: 'Libya', code: '+218', iso: 'LY' },
  { name: 'Liechtenstein', code: '+423', iso: 'LI' },
  { name: 'Lithuania', code: '+370', iso: 'LT' },
  { name: 'Luxembourg', code: '+352', iso: 'LU' },
  { name: 'Madagascar', code: '+261', iso: 'MG' },
  { name: 'Malawi', code: '+265', iso: 'MW' },
  { name: 'Malaysia', code: '+60', iso: 'MY' },
  { name: 'Maldives', code: '+960', iso: 'MV' },
  { name: 'Mali', code: '+223', iso: 'ML' },
  { name: 'Malta', code: '+356', iso: 'MT' },
  { name: 'Marshall Islands', code: '+692', iso: 'MH' },
  { name: 'Mauritania', code: '+222', iso: 'MR' },
  { name: 'Mauritius', code: '+230', iso: 'MU' },
  { name: 'Mexico', code: '+52', iso: 'MX' },
  { name: 'Micronesia', code: '+691', iso: 'FM' },
  { name: 'Moldova', code: '+373', iso: 'MD' },
  { name: 'Monaco', code: '+377', iso: 'MC' },
  { name: 'Mongolia', code: '+976', iso: 'MN' },
  { name: 'Montenegro', code: '+382', iso: 'ME' },
  { name: 'Morocco', code: '+212', iso: 'MA' },
  { name: 'Mozambique', code: '+258', iso: 'MZ' },
  { name: 'Myanmar', code: '+95', iso: 'MM' },
  { name: 'Namibia', code: '+264', iso: 'NA' },
  { name: 'Nauru', code: '+674', iso: 'NR' },
  { name: 'Nepal', code: '+977', iso: 'NP' },
  { name: 'Netherlands', code: '+31', iso: 'NL' },
  { name: 'New Zealand', code: '+64', iso: 'NZ' },
  { name: 'Nicaragua', code: '+505', iso: 'NI' },
  { name: 'Niger', code: '+227', iso: 'NE' },
  { name: 'Nigeria', code: '+234', iso: 'NG' },
  { name: 'North Macedonia', code: '+389', iso: 'MK' },
  { name: 'Norway', code: '+47', iso: 'NO' },
  { name: 'Oman', code: '+968', iso: 'OM' },
  { name: 'Pakistan', code: '+92', iso: 'PK' },
  { name: 'Palau', code: '+680', iso: 'PW' },
  { name: 'Panama', code: '+507', iso: 'PA' },
  { name: 'Papua New Guinea', code: '+675', iso: 'PG' },
  { name: 'Paraguay', code: '+595', iso: 'PY' },
  { name: 'Peru', code: '+51', iso: 'PE' },
  { name: 'Philippines', code: '+63', iso: 'PH' },
  { name: 'Poland', code: '+48', iso: 'PL' },
  { name: 'Portugal', code: '+351', iso: 'PT' },
  { name: 'Qatar', code: '+974', iso: 'QA' },
  { name: 'Romania', code: '+40', iso: 'RO' },
  { name: 'Russia', code: '+7', iso: 'RU' },
  { name: 'Rwanda', code: '+250', iso: 'RW' },
  { name: 'Saint Kitts and Nevis', code: '+1-869', iso: 'KN' },
  { name: 'Saint Lucia', code: '+1-758', iso: 'LC' },
  { name: 'Saint Vincent and the Grenadines', code: '+1-784', iso: 'VC' },
  { name: 'Samoa', code: '+685', iso: 'WS' },
  { name: 'San Marino', code: '+378', iso: 'SM' },
  { name: 'Sao Tome and Principe', code: '+239', iso: 'ST' },
  { name: 'Saudi Arabia', code: '+966', iso: 'SA' },
  { name: 'Senegal', code: '+221', iso: 'SN' },
  { name: 'Serbia', code: '+381', iso: 'RS' },
  { name: 'Seychelles', code: '+248', iso: 'SC' },
  { name: 'Sierra Leone', code: '+232', iso: 'SL' },
  { name: 'Singapore', code: '+65', iso: 'SG' },
  { name: 'Slovakia', code: '+421', iso: 'SK' },
  { name: 'Slovenia', code: '+386', iso: 'SI' },
  { name: 'Solomon Islands', code: '+677', iso: 'SB' },
  { name: 'Somalia', code: '+252', iso: 'SO' },
  { name: 'South Africa', code: '+27', iso: 'ZA' },
  { name: 'South Korea', code: '+82', iso: 'KR' },
  { name: 'South Sudan', code: '+211', iso: 'SS' },
  { name: 'Spain', code: '+34', iso: 'ES' },
  { name: 'Sri Lanka', code: '+94', iso: 'LK' },
  { name: 'Sudan', code: '+249', iso: 'SD' },
  { name: 'Suriname', code: '+597', iso: 'SR' },
  { name: 'Sweden', code: '+46', iso: 'SE' },
  { name: 'Switzerland', code: '+41', iso: 'CH' },
  { name: 'Syria', code: '+963', iso: 'SY' },
  { name: 'Taiwan', code: '+886', iso: 'TW' },
  { name: 'Tajikistan', code: '+992', iso: 'TJ' },
  { name: 'Tanzania', code: '+255', iso: 'TZ' },
  { name: 'Thailand', code: '+66', iso: 'TH' },
  { name: 'Timor-Leste', code: '+670', iso: 'TL' },
  { name: 'Togo', code: '+228', iso: 'TG' },
  { name: 'Tonga', code: '+676', iso: 'TO' },
  { name: 'Trinidad and Tobago', code: '+1-868', iso: 'TT' },
  { name: 'Tunisia', code: '+216', iso: 'TN' },
  { name: 'Turkey', code: '+90', iso: 'TR' },
  { name: 'Turkmenistan', code: '+993', iso: 'TM' },
  { name: 'Tuvalu', code: '+688', iso: 'TV' },
  { name: 'Uganda', code: '+256', iso: 'UG' },
  { name: 'Ukraine', code: '+380', iso: 'UA' },
  { name: 'United Arab Emirates', code: '+971', iso: 'AE' },
  { name: 'United Kingdom', code: '+44', iso: 'GB' },
  { name: 'United States', code: '+1', iso: 'US' },
  { name: 'Uruguay', code: '+598', iso: 'UY' },
  { name: 'Uzbekistan', code: '+998', iso: 'UZ' },
  { name: 'Vanuatu', code: '+678', iso: 'VU' },
  { name: 'Vatican City', code: '+379', iso: 'VA' },
  { name: 'Venezuela', code: '+58', iso: 'VE' },
  { name: 'Vietnam', code: '+84', iso: 'VN' },
  { name: 'Yemen', code: '+967', iso: 'YE' },
  { name: 'Zambia', code: '+260', iso: 'ZM' },
  { name: 'Zimbabwe', code: '+263', iso: 'ZW' }
];
