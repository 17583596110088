import React, { useEffect, useState } from 'react';

const HamburgerMenu = ({ isOpen, setIsOpen, children }) => {
  // const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  return (
    <div className="lg:hidden">
      {/* Hamburger Icon */}

      {/* Menu Overlay */}
      <div
        className={`fixed top-0 right-0 h-full w-64  z-40 transform transition-transform duration-300 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="bg-transparent h-[5.26rem]" onClick={toggleMenu}></div>
        {children}
      </div>

      {/* Background Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-white opacity-20 z-30"
          onClick={toggleMenu}
        />
      )}
    </div>
  );
};

export default HamburgerMenu;
