import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Question = ({
  question,
  index,
  answer,
  onAnswerChange,
  textareaRef,
  setIsPasted
}) => {
  const [charCount, setCharCount] = useState(answer.length);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const allowPaste = queryParams.get('allowPaste');

  const handleInputChange = (value) => {
    setCharCount(value.length);
    onAnswerChange(value);
  };

  const handlePaste = (e) => {
    if (!allowPaste) {
      e.preventDefault();
    } /////////// TODO: Comment this before prod merge \\\\\\\\\\\\
    // e.preventDefault(); /////////// TODO: Uncomment this before prod merge \\\\\\\\\\\\
    setIsPasted(true);
  };

  return (
    <div className="mt-6 relative m-3 select-none">
      <div className="flex justify-between items-center mb-4 mr-2">
        <label
          htmlFor="message"
          className="block text-base font-medium text-gray-900"
        >
          {index}. {question}
        </label>
        <div className="text-sm text-gray-500">
          {charCount > 500
            ? `${charCount - 500} characters exceeded`
            : `${charCount}/500`}
        </div>
      </div>
      <textarea
        onPaste={handlePaste}
        id="message"
        ref={textareaRef}
        style={{ background: '#80808017' }}
        value={answer}
        onChange={(e) => handleInputChange(e.target.value)}
        rows="4"
        maxlength="500"
        className={`block w-full p-2.5 bg-gray-50 rounded border ${
          charCount > 500
            ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
            : 'border-gray-300 focus:ring-primary-500 focus:border-primary-500'
        }`}
      />
    </div>
  );
};

export default Question;
