import React from 'react';
import PropTypes from 'prop-types';

const DiscQuestion = ({
  question,
  onAnswerChange,
  userAnswers,
  currentPage
}) => {
  return (
    <div className="mb-6">
      {/* <h2 className="text-lg font-semibold text-justify md:text-center mb-4">
          {question.question}
        </h2> */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-2">
        <span className="text-lg font-semibold flex justify-center sm:justify-normal col-span-2">
          OPTIONS
        </span>
        <span className="text-lg font-semibold text-center">MOST LIKE</span>
        <span className="text-lg font-semibold text-center">LEAST LIKE</span>
      </div>
      {question.options.map((option, index) => (
        <div
          key={index}
          className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center bg-gray-50 p-4 rounded-lg shadow-md mb-4"
        >
          <div className="col-span-2">
            <span className="text-md font-bold">{option.word}</span>
            <p className="text-sm text-gray-600 mt-1">
              {option.synonyms.join(', ')}
            </p>
          </div>
          <input
            type="radio"
            name={`most_like_${currentPage}`}
            value={index}
            checked={userAnswers[currentPage]?.mostLike === index}
            onChange={() => onAnswerChange(currentPage, 'mostLike', index)}
            className="form-radio h-5 w-5 text-primary-600 cursor-pointer transition-all duration-200 ease-in-out hover:ring-2 hover:ring-primary-300 focus:ring-2 focus:ring-primary-500 mx-auto"
          />
          <input
            type="radio"
            name={`least_like_${currentPage}`}
            value={index}
            checked={userAnswers[currentPage]?.leastLike === index}
            onChange={() => onAnswerChange(currentPage, 'leastLike', index)}
            className="form-radio h-5 w-5 text-primary-600 cursor-pointer transition-all duration-200 ease-in-out hover:ring-2 hover:ring-primary-300 focus:ring-2 focus:ring-primary-500 mx-auto"
          />
        </div>
      ))}
    </div>
  );
};

DiscQuestion.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        word: PropTypes.string.isRequired,
        synonyms: PropTypes.arrayOf(PropTypes.string).isRequired
      })
    ).isRequired
  }).isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  userAnswers: PropTypes.object.isRequired
};

export default DiscQuestion;
