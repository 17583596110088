import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone =
    destination && destination?.length ? Array.from(destination) : [];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "#f9fafb",

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '',
  padding: grid
  // width: "100%",
});

function convertArrays(arr1, arr2) {
  // Create a unique ID generator
  let idCounter = 0;
  const generateId = () => (idCounter++).toString();

  // Create a helper function to generate the objects with unique IDs
  const createObjects = (arr) =>
    arr.map((content) => ({
      id: generateId(), // Generate a unique ID
      content
    }));

  // Convert both arrays to the desired object format
  const objects1 = createObjects(arr1);
  const objects2 = createObjects(arr2);

  // Return the arrays in the specified format
  return [objects1, objects2];
}

const DragAndDrop = ({
  positiveOptions,
  negativeOptions,
  setOptions,
  hasSorted
}) => {
  let initialState = convertArrays(positiveOptions, negativeOptions);
  const [state, setState] = useState(initialState);

  const handleSelection = (item, i, value) => {
    if (value === 'positive') {
      // if (state[1].length === 1) {
      //   toast.error("Atlease 1 item is required in negative column.");
      //   return;
      // }
      onDragEnd({
        draggableId: `${item.id}`,
        type: 'DEFAULT',
        source: {
          index: i,
          droppableId: `${1}`
        },
        reason: 'DROP',
        mode: 'FLUID',
        destination: {
          index: state[0]?.length || 0,
          droppableId: `${0}`
        },
        combine: null
      });
    } else {
      // if (state[0].length === 1) {
      //   toast.error("Atlease 1 item is required in positive column.");
      //   return;
      // }
      onDragEnd({
        draggableId: `${item.id}`,
        type: 'DEFAULT',
        source: {
          index: i,
          droppableId: `${0}`
        },
        reason: 'DROP',
        mode: 'FLUID',
        destination: {
          index: state[1]?.length || 0,
          droppableId: `${1}`
        },
        combine: null
      });
    }
  };

  useEffect(() => {
    if (state && state.length === 2) {
      const arr1 = state.length && state[0].map((item) => item.content);
      const arr2 = state.length && state[1].map((item) => item.content);
      setOptions(arr1, arr2);
    }
  }, [state]);

  function onDragEnd(result) {
    // if (!(state[0]?.length !== 1)) {
    //   toast.error("Atlease 1 item is required in positive column.");
    //   return;
    // }

    // if (!(state[1]?.length !== 1)) {
    //   toast.error("Atlease 1 item is required in negative column.");
    //   return;
    // }
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState);
      // setState(newState.filter((group) => group.length));
    }
    hasSorted();
  }

  return (
    <div>
      <div className="flex flex-col w-full">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="flex flex-row w-full">
            {state.map((el, ind) => (
              <>
                {ind === 0 ? (
                  <div className="px-2 gap-2 flex flex-col flex-1">
                    <div className="text-base">
                      Rank from{' '}
                      <span className="font-semibold">
                        MOST <span className="text-green-500">positive</span>
                      </span>{' '}
                      (top) to{' '}
                      <span className="font-semibold">
                        LEAST <span className="text-green-500">positive</span>
                      </span>{' '}
                      (bottom).
                    </div>
                    <div className="text-sm">
                      Drag and drop items to sort them in their respective lists
                      from <span className="font-semibold">MOST</span> to{' '}
                      <span className="font-semibold">LEAST</span>.
                    </div>
                    <div className="text-xs pb-8">
                      Click the{' '}
                      <span className="text-red-500 font-semibold">red</span>{' '}
                      arrow to move a statement to the negative column.
                    </div>
                    <h3 className="text-lg font-semibold ">
                      MOST{' '}
                      <span className="text-green-500">
                        positive at the top
                      </span>
                    </h3>
                  </div>
                ) : (
                  <>
                    <div className="px-2 gap-2 flex flex-col flex-1">
                      <div className="text-base">
                        Rank from{' '}
                        <span className="font-semibold">
                          MOST <span className="text-red-500">negative</span>
                        </span>{' '}
                        (top) to{' '}
                        <span className="font-semibold">
                          LEAST <span className="text-red-500">negative</span>
                        </span>{' '}
                        (bottom).
                      </div>
                      <div className="text-sm">
                        Drag and drop items to sort them in their respective
                        lists from <span className="font-semibold">MOST</span>{' '}
                        to <span className="font-semibold">LEAST</span>.
                      </div>
                      <div className="text-xs pb-8">
                        Click the{' '}
                        <span className="text-green-500 font-semibold">
                          green
                        </span>{' '}
                        arrow to move a statement to the positive column.
                      </div>
                      <h3 className="text-lg font-semibold ">
                        MOST{' '}
                        <span className="text-red-500">
                          negative at the top
                        </span>
                      </h3>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
          <div className="flex flex-row w-full">
            {state.map((el, ind) => (
              <div className="flex flex-col flex-1 w-1/2">
                <Droppable key={ind} droppableId={`${ind}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                      className="w-full h-full"
                    >
                      {el.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className={`${
                                snapshot.isDragging
                                  ? 'bg-primary-200'
                                  : 'bg-gray-50'
                              } p-4 rounded-lg shadow-md space-y-2 w-full`}
                            >
                              <div
                                key={item}
                                className={`flex items-center justify-between ${
                                  state[0].includes(item)
                                    ? 'max-sm:flex-col-reverse'
                                    : 'max-sm:flex-col'
                                } gap-2`}
                              >
                                {state[0].includes(item) ? (
                                  <>
                                    <div className="text-md max-sm:text-center min-h-6 max-sm:min-h-24 max-sm:flex max-sm:flex-col justify-center ">
                                      {item.content}
                                    </div>
                                    <button
                                      type="button"
                                      className="bg-red-500 hover:bg-red-600 text-white max-sm:text-xl font-bold py-1 px-2 rounded-lg max-sm:w-full"
                                      onClick={() =>
                                        handleSelection(item, index, 'negative')
                                      }
                                    >
                                      →
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="bg-green-500 hover:bg-green-600 text-white max-sm:text-xl font-bold py-1 px-2 rounded-lg max-sm:w-full"
                                      onClick={() =>
                                        handleSelection(item, index, 'positive')
                                      }
                                    >
                                      {' '}
                                      ←
                                    </button>
                                    <div className="text-md text-right max-sm:text-center min-h-6 max-sm:min-h-24 max-sm:flex max-sm:flex-col justify-center ">
                                      {item.content}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default DragAndDrop;
