import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';

const ChangePasswordModal = ({ isOpen, toggleModal, onClose }) => {
  const { orgName } = useParams();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstInputRef = useRef(null);

  const handleClose = () => {
    onClose();
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (oldPassword === newPassword) {
      toast.error('New Password cannot be same as Old Password');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      toast.error('New Password and Confirm New Password do not match');
      return;
    }
    setLoading(true);

    async function changePassword(oldPassword, newPassword) {
      try {
        const accessToken = localStorage.getItem('session')
          ? JSON.parse(localStorage.getItem('session')).accessToken
          : null;

        if (!accessToken) {
          throw new Error('Access token not found in local storage');
        }

        const response = await fetch(
          `${process.env.REACT_APP_DEV_API}/v1/iam/change-password`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({ oldPassword, newPassword })
          }
        );

        const data = await response.json();

        if (data.message.includes('success')) {
          toast.success('Password Changed successfully');
          setLoading(false);
          handleClose();
        } else {
          toast.error(data.message || 'An error occurred');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error while changing the password:', error);

        toast.error(error.message || 'An error occurred');
        setLoading(false);
      }
    }
    changePassword(oldPassword, newPassword);
  };

  useEffect(() => {
    if (isOpen && firstInputRef.current) {
      firstInputRef.current.focus();
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setShowOldPassword(false);
      setShowNewPassword(false);
      setShowConfirmNewPassword(false);
      setLoading(false);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50"
          onClick={toggleModal}
        >
          <div
            className="bg-white p-6 rounded shadow-lg max-w-md w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-3 pb-3 border-b">
              <h2 className="text-2xl font-bold">Change Password</h2>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center z-50"
                onClick={handleClose}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="flex flex-col items-center">
              <form className="w-full" onSubmit={handleChangePassword}>
                <div className="mb-4 w-full">
                  <label htmlFor="password" className="block mb-2">
                    Old Password
                  </label>
                  <div className="flex items-center">
                    <input
                      ref={firstInputRef}
                      type={showOldPassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className=" p-2 w-full border border-gray-300 rounded"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      placeholder="Type Your Password"
                      disabled={loading}
                      required
                    />
                    <button
                      tabIndex="-1"
                      type="button"
                      className="absolute right-[34px] focus:outline-none ml-2 flex items-center justify-center rounded-md"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                </div>

                <div className="mb-4 w-full">
                  <label htmlFor="password" className="block mb-2">
                    New Password
                  </label>
                  <div className="flex items-center">
                    <input
                      type={showNewPassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className=" p-2 w-full border border-gray-300 rounded"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Password"
                      disabled={loading}
                      required
                    />
                    <button
                      tabIndex="-1"
                      type="button"
                      className="absolute right-[34px] focus:outline-none ml-2 flex items-center justify-center rounded-md"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                </div>
                <div className="mb-4 w-full">
                  <label htmlFor="password" className="block mb-2">
                    Confirm New Password
                  </label>
                  <div className="flex items-center">
                    <input
                      type={showConfirmNewPassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className=" p-2 w-full border border-gray-300 rounded"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      placeholder="Confirm Password"
                      disabled={loading}
                      required
                    />
                    <button
                      tabIndex="-1"
                      type="button"
                      className="absolute right-[34px] focus:outline-none ml-2 flex items-center justify-center rounded-md"
                      onClick={() =>
                        setShowConfirmNewPassword(!showConfirmNewPassword)
                      }
                    >
                      {showConfirmNewPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <button
                    type="submit"
                    disabled={loading}
                    className="min-w-24 h-11 flex justify-center bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700"
                  >
                    {loading ? (
                      <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                    ) : (
                      'Change Password'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* {loading && (
            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
              <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default ChangePasswordModal;
