import React, { useState, useRef, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Questions from '../Questions';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Datepicker from 'tailwind-datepicker-react';
import { format } from 'date-fns';
import {
  countryCodes,
  isValidDate,
  toggleScrollBarFormRootHTMLTag
} from '../../utils/CommonFunctions';
import { BsCalendar3 } from 'react-icons/bs';
import { TagsInput } from 'react-tag-input-component';
import './ApplyJobModal.css';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { useHelperBot } from '../HelperBot';
import currencySymbols from 'currency-symbol-map';
import LocationInput from './locationInput';
import { Dropdown } from 'react-bootstrap';
import Flag from 'react-world-flags';

const components = {
  DropdownIndicator: null // Hide the dropdown indicator
};

const earliestDatePickerConfig = {
  title: 'Earliest Available Start Date',
  autoHide: true,
  todayBtn: false,
  clearBtn: false,
  clearBtnText: 'Clear',
  minDate: new Date(new Date().setHours(0, 0, 0, 0)),
  maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // One year from now
  theme: {
    background: 'bg-white dark:bg-gray-800',
    todayBtn: '',
    clearBtn: '',
    icons: '',
    text: '',
    disabledText: '!text-gray-400 cursor-not-allowed',
    input: '',
    inputIcon: '',
    selected: 'bg-primary-600 text-white hover:bg-primary-700'
  },
  datepickerClassNames: 'top-[-5px]',
  language: 'en',
  disabledDates: [],
  weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  inputNameProp: 'earliestStartDate',
  inputIdProp: 'earliestStartDate',
  inputPlaceholderProp: 'Select Date',
  inputDateFormatProp: {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }
};

const datePickerConfig = {
  title: 'Date of Birth',
  autoHide: true,
  todayBtn: false,
  clearBtn: false,
  clearBtnText: 'Clear',
  maxDate: new Date(`12/31/${new Date().getFullYear() - 10}`),
  minDate: new Date('1950-01-01'),
  theme: {
    background: 'bg-white dark:bg-gray-800',
    todayBtn: '',
    clearBtn: '',
    icons: '',
    text: '',
    disabledText: '!text-gray-400 cursor-not-allowed',
    input: '',
    inputIcon: '',
    selected: 'bg-primary-600 text-white hover:bg-primary-700'
  },
  datepickerClassNames: 'top-[-5px]',
  language: 'en',
  disabledDates: [],
  weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  inputNameProp: 'date',
  inputIdProp: 'date',
  inputPlaceholderProp: 'Select Date',
  inputDateFormatProp: {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }
};

const ApplyJobModal = ({
  isOpen,
  jobId,
  onClose,
  parsedResumeContent,
  resumeId
}) => {
  const { orgName } = useParams();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dob, setDob] = useState({ dd: '', mm: '', yyyy: '' });
  const [earliestStartDate, setEarliestStartDate] = useState('');
  const [showEarliestDatePicker, setShowEarliestDatePicker] = useState(false);
  const [date, setDate] = useState('');
  const [phone, setPhone] = useState('');
  const [linkedinProfileLink, setlinkedinProfileLink] = useState('');
  const [linkedinProfileLinkError, setlinkedinProfileLinkError] = useState('');
  const [locationSelected, setLocationSelected] = useState(false);
  const [address, setAddress] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [gender, setGender] = useState('');
  const [education, setEducation] = useState('');
  const [experience, setExperience] = useState('');
  const [noticePeriodDays, setNoticePeriodDays] = useState('');
  const [skills, setSkills] = useState([]);
  const [currentCTC, setCurrentCTC] = useState('');
  const [currentCTCDisplay, setCurrentCTCDisplay] = useState('');
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  // const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [countryCodeMenuStyle, setCountryCodeMenuStyle] = useState({});
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    name: 'India',
    code: '+91',
    iso: 'IN'
  });
  // const [date, setDate] = useState({ dd: "", mm: "", yyyy: "" });
  const ddRef = useRef(null);
  const dobInputRef = useRef(null);
  const mmRef = useRef(null);
  const yyyyRef = useRef(null);
  const datePickerRef = useRef();
  const datePickerInputRef = useRef();
  const countryCodeRef = useRef(null);
  const inputRef = useRef(null);
  // const earliestDateInputRef = useRef(null);
  const earliestDatePickerRef = useRef();
  const earliestDateInputRef = useRef();
  const currencySymbol = localStorage.getItem('currency') || 'INR';
  const [countryCodeSearch, setCountryCodeSearch] = useState('');

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  const { helperMessage } = useHelperBot();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    if (window.innerWidth < 576) {
      setCountryCodeMenuStyle({
        width: window.innerWidth - 20 + 'px'
      });
    }

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (showDropdown) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveIndex((prevIndex) =>
          prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
      } else if (e.key === 'Enter' && activeIndex >= 0) {
        e.preventDefault();
        handleSelectLocation(suggestions[activeIndex]);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      toggleScrollBarFormRootHTMLTag(true);
    }

    return () => {};
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (parsedResumeContent) {
      const formattedPhoneNumber = parsedResumeContent.personal_info.phoneNumber
        ? parsedResumeContent.personal_info.phoneNumber
            .replace(/\D/g, '')
            .slice(-10)
        : '';
      let linkedinLink = '';
      const linkedinLinkIndex =
        parsedResumeContent?.socialLinks &&
        Array.isArray(parsedResumeContent.socialLinks)
          ? parsedResumeContent.socialLinks.findIndex(
              (social) => social.platform === 'LinkedIn'
            )
          : -1;
      if (linkedinLinkIndex !== -1) {
        linkedinLink = parsedResumeContent.socialLinks[linkedinLinkIndex].url;
      }
      if (parsedResumeContent?.personal_info.dob) {
        handleSetDob(parsedResumeContent.personal_info.dob);
      }
      setPhone(formattedPhoneNumber || '');
      setAddress(
        parsedResumeContent.address ||
          parsedResumeContent.personal_info.location ||
          ''
      );
      setGender(parsedResumeContent.personal_info.gender?.toUpperCase() || '');
      setEducation(parsedResumeContent.highestEducation || '');
      setExperience(parsedResumeContent.totalExperience?.toString() || '');
      setSkills(parsedResumeContent.skills || []);
      setCurrentCTC(parsedResumeContent.currentCTC || '');
      setlinkedinProfileLink(linkedinLink);
    }
  }, [parsedResumeContent]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target) &&
        !datePickerInputRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
      if (
        earliestDatePickerRef.current &&
        !earliestDatePickerRef.current.contains(event.target) &&
        !earliestDateInputRef.current.contains(event.target)
      ) {
        setShowEarliestDatePicker(false);
      }
      if (
        countryCodeRef.current &&
        !countryCodeRef.current.contains(event.target)
      ) {
        setShowCountryDropdown(false);
        setCountryCodeSearch('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker, showEarliestDatePicker]);

  const handleClose = () => {
    toggleScrollBarFormRootHTMLTag(false);
    onClose();
    setMessage('');
    setDate('');
    setPhone('');
    setAddress('');
    setGender('');
    setEducation('');
    setExperience('');
    setSkills([]);
    setCurrentCTC('');
    // setResume(null);
  };

  const isPrevButtonDisabled = () => {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const earliestDate = parseEarliestDate(earliestStartDate);
    return (
      earliestDate.getFullYear() === currentYear &&
      earliestDate.getMonth() === currentMonth
    );
  };

  const isNextButtonDisabled = () => {
    const currentDate = new Date();
    const maxDate = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const earliestDate = parseEarliestDate(earliestStartDate);
    return (
      earliestDate.getFullYear() === maxDate.getFullYear() &&
      earliestDate.getMonth() === maxDate.getMonth()
    );
  };

  const handleSkillsChange = (newValue) => {
    const filteredSkills = newValue.filter((skill) => skill.trim() !== '');

    setSkills(filteredSkills);
  };

  const handleSkillsChangeBlur = (event, removeLastChar = false) => {
    let value = event.target.value;
    if (value) {
      if (removeLastChar) {
        value = value.slice(0, -1);
      }
    }

    if (value) {
      const doesExist = skills.includes(value);
      if (!doesExist) {
        handleSkillsChange([...skills, value]);
      }

      event.target.value = '';
    }
  };

  // const customStyles = {
  //   input: (provided) => ({
  //     ...provided,
  //     cursor: "pointer",
  //     "--tw-ring-shadow": "none",
  //   }),
  // };

  const validateFormData = ({
    date,
    phone,
    address,
    gender,
    education,
    experience,
    userLocation,
    noticePeriodDays,
    earliestStartDate,
    currentCTC,
    resumeIdString,
    linkedinProfileLink
  }) => {
    if (!date) {
      return 'Date of birth is required.';
    }
    // if(!userLocation) return "Location is required";
    if (!phone) {
      return 'Phone number is required.';
    }
    if (!/^\d{10}$/.test(phone)) {
      return 'Phone number must be 10 digits.';
    }
    if (!gender) {
      return 'Gender is required.';
    }
    if (!noticePeriodDays) {
      return 'Notice Period Days is required.';
    }
    if (!currentCTC) {
      return 'Current CTC is required.';
    }
    if (!/^\d+(\.\d{1,2})?$/.test(currentCTC)) {
      return 'Current CTC must be a valid number.';
    }
    if (!education) {
      return 'Education is required.';
    }
    if (!experience) {
      return 'Experience is required.';
    }
    if (!address) {
      return 'Complete Address is required.';
    }
    if (!resumeIdString) {
      return 'Resume Id is not available.';
    }
    if (
      linkedinProfileLink &&
      !isValidlinkedinProfileLink(linkedinProfileLink)
    ) {
      return 'Please enter a valid LinkedIn profile URL or leave it empty.';
    }
    return null;
  };

  const handleRejectionModalClose = () => {
    setShowModal(false);
    navigate(`/${orgName}`);
  };

  // const isValidlinkedinProfileLink = (url) => {
  //   if (!url) return true;
  //   const linkedInRegex = /^https:\/\/(www\.)?linkedin\.com\/in\/[\w-]+\/?$/;
  //   return linkedInRegex.test(url);
  // };

  const isValidlinkedinProfileLink = (url) => {
    if (!url) {
      return true;
    } // Empty URL is considered valid as it's optional
    try {
      const parsedUrl = new URL(url);
      const linkedInRegex =
        /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[\w-]+\/?$/i;
      return linkedInRegex.test(parsedUrl.href);
    } catch {
      return false; // URL is not properly formatted
    }
  };

  const handleApplyJob = async (e) => {
    e.preventDefault();
    if (!locationSelected) {
      setMessage('Please select a location from the dropdown.');
      toast.error('Please select a location from the dropdown.');
      return;
    }

    if (date.length !== 10) {
      setMessage('Date of birth is not valid');
      toast.error('Date of birth is not valid');
      return;
    }

    const dateArr = date.split('/');
    const reqDate = new Date(`${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`);
    const resumeIdString = resumeId.toString();
    const formData = {
      date: reqDate,
      phone,
      location: userLocation,
      address,
      gender,
      education,
      noticePeriodDays,
      experience,
      currentCTC,
      resumeIdString,
      linkedinProfileLink
    };

    let reqjoiningdate;
    if (earliestStartDate) {
      const joiningdateArr = earliestStartDate.split('/');
      reqjoiningdate = new Date(
        `${joiningdateArr[1]}/${joiningdateArr[0]}/${joiningdateArr[2]}`
      );
      formData.earliestStartDate = reqjoiningdate; // Add to formData if it exists
    }

    const errorMessage = validateFormData(formData);
    if (errorMessage) {
      setMessage(errorMessage);
      toast.error(errorMessage);
      return;
    }

    if (!isValidDate(date)) {
      setMessage('Date of birth is not valid');
      toast.error('Date of birth is not valid');
      return;
    }

    setLoading(true);

    try {
      const accessToken = localStorage.getItem('session')
        ? JSON.parse(localStorage.getItem('session')).accessToken
        : null;

      if (!accessToken) {
        throw new Error('Access token not found in local storage');
      }

      const formDataToSend = new FormData();
      formDataToSend.append('dob', reqDate);
      formDataToSend.append('phone', phone);
      formDataToSend.append('address', address);
      formDataToSend.append('gender', gender.toUpperCase());
      formDataToSend.append('education', education);
      formDataToSend.append('noticePeriodDays', noticePeriodDays);
      if (reqjoiningdate) {
        formDataToSend.append('earliestStartDate', reqjoiningdate);
      }
      formDataToSend.append('experience', experience);
      formDataToSend.append('currentCTC', currentCTC);
      formDataToSend.append('resumeId', resumeIdString);
      formDataToSend.append('location', userLocation);
      formDataToSend.append('countryCode', selectedCountryCode.code);
      // formDataToSend.append("linkedinProfileLink",linkedinProfileLink);
      if (linkedinProfileLink.trim()) {
        formDataToSend.append(
          'linkedinProfileLink',
          linkedinProfileLink.trim()
        );
      }

      if (skills && skills.length > 0) {
        skills.forEach((skill, index) => {
          formDataToSend.append(`skills[${index}]`, skill);
        });
      }
      const applyResponse = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/apply`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          body: formDataToSend
        }
      );

      if (!applyResponse.ok) {
        throw new Error('Failed to apply for the job');
      }

      const data = await applyResponse.json();
      const appId = data.data.applicationId;

      setLoading(false);
      toast.success('Application submitted successfully.');
      toggleScrollBarFormRootHTMLTag(false);
      navigate(
        `/${orgName}/analyze-candidate/details?jobId=${jobId}&appId=${appId}`
      );
      let arr = [
        'I’m matching up your info with the job. Hang on just a little longer, we’re getting there!',
        'Still with me? Awesome! I’m just making sure you’re a great fit.',
        'Almost done! We’ll be moving on in just a sec.'
      ];
      helperMessage(arr[Math.floor(Math.random() * arr.length)]);
      document.body.classList.remove('overflow-hidden');
    } catch (error) {
      setLoading(false);
      setMessage(error.message);
      toast.error(error.message);
      console.error('Error applying for job:', error);
      document.body.classList.remove('overflow-hidden');
    }

    fetchAllApplicationUserHaveAppliedFor();
  };

  const fetchAllApplicationUserHaveAppliedFor = async () => {
    try {
      const session = localStorage.getItem('session');
      const accessToken = session ? JSON.parse(session).accessToken : null;

      if (!accessToken) {
        return;
      }
      let url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/applied-applications`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.statusCode === 200) {
        let jobsObj = {};
        data?.data?.applications.forEach((application) => {
          jobsObj[application.job] = { ...application };
        });
        dispatch({ type: 'SET_APPLIED_JOBS_OBJ', payload: jobsObj });
      } else {
        console.error('Failed to fetch applications :', data.message);
      }
    } catch (error) {
      console.error('Error fetching applications:', error);
    }
  };

  const handleSetDob = (value) => {
    const dateObj = new Date(value);
    if (dateObj) {
      const dd = String(dateObj.getDate()).padStart(2, '0');
      const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
      const yyyy = String(dateObj.getFullYear());
      setDate(`${dd}/${mm}/${yyyy}`);
    }
  };

  const handleLocationInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      setQuery(newValue); // Set the query based on the typed input
      if (newValue) {
        fetchLocationSuggestions(newValue); // Fetch suggestions based on input
        setShowDropdown(true);
      } else {
        setSuggestions([]);
        setShowDropdown(false);
      }
    }
  };

  const transformSuggestions = (suggestionsArray) => {
    return suggestionsArray.map((suggestion) => ({
      label: suggestion.displayName, // Display text for the dropdown
      value: suggestion.displayName // The actual value selected
    }));
  };

  const fetchLocationSuggestions = async (city) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/default/city-suggestions?query=${city}`
      );
      const data = await response.json();
      setSuggestions(transformSuggestions(data.data || [])); // Transform to { label, value }
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
    }
  };

  const handleSelectLocation = (selectedOption) => {
    setQuery(selectedOption.label);
    setShowDropdown(false);
  };

  const customStyles = {
    // Your existing input style
    input: (provided) => ({
      ...provided,
      cursor: 'pointer',
      '--tw-ring-shadow': 'none'
    }),

    // Control styling to manage the overall look of the dropdown
    control: (baseStyles, state) => ({
      ...baseStyles,
      padding: '2px',
      borderColor: state.isFocused
        ? 'var(--color-primary-500)'
        : baseStyles.borderColor,
      boxShadow: state.isFocused
        ? '0 0 0 1px var(--color-primary-500)'
        : baseStyles.boxShadow,
      '&:hover': {
        borderColor: 'var(--color-primary-500)'
      }
    }),

    // Styles for the selected value within the dropdown
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: 'var(--color-gray-900)'
    }),

    // Placeholder text styling
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: 'var(--color-gray-500)'
    }),

    // Dropdown menu (suggestions box) styling
    menu: (baseStyles) => ({
      ...baseStyles,
      borderColor: 'var(--color-gray-300)'
    }),

    // Individual option styling (for each item in the dropdown)
    option: (baseStyles, { isSelected, isFocused }) => ({
      ...baseStyles,
      backgroundColor: isSelected
        ? 'var(--color-primary-300)' // Background for selected option
        : isFocused
          ? 'var(--color-primary-100)' // Background for focused option
          : baseStyles.backgroundColor,
      color: isSelected ? 'var(--color-primary-900)' : baseStyles.color,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--color-primary-100)'
      }
    })
  };

  const remoteOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];

  const genderOptions = [
    { value: 'MALE', label: 'Male' },
    { value: 'FEMALE', label: 'Female' },
    { value: 'OTHER', label: 'Other' }
  ];

  useEffect(() => {
    if (isOpen && ddRef.current) {
      ddRef.current.focus();
    }
  }, [isOpen]);

  const renderSubmitbutton = () => {
    if (
      !date ||
      !phone ||
      !gender ||
      !currentCTC ||
      !noticePeriodDays ||
      !education ||
      !experience ||
      !address ||
      !resumeId
    ) {
      return (
        <button
          type="button"
          className="button min-w-24 h-11 flex justify-center bg-primary-600 text-white py-2 px-4 rounded !cursor-not-allowed opacity-50"
          style={{ maxWidth: '30%' }}
        >
          {loading ? (
            <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
          ) : (
            'Submit'
          )}
        </button>
      );
    }

    return (
      <button
        type="button"
        className="button min-w-24 h-11 flex justify-center bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700"
        style={{ maxWidth: '30%' }}
        onClick={handleApplyJob}
      >
        {loading ? (
          <div className="w-8 h-8 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
        ) : (
          'Submit'
        )}
      </button>
    );
  };

  const handleInputChange = (e) => {
    if (showDatePicker) {
      setShowDatePicker(false);
    }
    let value = e.target.value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    let formattedDate = '';
    let day = '';
    let month = '';

    if (value.length > 0) {
      // Handle day
      let isMonthUpdated = false;
      day = value.slice(0, 2);
      if (day.length === 1 && day > 3) {
        day = `0${day}`;
        value = value.slice(1);
      } else if (day.length === 2) {
        if (day === '00') {
          day = '01';
        } else if (day[0] === '3' && day[1] > '1') {
          month = `0${day[1]}`; // Update month with the next character
          day = '03';
          value = value.slice(2);
          isMonthUpdated = true;
        }
      }
      formattedDate = day;
      if (isMonthUpdated) {
        formattedDate += `/${month}`;
      }
    }

    if (value.length > 2) {
      // Handle month
      if (!month) {
        month = value.slice(2, 4);
        if (month.length === 1 && month > 1) {
          month = `0${month}`;
          value = value.slice(3);
        } else if (month.length === 2) {
          if (month === '00') {
            month = '01';
          } else if (month > 12) {
            month = '12';
          }
        }
      }
      formattedDate += `/${month}`;
    }

    if (value.length > 4) {
      // Handle year
      let year = value.slice(4, 8);
      if (year.length === 4) {
        const currentYear = new Date().getFullYear();
        const maxYear = currentYear - 10;
        if (year < 1950) {
          year = 1950;
        } else if (year > maxYear) {
          year = maxYear;
        }
      }
      formattedDate += `/${year}`;
    }

    setDate(formattedDate);

    if (value.length === 8) {
      validateDate(formattedDate);
    }
  };

  const handleEarliestDateInputChange = (e) => {
    if (showEarliestDatePicker) {
      setShowEarliestDatePicker(false);
    }
    let value = e.target.value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    let formattedDate = '';
    let day = '';
    let month = '';

    if (value.length > 0) {
      // Handle day
      let isMonthUpdated = false;
      day = value.slice(0, 2);
      if (day.length === 1 && day > 3) {
        day = `0${day}`;
        value = value.slice(1);
      } else if (day.length === 2) {
        if (day === '00') {
          day = '01';
        } else if (day[0] === '3' && day[1] > '1') {
          month = `0${day[1]}`; // Update month with the next character
          day = '03';
          value = value.slice(2);
          isMonthUpdated = true;
        }
      }
      formattedDate = day;
      if (isMonthUpdated) {
        formattedDate += `/${month}`;
      }
    }

    if (value.length > 2) {
      // Handle month
      if (!month) {
        month = value.slice(2, 4);
        if (month.length === 1 && month > 1) {
          month = `0${month}`;
          value = value.slice(3);
        } else if (month.length === 2) {
          if (month === '00') {
            month = '01';
          } else if (month > 12) {
            month = '12';
          }
        }
      }
      formattedDate += `/${month}`;
    }

    if (value.length > 4) {
      // Handle year

      let year = value.slice(4, 8);
      if (year.length === 4) {
        const currentYear = new Date().getFullYear();
        const maxYear = currentYear + 1;
        if (year <= currentYear) {
          year = currentYear;
        } else if (year > maxYear) {
          year = maxYear;
        }
      }
      formattedDate += `/${year}`;
    }

    setEarliestStartDate(formattedDate);

    if (value.length === 8) {
      validateEarliestDate(formattedDate);
    }
  };

  const validateEarliestDate = (date) => {
    const [day, month, year] = date.split('/').map(Number);
    const selectedDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    // const maxDate = new Date(
    //   currentDate.setFullYear(currentDate.getFullYear() + 1)
    // );
    const maxDate = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (selectedDate < currentDate) {
      setEarliestStartDate(format(currentDate, 'dd/MM/yyyy'));
    } else if (selectedDate > maxDate) {
      setEarliestStartDate(format(maxDate, 'dd/MM/yyyy'));
    } else {
      setEarliestStartDate(format(selectedDate, 'dd/MM/yyyy'));
    }
  };

  const formatNumberWithCommas = (value) => {
    if (!value) {
      return '';
    }

    const currency = localStorage.getItem('currency') || 'INR';
    // Remove any existing commas and non-numeric characters (except for decimal point)
    const cleanValue = value.replace(/[^\d.]/g, '');

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = cleanValue.split('.');

    let formattedInteger;
    if (currency === 'INR') {
      // Format for Indian numbering system
      formattedInteger = integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
    } else {
      // Format for Western numbering system
      formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    // Combine the formatted integer part with the decimal part (if it exists)
    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };

  const handleCTCChange = (e) => {
    const inputValue = e.target.value;

    const numericValue = inputValue.replace(/,/g, '');

    const cursorPosition = e.target.selectionStart;

    setCurrentCTC(numericValue);
    setCurrentCTCDisplay(formatNumberWithCommas(numericValue));

    setTimeout(() => {
      e.target.selectionStart = cursorPosition;
      e.target.selectionEnd = cursorPosition;
    }, 0);
  };

  const validateDate = (earliestStartDate) => {
    const [day, month, year] = earliestStartDate.split('/').map(Number);
    let maxDays = 31;

    if (month === 2) {
      maxDays = isLeapYear(year) ? 29 : 28;
    } else if ([4, 6, 9, 11].includes(month)) {
      maxDays = 30;
    }

    if (day > maxDays) {
      setEarliestStartDate(`${padZero(maxDays)}/${padZero(month)}/${year}`);
    }
  };

  const padZero = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const parseEarliestDate = (input) => {
    if (!input) {
      return new Date(new Date().setHours(0, 0, 0, 0));
    }

    const parts = input.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
    const year = parseInt(parts[2], 10);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return new Date(new Date().setHours(0, 0, 0, 0)); // Return current date if parsing fails
    }

    const parsedDate = new Date(year, month, day);
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const maxDate = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    // Ensure the date is within the allowed range
    if (parsedDate < currentDate) {
      return currentDate;
    } else if (parsedDate > maxDate) {
      return maxDate;
    }

    return parsedDate;
  };

  const parseDate = (input) => {
    // Split the input string by '/'
    const parts = input.split('/');

    const y =
      parts[2] && parts[2].length === 4
        ? parts[2]
        : new Date().getFullYear() - 10;

    const m = parts[1] ? parts[1] : '12';

    const d = parts[0] ? parts[0] : '31';

    // Re-arrange the parts to yyyy-mm-dd
    const formattedDate = `${y}-${m}-${d}`;

    // Create a new Date object
    const earliestStartDate = new Date(formattedDate);

    return earliestStartDate;
  };

  const handleSetEarliestDate = (value) => {
    const dateObj = new Date(value);
    if (dateObj) {
      const formattedDate = format(dateObj, 'dd/MM/yyyy');
      setEarliestStartDate(formattedDate);
    }
  };

  useEffect(() => {
    const container = document.getElementById('applyJobModal');
    if (!container) {
      return;
    }

    const focusableElements = container.querySelectorAll(
      'input, button, select, textarea, a[href]'
    );
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    const handleKeyDown = (e) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // Shift + Tab
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        // Tab
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    };

    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-[2] flex items-center justify-center overflow-y-hidden bg-black bg-opacity-50 select-none"
          id="applyJobModal"
          tabIndex="0"
        >
          <div className="relative p-4 w-full max-w-2xl h-auto md:h-auto">
            <div className="relative p-4 bg-white rounded-lg shadow sm:p-5 overflow-y-auto max-h-[90vh] mt-[5vh]">
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5">
                <h3 className="text-lg font-semibold text-gray-900">
                  Apply Job
                </h3>
                <button
                  type="button"
                  className="button text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center z-50"
                  onClick={handleClose}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <form action="#" className="relative">
                <div className=" grid gap-4 mb-4 sm:grid-cols-2">
                  <div className="relative">
                    <label
                      htmlFor="Dob"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Date of Birth
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div
                      ref={datePickerInputRef}
                      id="dob-input"
                      className="flex justify-between align-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 w-full p-[10px]"
                      onClick={() => {
                        if (!showDatePicker) {
                          setShowDatePicker(true);
                        }
                      }}
                    >
                      <div className="w-[100%]">
                        <input
                          className="text-sm border-none focus:ring-0 w-[100%] p-0 ml-[5px] bg-transparent placeholder-gray-300"
                          type="text"
                          id="date-input"
                          value={date}
                          onChange={handleInputChange}
                          maxLength="10"
                          placeholder="DD/MM/YYYY"
                          onClick={() => {
                            if (inputRef.current) {
                              // Move the cursor to the end of the input value
                              inputRef.current.selectionStart =
                                inputRef.current.selectionEnd = date.length;
                            }
                          }}
                          ref={inputRef}
                        />
                      </div>
                      <BsCalendar3
                        size={20}
                        className="mr-[10px] text-primary-600"
                      />
                    </div>
                    {showDatePicker && (
                      <div
                        className="calander-custom-style absolute z-50 block pt-2 top-30"
                        ref={datePickerRef}
                      >
                        <Datepicker
                          options={{
                            ...datePickerConfig,
                            defaultDate: parseDate(date),
                            icons: {
                              // () => ReactElement | JSX.Element
                              prev: () => (
                                <span
                                  className={`${
                                    new Date('01/01/1950').getMonth() + 1 ===
                                      parseDate(date).getMonth() + 1 &&
                                    new Date('01/01/1950').getFullYear() ===
                                      parseDate(date).getFullYear()
                                      ? 'disabled'
                                      : ''
                                  }`}
                                  onClick={(e) => {
                                    const minDate = new Date('01/01/1950');
                                    const minDateMonth = minDate.getMonth() + 1;
                                    const minDateYear = minDate.getFullYear();
                                    const selectedDate = parseDate(date);
                                    const selectedDataMonth =
                                      selectedDate.getMonth() + 1;
                                    const selectedDateYear =
                                      selectedDate.getFullYear();
                                    if (
                                      selectedDataMonth === minDateMonth &&
                                      selectedDateYear === minDateYear
                                    ) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }}
                                >
                                  <FaArrowLeftLong />
                                </span>
                              ),
                              next: () => (
                                <span
                                  className={`${
                                    parseDate(date).getMonth() + 1 ===
                                      new Date(
                                        `12/31/${new Date().getFullYear() - 10}`
                                      ).getMonth() +
                                        1 &&
                                    parseDate(date).getFullYear() ===
                                      new Date(
                                        `12/31/${new Date().getFullYear() - 10}`
                                      ).getFullYear()
                                      ? 'disabled'
                                      : ''
                                  }`}
                                  onClick={(e) => {
                                    const currentDate = new Date(
                                      `12/31/${new Date().getFullYear() - 10}`
                                    );
                                    const currentMonth =
                                      currentDate.getMonth() + 1;
                                    const currentYear =
                                      currentDate.getFullYear();
                                    const selectedDate = parseDate(date);
                                    const selectedDataMonth =
                                      selectedDate.getMonth() + 1;
                                    const selectedDateYear =
                                      selectedDate.getFullYear();
                                    if (
                                      selectedDataMonth === currentMonth &&
                                      selectedDateYear === currentYear
                                    ) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }}
                                >
                                  <FaArrowRightLong />
                                </span>
                              )
                            }
                          }}
                          value={parseDate(date)}
                          onChange={(e) => handleSetDob(e)}
                          show={true}
                          setShow={(e) =>
                            setTimeout(() => setShowDatePicker(false), 200)
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="Phone"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Phone
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="flex gap-0" ref={countryCodeRef}>
                      {/* <label className="bg-white border border-r-0 rounded-tl rounded-bl border-gray-300 p-2 text-sm flex items-center">
                        +91
                      </label> */}
                      <div
                        className="custom-dropdown-container form-control solid style-1 flex align-items-center"
                        style={{ width: 108 }}
                      >
                        <button
                          tabIndex="0"
                          as="div"
                          className="custom-dropdown-toggle border border-gray-300 w-full"
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              e.target.click();
                            }
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setCountryCodeSearch('');
                            setShowCountryDropdown(!showCountryDropdown);
                          }}
                        >
                          <Flag
                            code={selectedCountryCode.iso}
                            style={{ height: 15 }}
                          />{' '}
                          {selectedCountryCode.code}
                        </button>
                        <div
                          className={`custom-dropdown-menu ${showCountryDropdown ? '' : 'hidden'}`}
                          style={{
                            ...countryCodeMenuStyle,
                            overflowY: 'hidden',
                            height: 'auto',
                            maxHeight: '200px',
                            top: '42px'
                          }}
                        >
                          <div>
                            <div className="flex justify-between items-center p-2 border-b border-gray-300">
                              <input
                                type="text"
                                value={countryCodeSearch}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300"
                                placeholder="Search"
                                onChange={(e) => {
                                  setCountryCodeSearch(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{ maxHeight: '138px', overflowY: 'auto' }}
                          >
                            {countryCodes.filter((countryCode) => {
                              return countryCode.name
                                .toLowerCase()
                                .includes(countryCodeSearch.toLowerCase());
                            }).length === 0 ? (
                              <li>No country code found</li>
                            ) : (
                              countryCodes
                                .filter((countryCode) => {
                                  return countryCode.name
                                    .toLowerCase()
                                    .includes(countryCodeSearch.toLowerCase());
                                })
                                .map((value) => (
                                  <li
                                    key={value.iso}
                                    onClick={() => {
                                      setSelectedCountryCode(value);
                                      setShowCountryDropdown(false);
                                      setCountryCodeSearch('');
                                    }}
                                  >
                                    <Flag
                                      code={value.iso}
                                      style={{ height: 15 }}
                                    />{' '}
                                    {value.code} {value.name}
                                  </li>
                                ))
                            )}
                          </div>
                        </div>
                      </div>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => {
                          if (/^[0-9]{0,10}$/.test(e.target.value)) {
                            setPhone(e.target.value);
                          }
                        }}
                        name="phone"
                        id="phone"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-tr rounded-br focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300"
                        placeholder="XXX XXXX XXX"
                        min="0"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="gender"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Gender
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <Select
                      id="gender"
                      name="gender"
                      placeholder="Select Gender"
                      value={
                        genderOptions.find(
                          (option) => option.value === gender
                        ) || null
                      }
                      options={genderOptions}
                      onChange={(selectedOption) =>
                        setGender(selectedOption.value)
                      }
                      className="text-gray-900 text-base focus:ring-primary-600 focus:border-primary-600 block w-full"
                      isSearchable={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: 'rgb(249, 250, 251)',
                          padding: '2px',
                          borderColor: state.isFocused
                            ? 'var(--color-primary-500)'
                            : baseStyles.borderColor,
                          boxShadow: state.isFocused
                            ? '0 0 0 1px var(--color-primary-500)'
                            : baseStyles.boxShadow,
                          '&:hover': {
                            borderColor: 'var(--color-primary-500)'
                          }
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          color: 'var(--color-gray-900)'
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          color: 'rgb(209, 213, 219)'
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: 'rgb(209, 213, 219)'
                        }),
                        option: (baseStyles, { isSelected, isFocused }) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? 'var(--color-primary-300)'
                            : isFocused
                              ? 'var(--color-primary-100)'
                              : baseStyles.backgroundColor,
                          color: isSelected
                            ? 'var(--color-primary-900)'
                            : baseStyles.color,
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'var(--color-primary-100)'
                          }
                        })
                      }}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="CTC"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Current Annual CTC ({`${currencySymbol}`})
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      value={currentCTCDisplay}
                      onChange={handleCTCChange}
                      name="CTC"
                      id="CTC"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300"
                      placeholder={`${
                        currencySymbol === 'INR' ? '15,00,000' : '50,000'
                      }`}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Education"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Education
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      value={education}
                      onChange={(e) => {
                        setEducation(e.target.value);
                      }}
                      name="education"
                      id="education"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300"
                      placeholder="Bachelors in Computer Science"
                      required
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="yearsexperience"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Experience (in years)
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="number"
                      id="experience"
                      value={experience}
                      onChange={(e) => setExperience(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300"
                      placeholder="5"
                      min="0"
                    />
                  </div>
                  {/* added here */}
                  <div className="">
                    <label
                      htmlFor="noticePeriodDays"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Official Notice Period (in days)
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="number"
                      id="noticePeriodDays"
                      value={noticePeriodDays}
                      onChange={(e) => setNoticePeriodDays(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300"
                      placeholder="Enter your notice period in days"
                      min="0"
                      max="365"
                    />
                  </div>
                  {/* added date picker here */}
                  <div className="relative">
                    <label
                      htmlFor="earlistAvailabledate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Earliest Available Start Date:
                    </label>
                    <div
                      ref={earliestDateInputRef}
                      id="earliestStartDate-input"
                      className="flex justify-between align-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 w-full p-[10px]"
                      onClick={() => {
                        if (!showEarliestDatePicker) {
                          setShowEarliestDatePicker(true);
                        }
                      }}
                    >
                      <div className="w-[100%]">
                        <input
                          className="text-sm border-none focus:ring-0 w-[100%] p-0 ml-[5px] bg-transparent placeholder-gray-300"
                          type="text"
                          id="earliestStartDate-input"
                          value={earliestStartDate}
                          onChange={handleEarliestDateInputChange}
                          maxLength="10"
                          placeholder="DD/MM/YYYY"
                          onClick={() => {
                            if (earliestDateInputRef.current) {
                              // Move the cursor to the end of the input value
                              earliestDateInputRef.current.selectionStart =
                                earliestDateInputRef.current.selectionEnd =
                                  date.length;
                            }
                          }}
                          ref={earliestDateInputRef}
                        />
                      </div>
                      <BsCalendar3
                        size={20}
                        className="mr-[10px] text-primary-600"
                      />
                    </div>
                    {showEarliestDatePicker && (
                      <div
                        className="calander-custom-style absolute z-50 block pt-2 top-30"
                        ref={earliestDatePickerRef}
                      >
                        <Datepicker
                          options={{
                            ...earliestDatePickerConfig,
                            defaultDate: parseEarliestDate(earliestStartDate),
                            icons: {
                              prev: () => (
                                <span
                                  className={`${
                                    isPrevButtonDisabled() ? 'disabled' : ''
                                  }`}
                                  onClick={(e) => {
                                    if (isPrevButtonDisabled()) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }}
                                >
                                  <FaArrowLeftLong />
                                </span>
                              ),
                              next: () => (
                                <span
                                  className={`${
                                    isNextButtonDisabled() ? 'disabled' : ''
                                  }`}
                                  onClick={(e) => {
                                    if (isNextButtonDisabled()) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }}
                                >
                                  <FaArrowRightLong />
                                </span>
                              )
                            }
                          }}
                          value={parseEarliestDate(earliestStartDate)}
                          onChange={(e) => handleSetEarliestDate(e)}
                          show={showEarliestDatePicker}
                          setShow={(e) =>
                            setTimeout(
                              () => setShowEarliestDatePicker(false),
                              200
                            )
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="sm:col-span-2 text-sm">
                    <label
                      htmlFor="Skills"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Skills
                    </label>
                    <TagsInput
                      value={skills}
                      onChange={handleSkillsChange}
                      onBlur={handleSkillsChangeBlur}
                      onKeyUp={(e) =>
                        e.key === ',' || e.code === 'Comma'
                          ? handleSkillsChangeBlur(e, true)
                          : () => {}
                      }
                      name="skills"
                      className="text-gray-900 text-sm skills-input placeholder-gray-300"
                      placeHolder="Skill ↵"
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="Current CTC"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Complete Address
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <textarea
                      id="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300"
                      placeholder="123 Main St, Springfield, IL 62701"
                    ></textarea>
                  </div>

                  <div className="sm:col-span-2">
                    {/* <LocationInput setUserLocation={setUserLocation} /> */}
                    <LocationInput
                      setUserLocation={setUserLocation}
                      setLocationSelected={setLocationSelected}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="linkedinProfileLink"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      LinkedIn URL
                    </label>
                    <input
                      type="url"
                      id="linkedinProfileLink"
                      value={linkedinProfileLink}
                      onChange={(e) => {
                        setlinkedinProfileLink(e.target.value);
                        if (
                          e.target.value &&
                          !isValidlinkedinProfileLink(e.target.value)
                        ) {
                          setlinkedinProfileLinkError(
                            'Please enter a valid LinkedIn profile URL.'
                          );
                        } else {
                          setlinkedinProfileLinkError('');
                        }
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300"
                      placeholder="https://www.linkedin.com/in/yourprofile"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center pt-5">
                  {renderSubmitbutton()}
                </div>

                {loading && (
                  <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
                    <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplyJobModal;
