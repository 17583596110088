import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';
import axios from 'axios';
import setThemeColor from './themeColors';

const Error404 = () => {
  const { orgName } = useParams();
  const [newOrgName, setNewOrgName] = useState('');

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!orgName) {
      setIsLoading(false);
      return;
    }
    // Define the function to call the API
    const validateOrganization = async () => {
      try {
        // const storedOrg = localStorage.getItem("org");
        // if (storedOrg) {
        //   const parsedOrg = JSON.parse(storedOrg);
        //   if (parsedOrg.name === orgName) {
        //     setThemeColor(parsedOrg.themeColor.toLowerCase());
        //     setIsLoading(false);
        //     return;
        //   }
        // }

        const response = await axios.get(
          `${process.env.REACT_APP_DEV_API}/v1/organizationId/${orgName}`
        );
        if (response.data.message === 'Organization retrieved successfully') {
          const orgData = {
            id: response.data.data.id,
            name: orgName,
            themeColor: response.data.data.themeColor,
            horizontalLogo:
              response.data.data?.logo?.horizontalLogoSignedUrl || ''
          };
          setNewOrgName(orgName);

          setThemeColor(response.data.data.themeColor.toLowerCase());
          localStorage.setItem('org', JSON.stringify(orgData));
          localStorage.setItem('currency', response.data.data.currency);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error validating organization:', error);
        navigate(`/${orgName}/404`);
      }
    };

    validateOrganization();
  }, [orgName, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#4885ed1a]">
      <div className="container mx-auto px-4">
        <div className="flex justify-center h-full items-center">
          <div className="w-full md:w-7/12 text-center">
            <div className="form-input-content text-center error-page">
              <h1 className="text-9xl md:text-[9.375rem] font-bold mb-2">
                404
              </h1>
              <h4 className="text-xl md:text-2xl font-medium mb-2">
                <FaExclamationTriangle className="text-yellow-400 inline-block mr-2 mb-1" />
                The page you were looking for is not found!
              </h4>
              <p className="text-base md:text-lg text-gray-500 mb-4">
                You may have mistyped the address or the page may have moved.
              </p>
              {}
              {newOrgName ? (
                <div className="mt-4">
                  <Link
                    className={`inline-block px-6 py-3 text-white  rounded-lg  ${
                      newOrgName
                        ? ' bg-primary-500 hover:bg-primary-600 '
                        : ' bg-blue-500 hover:bg-blue-600 '
                    }`}
                    to={`/${newOrgName}`}
                  >
                    Back to Home
                  </Link>
                </div>
              ) : (
                !!isLoading && (
                  <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
                    <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
