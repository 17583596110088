import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
import { addListener, launch, stop } from 'devtools-detector';

const ExamRestrictions = ({ isPasted, setIsPasted }) => {
  const [modal, setModal] = useState(null);
  const [refreshModal, setRefreshModal] = useState(null);
  const navigate = useNavigate();
  const { orgName } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const allowPaste = queryParams.get('allowPaste');

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setRefreshModal(null);
      }
    };

    if (refreshModal) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [refreshModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setModal(null);
      }
    };

    if (modal) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modal]);

  useEffect(() => {
    if (isPasted && !allowPaste) {
      setModal('Pasting');
    } /////////// TODO: Comment this before prod merge \\\\\\\\\\\\
    // if (isPasted) setModal("Pasting"); /////////// TODO: Uncomment this before prod merge \\\\\\\\\\\\
  }, [isPasted]);

  const closeModal = () => {
    setModal(null);
    if (isPasted !== undefined) {
      setIsPasted(false);
    }
  };

  let visibilityInterval = null;
  useEffect(() => {
    visibilityInterval = setInterval(() => {
      if (document.visibilityState !== 'visible') {
        setModal('Changing Window/Tab');
      }
    }, 200);

    return () => clearInterval(visibilityInterval);
  }, []);

  // Console popup
  useEffect(() => {
    addListener((isOpen) =>
      isOpen ? setModal('Opening Developer Console') : ''
    );
    launch();
    return () => stop();
  }, []);

  //check back or refresh

  useEffect(() => {
    let isReloaded = localStorage.getItem('isReloaded');
    if (isReloaded == 'true') {
      setRefreshModal(
        'Do not Refresh. Complete the test to avoid application rejection.'
      );
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.setItem('isReloaded', 'true');

      setRefreshModal(
        'Do not Refresh. Complete the test to avoid application rejection.'
      );
      event.preventDefault();
      event.returnValue = ''; // Required for Chrome
    };

    const handlePopState = (event) => {
      setRefreshModal(
        'Do not go Back. Complete the test to avoid application rejection.'
      );
      navigate(1); // Navigate forward to the current page
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);
    // window.onpopstate = handlePopState;

    window.history.pushState(null, null, window.location.pathname);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // window.removeEventListener("popstate", handlePopState);
      window.onpopstate = null;
    };
    // }, []);
  }, [navigate]);

  return (
    <>
      {modal && (
        <div className=" bg-[#00000057] z-30 fixed top-0 w-[100vw] h-[100vh] flex justify-center items-center">
          <div className="bg-white rounded-xl p-5 shadow-lg w-[80%] max-w-[500px] flex flex-col">
            <h1 className=" font-bold">Warning</h1>
            <hr className="my-4" />
            <p>{`${modal} is not allowed. This action is recorded and this will affect your final result.`}</p>
            <button
              className="w-fit self-end bg-primary-600 text-white px-3 py-2 rounded-md mt-5"
              onClick={closeModal}
            >
              Continue
            </button>
          </div>
        </div>
      )}
      {!!refreshModal && (
        <div className=" bg-[#00000057] z-30 fixed top-0 w-[100vw] h-[100vh] flex justify-center items-center">
          <div className="bg-white rounded-xl p-5 shadow-lg w-[80%] max-w-[500px] flex flex-col">
            <h1 className=" font-bold">Warning</h1>
            <hr className="my-4" />
            <p>{refreshModal}</p>
            <button
              className="w-fit self-end bg-primary-600 text-white px-3 py-2 rounded-md mt-5"
              onClick={() => {
                setRefreshModal(null);
              }}
            >
              Complete the Assessment
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ExamRestrictions;
