import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoMdAlert } from 'react-icons/io';

const RejectionPage = () => {
  const { orgName } = useParams();
  const { state } = useLocation();
  const { reasonsToShowToCandidate = [], jobId } = state || {};
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only on mount

  // const jobId = job;
  const handleHomeClick = () => {
    navigate(`/${orgName}`);
  };

  const handleBackToJobClick = () => {
    navigate(`/${orgName}/job/${jobId}`);
  };

  const isInvalidResume =
    reasonsToShowToCandidate[0] === 'Unable to analyze resume.';

  return (
    <>
      <div className="bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover h-[150px] relative bg-primary-600 z-[1]"></div>
      <div className="flex flex-col items-center min-h-screen mt-[10vh]">
        {isInvalidResume ? (
          <>
            <div className="flex items-center mb-4">
              <div className="flex items-center gap-2">
                <h1 className="text-4xl font-bold text-red-700">
                  Invalid File Uploaded
                </h1>
                <IoMdAlert className="text-4xl text-red-700" />
              </div>
            </div>
            <div className="text-lg mb-8 pt-0 pb-0 pl-[15%] pr-[15%] text-center">
              <p>
                We're sorry, but your application has not been processed because
                the file you uploaded is not a valid resume. Please ensure that
                you are uploading a resume that meets the following
                requirements:
              </p>
              <ul className="list-disc text-left mt-4 ml-8">
                <li>
                  <strong>File format</strong>: PDF
                </li>
                <li>The file size does not exceed 5 MB.</li>
                <li>
                  The document contains your professional qualifications and
                  work experience.
                </li>
              </ul>
            </div>
            {/* <img src="/path/to/invalid-resume-image.png" alt="Invalid Resume" className="mb-8 max-w-md" /> */}
          </>
        ) : (
          <>
            <h1 className="text-4xl font-bold text-red-700 mb-4">
              Application Rejected
            </h1>
            <p className="text-lg mb-8 pt-0 pb-0 pl-[15%] pr-[15%] text-center">
              We regret to inform you that your job application has been
              rejected.
            </p>
          </>
        )}

        {reasonsToShowToCandidate.length > 0 && !isInvalidResume && (
          <div
            className={`mb-8 px-5 rejection-reasons ${
              reasonsToShowToCandidate.length === 1 ? 'text-center' : ''
            }`}
          >
            <h2 className="mb-3 text-slate-600 font-bold text-xl">
              Reason{reasonsToShowToCandidate.length > 1 ? 's' : ''}
            </h2>
            {reasonsToShowToCandidate.length === 1 ? (
              <p className="p-2">{reasonsToShowToCandidate[0]}</p>
            ) : (
              reasonsToShowToCandidate.map((reason, ind) => (
                <p className="p-2" key={ind}>
                  {ind + 1}. {reason}
                </p>
              ))
            )}
          </div>
        )}

        <div className="flex space-x-4">
          <button
            onClick={handleHomeClick}
            className="px-6 py-3 bg-red-700 text-white rounded hover:bg-red-800"
          >
            Go to Home
          </button>
          {isInvalidResume && (
            <button
              onClick={handleBackToJobClick}
              className="px-6 py-3 bg-primary-600 text-white rounded hover:bg-primary-700"
            >
              Back to Job
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default RejectionPage;
