import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { useDispatch, useSelector } from 'react-redux';
import Jobcard from '../JobCard';
import { Link, useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import { CiFileOff } from 'react-icons/ci';
import noJobFound from '../../images/no-jobs-found.png';
import { useHelperBot } from '../HelperBot';

const AppliedJobs = () => {
  const { orgName } = useParams();
  const appliedJobsObj = useSelector((state) => state.appliedJobsObj);
  const appliedJobList = Object.values(appliedJobsObj) || [];
  const dispatch = useDispatch();
  const [jobList, setJobList] = useState(appliedJobList);
  const [loading, setLoading] = useState(true);
  const { helperMessage } = useHelperBot();

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  useEffect(() => {
    const fetchAllApplicationUserHaveAppliedFor = async () => {
      // Clear Redux state before fetching new data
      dispatch({ type: 'CLEAR_APPLIED_JOBS_OBJ' });

      setLoading(true);

      try {
        const session = localStorage.getItem('session');
        const accessToken = session ? JSON.parse(session).accessToken : null;

        if (!accessToken) {
          setLoading(false);
          return;
        }
        let url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/applied-applications`;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        };
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.statusCode === 200) {
          let jobsObj = {};
          data?.data?.applications.forEach((application) => {
            jobsObj[application.job] = { ...application };
          });
          dispatch({ type: 'SET_APPLIED_JOBS_OBJ', payload: jobsObj });
          setJobList(Object.values(jobsObj)); // Update jobList with the fetched data
        } else if (data.statusCode === 404) {
          setJobList([]); // Clear jobList when no applications are found
        } else {
          console.error('Failed to fetch applications :', data.message);
        }
      } catch (error) {
        console.error('Error fetching applications:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAllApplicationUserHaveAppliedFor();
    helperMessage(
      'Here’s a quick look at the jobs you’ve applied to. You can check their status, review the details with just a single click. We’re here to support you on your journey!'
    );
  }, [dispatch, orgId]);

  return (
    <>
      <div className="bg-primary-600 fixed top-50 w-full h-[6rem] "></div>
      <div className="container mx-auto pt-32 pb-8 px-5 z-[0] md:px-0 min-h-[80vh]">
        <div className="flex gap-2 items-center">
          <Link
            to={`/${orgName}`}
            className="text-gray-500 hover:text-primary-700"
          >
            <IoArrowBack size={25} />
          </Link>
          <h3 className="text-2xl font-semibold">Applied Jobs</h3>
        </div>

        <div className={'mt-8 flex flex-col gap-6'}>
          {loading ? (
            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
              <div className="w-16 h-16 border-4 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
            </div>
          ) : appliedJobList.length === 0 ? (
            <div className="flex flex-col items-center gap-5 p-5 py-8 justify-center bg-white rounded-3xl">
              {/* <CiFileOff size={150} color="#727272" /> */}
              <img src={noJobFound} alt="no-job-found" className="h-40" />
              <h2 className="mt-1 text-center text-xl font-semibold text-gray-700">
                You have not applied for any jobs
              </h2>
              <div className="flex gap-1">
                <label className="mt-1 text-xl font-semibold text-gray-500">
                  Apply for a new job
                </label>
                <Link
                  to={`/${orgName}`}
                  className="mt-1 text-xl font-semibold text-primary-600"
                >
                  here
                </Link>
              </div>
            </div>
          ) : (
            jobList
              .slice()
              .reverse()
              .map((job) => {
                return (
                  <Jobcard
                    key={job.jobData._id}
                    job={job.jobData}
                    jobId={job.job}
                    maxSalary={job.maximumSalary}
                    minSalary={job.minimumSalary}
                    appId={job._id}
                    title={job.jobData.title}
                    workLocationType={job.jobData.workLocationType}
                    jobDescriptionSummary={job.jobData.jobDescriptionSummary}
                    minimumExperience={job.jobData.minimumExperience}
                    status={job.status}
                  />
                );
              })
          )}
        </div>
      </div>
    </>
  );
};

export default AppliedJobs;
