import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone =
    destination && destination?.length ? Array.from(destination) : [];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "#f9fafb",

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '',
  padding: grid
  // width: "100%",
});

function convertArrays(arr1) {
  // Create a unique ID generator
  let idCounter = 0;
  const generateId = () => (idCounter++).toString();

  // Create a helper function to generate the objects with unique IDs
  const createObjects = (arr) =>
    arr.map((content) => ({
      id: generateId(), // Generate a unique ID
      content
    }));

  // Convert both arrays to the desired object format
  const objects1 = createObjects(arr1);

  // Return the arrays in the specified format
  return [objects1];
}

const DragAndDrop = ({ orderedOptions, setOptions, question, hasSorted }) => {
  // let initialState = convertArrays(orderedOptions);

  const [state, setState] = useState(convertArrays(orderedOptions));
  const [highlightedItem, setHighlightedItem] = useState({});

  useEffect(() => {
    setState(convertArrays(orderedOptions));
  }, [orderedOptions]);

  const highlightItem = (item) => {
    setTimeout(() => {
      setHighlightedItem(item);
    }, 100);

    setTimeout(() => {
      setHighlightedItem({});
    }, 700);
  };
  const handleSelection = (item, i, value) => {
    if (value === 'positive') {
      // if (state[1].length === 1) {
      //   toast.error("Atlease 1 item is required in negative column.");
      //   return;
      // }
      if (i === 0) {
        return;
      }
      onDragEnd({
        draggableId: `${item.id}`,
        type: 'DEFAULT',
        source: {
          index: i,
          droppableId: `${0}`
        },
        reason: 'DROP',
        mode: 'FLUID',
        destination: {
          index: i - 1,
          droppableId: `${0}`
        },
        combine: null
      });
    } else {
      // if (state[0].length === 1) {
      //   toast.error("Atlease 1 item is required in positive column.");
      //   return;
      // }
      if (i === state[0]?.length) {
        return;
      }
      onDragEnd({
        draggableId: `${item.id}`,
        type: 'DEFAULT',
        source: {
          index: i,
          droppableId: `${0}`
        },
        reason: 'DROP',
        mode: 'FLUID',
        destination: {
          index: i + 1,
          droppableId: `${0}`
        },
        combine: null
      });
    }
  };

  function onDragEnd(result) {
    // if (!(state[0]?.length !== 1)) {
    //   toast.error("Atlease 1 item is required in positive column.");
    //   return;
    // }

    // if (!(state[1]?.length !== 1)) {
    //   toast.error("Atlease 1 item is required in negative column.");
    //   return;
    // }
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
      const arr1 = newState.length && newState[0].map((item) => item.content);
      setOptions(arr1);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState);
      const arr1 = newState.length && newState[0].map((item) => item.content);
      setOptions(arr1);
      // setState(newState.filter((group) => group.length));
    }
    hasSorted();
  }

  return (
    <div>
      <div className="flex flex-row w-full">
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((el, ind) => (
            <div className="flex flex-col flex-1 w-1/2">
              {ind === 0 ? (
                <div className="px-2 gap-2 flex flex-col">
                  <div className="text-xl">{question}</div>

                  <div className="text-xs pb-8">
                    <ul>
                      Drag and drop items to sort them or click the arrows on
                      the right to move a statement up or down.
                    </ul>
                  </div>
                  {/* <h3 className="text-lg font-semibold ">
                    MOST{" "}
                    <span className="text-green-500">positive at the top</span>
                  </h3> */}
                </div>
              ) : (
                <>
                  <div className="px-2 gap-2 flex flex-col">
                    <div className="text-base">
                      Rank from{' '}
                      <span className="font-semibold">
                        MOST <span className="text-red-500">negative</span>
                      </span>{' '}
                      (top) to{' '}
                      <span className="font-semibold">
                        LEAST <span className="text-red-500">negative</span>
                      </span>{' '}
                      (bottom).
                    </div>
                    <div className="text-sm">
                      Drag and drop items to sort them in their respective lists
                      from <span className="font-semibold">MOST</span> to{' '}
                      <span className="font-semibold">LEAST</span>.
                    </div>
                    <div className="text-xs pb-8">
                      Click the arrow on the right to move a statement to the
                      positive column.
                    </div>
                    <h3 className="text-lg font-semibold ">
                      MOST{' '}
                      <span className="text-red-500">negative at the top</span>
                    </h3>
                  </div>
                </>
              )}
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                    className="w-full h-full"
                  >
                    {el.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className={`${
                                snapshot.isDragging
                                  ? 'bg-primary-200'
                                  : 'bg-gray-50'
                              } bg-gray-50 p-4 rounded-lg shadow-md space-y-2 w-full transition-color ease-linear duration-700 ${
                                highlightedItem.content === item.content
                                  ? '!bg-primary-200'
                                  : 'bg-gray-50'
                              }`}
                            >
                              <div
                                key={item}
                                className={'flex items-center justify-between '}
                              >
                                <span className="text-md">{item.content}</span>
                                <div className="flex space-x-4">
                                  <button
                                    type="button"
                                    className={`bg-red-500  text-white font-bold py-1 px-2 rounded-lg ${
                                      index === el.length - 1
                                        ? '!opacity-50 !cursor-not-allowed'
                                        : 'hover:bg-red-600'
                                    }`}
                                    onClick={() => {
                                      highlightItem(item);
                                      handleSelection(item, index, 'negative');
                                    }}
                                  >
                                    ↓
                                  </button>
                                  <button
                                    type="button"
                                    className={`bg-green-500 text-white font-bold py-1 px-2 rounded-lg ${
                                      index === 0
                                        ? '!opacity-50 !cursor-not-allowed'
                                        : 'hover:bg-green-600'
                                    }`}
                                    onClick={() => {
                                      highlightItem(item);
                                      handleSelection(item, index, 'positive');
                                    }}
                                  >
                                    ↑
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};

export default DragAndDrop;
