import React, { useEffect, useState } from 'react';

const LoaderWithText = ({
  restart,
  initialTime = 59,
  onComplete,
  showColorChange = false,
  allowBlink = true
}) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const [colorBasedOnTime, setColorBasedOnTime] = useState('default');
  const [isBlinking, setIsBlinking] = useState(false); // State to manage blinking

  useEffect(() => {
    if (isTimerCompleted) {
      onComplete();
    }
  }, [isTimerCompleted, onComplete]);

  useEffect(() => {
    if (timeLeft === 0) {
      setIsTimerCompleted(true);
      return;
    }

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    if (showColorChange) {
      if (timeLeft === 60) {
        setColorBasedOnTime('252, 104, 18');
      }
      if (timeLeft === 30) {
        setColorBasedOnTime('252, 23, 15');
        setIsBlinking(true); // Start blinking in the last 30 seconds
      }
    }

    // Blinking logic for the first 5 seconds
    if (initialTime - timeLeft < 5) {
      setIsBlinking(true);
    } else if (timeLeft < 30) {
      setIsBlinking(true);
    } else {
      setIsBlinking(false);
    }

    return () => clearInterval(timerInterval);
  }, [timeLeft, initialTime, showColorChange]);

  useEffect(() => {
    if (restart) {
      setTimeLeft(initialTime);
    }
  }, [restart, initialTime]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime =
    initialTime > 59
      ? `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      : seconds;

  // For circular progress bar
  const radius = initialTime > 59 ? 20 : 15;
  const circumference = 2 * Math.PI * radius;
  const progress = (timeLeft / initialTime) * circumference;

  return (
    <div className="flex flex-col items-center justify-center">
      <style>
        {`
          @keyframes blink {
            0%, 100% { opacity: 1; }
            50% { opacity: 0; }
          }

          .animate-blink {
            animation: blink 1s step-start infinite;
          }
        `}
      </style>
      <div className="relative flex items-center justify-center w-[24px] h-[24px]">
        <svg className="absolute" width="60" height="60">
          <circle
            className={isBlinking && allowBlink ? 'animate-blink' : ''}
            stroke={
              colorBasedOnTime === 'default'
                ? 'rgb(209 213 219)'
                : `rgb(${colorBasedOnTime}, 0.2)`
            }
            strokeWidth="5"
            fill="transparent"
            r={radius}
            cx="50%"
            cy="50%"
          />
          <circle
            className={isBlinking && allowBlink ? 'animate-blink' : ''}
            stroke={
              colorBasedOnTime === 'default'
                ? 'rgb(28 100 242)'
                : `rgb(${colorBasedOnTime})`
            }
            strokeWidth="4"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - progress}
            fill="transparent"
            r={radius}
            cx="50%"
            cy="50%"
            style={{ transition: 'stroke-dashoffset 1s linear' }}
          />
        </svg>
        <div
          className={`absolute inset-0 flex items-center justify-center text-xs ${
            isBlinking && allowBlink ? 'animate-blink' : ''
          }`}
          style={{
            color:
              colorBasedOnTime === 'default'
                ? 'black'
                : `rgb(${colorBasedOnTime})`
          }}
        >
          {formattedTime}
        </div>
      </div>
    </div>
  );
};

export default LoaderWithText;
