import {
  formatStringForFrontend,
  timeAgo,
  formatToCurrency
} from '../utils/CommonFunctions';

const jobSpecificDetails = [
  {
    name: 'workLocationType',
    displayName: 'Work Location Type',
    valueRenderingFunction: (text) => {
      return formatStringForFrontend(text);
    }
  },
  {
    name: 'experience',
    displayName: 'Experience',
    valueRenderingFunction: (min, max) => {
      if (min || min === 0) {
        if (max) {
          return `${min} - ${max} Years`;
        }
        return `${min} Years`;
      }
      return '';
    }
  },
  // {
  //   name: "minimumExperience",
  //   displayName: "Minimum Experience",
  //   valueRenderingFunction: (min, max) => {
  //     return `${min} - ${max} Years`;
  //   },
  // },
  {
    name: 'maximumSalary',
    displayName: 'Salary',
    valueRenderingFunction: (text, minSalary) => {
      return `${minSalary ? 'upto ' : ''}${formatToCurrency(text)}`;
    }
  },
  {
    name: 'location',
    displayName: 'Location',
    valueRenderingFunction: (text) => {
      return formatStringForFrontend(text);
    }
  },
  {
    name: 'jobType',
    displayName: 'Job Type',
    valueRenderingFunction: (text, workHours) => {
      return `${formatStringForFrontend(text)} (${formatStringForFrontend(
        workHours
      )})`;
    }
  },
  {
    name: 'createdAt',
    displayName: 'Posted',
    valueRenderingFunction: (createdAtData) => {
      return timeAgo(createdAtData);
    }
  }
];

export default jobSpecificDetails;
