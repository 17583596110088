import React from 'react';

const Question = ({
  index,
  questionId,
  question,
  options,
  onOptionChange,
  selectedOption
}) => {
  return (
    <div className="mt-4 p-4 border-b border-gray-300  shadow-sm bg-white select-none">
      <label className="block text-lg font-semibold text-black  mb-2">
        {index}. {question}
      </label>
      <div className="ml-2 mt-2">
        {options.map((option, index) => (
          <div key={index} className="mb-3 flex items-center">
            <input
              type="radio"
              id={`${questionId}-${index}`}
              name={questionId}
              value={option}
              checked={selectedOption === option}
              onChange={() => onOptionChange(questionId, option)}
              className="mr-2 text-primary-600 focus:ring-primary-500"
            />
            <label
              className="text-base text-black"
              htmlFor={`${questionId}-${index}`}
            >
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
