import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ allowedRoles }) => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const userRole = useSelector((state) => state.role);
  const location = useLocation();
  const { orgName } = useParams();

  const isPersonalityTestRoute = location.pathname.includes(
    `/${orgName}/personality-tests/`
  );

  if (!allowedRoles.includes(userRole)) {
    if (!isPersonalityTestRoute) {
      toast.error('You are not authorized to access this page');
      return <Navigate to="/" replace />;
    }
  }

  return <Outlet />;
};

export default ProtectedRoute;
