import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AcceptancePage = () => {
  const navigate = useNavigate();
  const { orgName } = useParams();

  const handleHomeClick = () => {
    navigate(`/${orgName}`);
  };

  return (
    <>
      <div className="bg-[url('images/headerbg.png')] bg-no-repeat bg-center bg-cover h-[150px] relative bg-primary-600 z-[1]"></div>
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-4xl font-bold text-green-700 mb-4">
          Application Accepted
        </h1>
        <p className="text-lg mb-8 pt-0 pb-0 pl-[15%] pr-[15%]">
          Congratulations! Your job application has been accepted.
        </p>
        <button
          onClick={handleHomeClick}
          className="px-6 py-3 bg-green-700 text-white rounded hover:bg-green-800"
        >
          Go to Home
        </button>
      </div>
    </>
  );
};

export default AcceptancePage;
