import { createStore } from 'redux';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('reduxState', serializedState);
  } catch {}
};

const initialState = loadState() || {
  isLoggedIn: false,
  role: null,
  appliedJobsObj: {},
  isJobModalOpen: false,
  isBotMessageVisible: true,
  isFirstRender: true
};

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN':
      const newStateAfterLogin = {
        ...state,
        isLoggedIn: true,
        role: action.payload.role
      };
      saveState(newStateAfterLogin);
      return newStateAfterLogin;
    case 'LOGOUT':
      const newStateAfterLogout = {
        ...state,
        isLoggedIn: false,
        role: null,
        appliedJobsObj: {},
        isJobModalOpen: false
      };
      saveState(newStateAfterLogout);
      return newStateAfterLogout;
    case 'SET_APPLIED_JOBS_OBJ':
      const newStateWithAppliedJobs = {
        ...state,
        appliedJobsObj: action.payload
      };
      saveState(newStateWithAppliedJobs);
      return newStateWithAppliedJobs;
    case 'CLEAR_APPLIED_JOBS_OBJ':
      const clearAppliedJobs = {
        ...state,
        appliedJobsObj: {}
      };
      saveState(clearAppliedJobs);
      return clearAppliedJobs;
    case 'SET_JOB_MODAL_OPEN':
      const newStateWithModalOpen = {
        ...state,
        isJobModalOpen: action.payload
      };
      saveState(newStateWithModalOpen);
      return newStateWithModalOpen;
    case 'SET_IS_BOT_MESSAGE_VISIBLE':
      const newStateWithBotMessage = {
        ...state,
        isBotMessageVisible: action.payload
      };
      // saveState(newStateWithBotMessage);
      return newStateWithBotMessage;
    case 'SET_IS_FIRST_RENDER':
      const newStateWithFirstRender = {
        ...state,
        isFirstRender: action.payload
      };
      // saveState(newStateWithFirstRender);
      return newStateWithFirstRender;

    default:
      return state;
  }
}

const store = createStore(loginReducer);

export default store;
