const login = (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/iam/sign-in`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email,
            password
            // role: "CANDIDATE"
          })
        }
      );

      const data = await response.json();

      if (data.status === 'success') {
        const user = data.data.user;
        if (user.role !== 'CANDIDATE') {
          reject(new Error('You are not authorized'));
        }
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('session', JSON.stringify(data.data.session));
        resolve({ status: 'LOGIN', role: user.role });
      } else {
        reject(new Error(data.message || 'An error occurred'));
      }
    } catch (error) {
      console.error('Error during login:', error);
      reject(new Error('An error occurred, please try again.'));
    }
  });
};

export default login;
