import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';

const Rating = ({ skill, rating, index, onRatingChange }) => {
  const ratingLabels = [
    'BEGINNER',
    'BASIC',
    'INTERMEDIATE',
    'ADVANCED',
    'EXPERT'
  ];
  const currentRating = ratingLabels.indexOf(rating) + 1;
  const [hoveredRating, setHoveredRating] = useState(0);
  const capitalizeFirstLetter = (skill) => {
    if (!skill) {
      return '';
    }
    return skill.charAt(0).toUpperCase() + skill.slice(1);
  };

  const handleRatingChange = (newRating) => {
    onRatingChange(ratingLabels[newRating - 1]);
  };

  return (
    <div className="rating p-2 mt-2.5 flex justify-between">
      <h5>
        {index}. {capitalizeFirstLetter(skill)}
      </h5>
      <div className="group stars flex justify-center cursor-pointer mr-1">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            onMouseEnter={() => setHoveredRating(i + 1)}
            onMouseLeave={() => setHoveredRating(0)}
            onClick={() => handleRatingChange(i + 1)}
            className="peer"
          >
            <FaStar
              size={24}
              className={`transition-colors duration-200 
                            ${i < (hoveredRating || currentRating) ? 'text-[#fcd53f]' : 'text-gray-300'}
                            ${i < hoveredRating ? 'group-hover:text-[#fcd53f]' : ''}`}
              style={{ cursor: 'pointer', marginRight: '5px' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rating;
