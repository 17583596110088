import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHelperBot } from '../HelperBot';

const LoaderPageToAnalyzeCandidate = ({ analyzingDocsGif }) => {
  const { orgName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { panel } = useParams();
  const jobId = queryParams.get('jobId');
  const appId = queryParams.get('appId');
  const testProcessId = queryParams.get('testProcessId');
  const personalityTestId = queryParams.get('personalityTestId');
  const isFinalTest = queryParams.get('isFinalTest');

  const [progress, setProgress] = useState(0);
  let fetchApplicationTimeOut = null;

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;

  useEffect(() => {
    localStorage.setItem('isReloaded', 'false');
  }, []);

  const getQueryParams = () => {
    let queryStr = '';
    if (!!jobId && !!appId && !!testProcessId) {
      queryStr += `?jobId=${jobId}&appId=${appId}&testProcessId=${testProcessId}`;
    }
    return queryStr;
  };

  const fetchApplication = async () => {
    try {
      const session = localStorage.getItem('session');
      const accessToken = session
        ? JSON.parse(localStorage.getItem('session')).accessToken
        : null;

      if (!accessToken) {
        throw new Error('Access token not found in local storage');
      }

      const url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/application/${appId}`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };

      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from API:', errorData);
        toast.error(errorData.message);
        throw new Error(errorData.message || 'Failed to fetch questions');
      }

      const data = await response.json();

      if (panel === 'MCQ') {
        setTimeout(() => {
          navigate(`/${orgName}/appliedjob/${jobId}/${appId}`);
        }, 5000);
      } else if (data.data?.application?.status === 'REJECTED') {
        navigate(`/${orgName}/appliedjob/${jobId}/${appId}`);
      } else {
        let analysisToCheck =
          panel === 'details' ? 'candidateAnalysis' : 'finalCandidateAnalysis';

        if (
          data &&
          data.data &&
          data.data.application &&
          data.data.application[`${analysisToCheck}`] &&
          (!!data.data.application[`${analysisToCheck}`]
            .profileMatchPercentage ||
            data.data.application[`${analysisToCheck}`]
              .profileMatchPercentage === 0)
        ) {
          if (data.data.application.status !== 'REJECTED') {
            if (panel === 'details') {
              navigate(`/${orgName}/questions/${jobId}/${appId}`);
            } else {
              navigate(`/${orgName}/appliedjob/${jobId}/${appId}`);
            }
          } else {
            navigate(`/${orgName}/appliedjob/${jobId}/${appId}`);
          }
        } else {
          fetchApplicationTimeOut = setTimeout(() => {
            fetchApplication();
          }, 5000);
        }
      }
    } catch (error) {
      console.error('Error fetching Questions', error);
      navigate(`/${orgName}/404`);
      toast.error(error.message || 'An error occurred');
    }
  };

  useEffect(() => {
    if (panel === 'personality-test') {
      if (!jobId || jobId === 'null') {
        setTimeout(() => {
          if (isFinalTest === 'true') {
            navigate(`/${orgName}/appreciation-personality-test`);
          } else {
            const queryStr = getQueryParams();
            navigate(
              `/${orgName}/personality-tests/${personalityTestId}${queryStr}`
            );
          }
        }, 5000);
        return;
      }
      setTimeout(() => {
        if (isFinalTest === 'true') {
          navigate(`/${orgName}/appliedjob/${jobId}/${appId}`);
        } else {
          const queryStr = getQueryParams();
          navigate(
            `/${orgName}/personality-tests/${personalityTestId}${queryStr}`
          );
        }
      }, 5000);
    } else {
      fetchApplication();
    }
  }, [panel, jobId, appId]);

  useEffect(() => {
    const navBar = document.getElementById('nav-bar');
    navBar.classList.add('bg-primary-600', 'pb-5');
    navBar.classList.remove('bg-transparent');

    // Calculate the increment value for the progress bar
    const incrementValue =
      panel === 'MCQ' ? 100 / (5000 / 50) : 100 / (40000 / 50);

    const intervalId = setInterval(() => {
      setProgress((prev) => {
        const newValue = prev + incrementValue;
        return newValue > 100 ? 100 : newValue;
      });
    }, 50);

    return () => {
      const navBar = document.getElementById('nav-bar');
      if (navBar) {
        navBar.classList.add('bg-transparent');
        navBar.classList.remove('bg-primary-600', 'pb-5');
        clearTimeout(fetchApplicationTimeOut);
        clearInterval(intervalId);
      }
    };
  }, [panel]);

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-100">
      <div className="flex flex-col justify-center items-center">
        {analyzingDocsGif && (
          <img
            src={analyzingDocsGif}
            alt="Analyzing Docs"
            className="w-1/2 mb-3"
          />
        )}
        <div className="w-full max-w-56 bg-gray-200 rounded-full h-2.5 my-4 dark:bg-gray-700">
          <div
            className="bg-primary-600 h-2.5 rounded-full w-56"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <label htmlFor="" className="font-bold">
          {panel === 'details'
            ? 'Analyzing your details. Hold Tight!'
            : "Hold Tight! We're Carefully Analyzing Your Answers."}
        </label>
      </div>
    </div>
  );
};

export default LoaderPageToAnalyzeCandidate;
