import React, { useRef, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Question from '../../../Question';
import Ratings from '../../../Ratings';
import { toast } from 'react-toastify';
import ExamRestrictions from '../../../Multichoice/Restrictions';
import GuidelinesModal from './GuidelinesModal';
import Questionnaire from './Questionnaire';
import { useHelperBot } from '../../../HelperBot';

const initialAnswers = (data) => {
  return data.map((item) => ({
    question: item.question,
    orderedOptions: [...item.options]
  }));
};

const MotivatorsQuestionnaire = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();
  const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);
  const [isDisabled, SetIsDisabled] = useState(true);
  const [isPasted, setIsPasted] = useState(false);
  const [isGuideLinesModalVisible, setIsGuideLinesModalVisible] =
    useState(true);
  const { orgName, personalityTestId } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const jobId = queryParams.get('jobId');
  const appId = queryParams.get('appId');
  const testProcessId = queryParams.get('testProcessId');
  const isFinalTest = queryParams.get('isFinalTest');

  const orgId = JSON.parse(localStorage.getItem('org'))?.id;
  // const [answer, setAnswer] = useState([]);
  const [page, setPage] = useState(0);

  const { helperMessage } = useHelperBot();
  useEffect(() => {
    let arr = [
      'Now, let’s explore what drives you! For each question, you’ll see a list of options. Just sort them from what you feel most strongly about to what matters least. No pressure, just go with your instincts!',
      'This is about what really motivates you, so be honest with yourself. You’ve got this!',
      'Keep going! These insights will help us find the roles where you’ll really thrive.'
    ];
    helperMessage(arr[Math.floor(Math.random() * arr.length)]);
  }, []);

  const nextPage = () => {
    if (page === questions.length - 1) {
      return;
    }
    setPage(page + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const previousPage = () => {
    if (page === 0) {
      return;
    }
    setPage(page - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (questions.length) {
      setAnswers(initialAnswers(questions));
    }
  }, [questions]);

  // const firstTextareaRef = useRef(null);

  useEffect(() => {}, [answers]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsGuideLinesModalVisible(false);
      }
    };

    if (isGuideLinesModalVisible) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isGuideLinesModalVisible]);

  useEffect(() => {
    let isReloaded = localStorage.getItem('isReloaded');

    if (isReloaded == 'true') {
      setIsGuideLinesModalVisible(false);
    } else {
      setIsGuideLinesModalVisible(true);
    }
  }, []);

  // const handleNext = () => {
  //   const allAnswered = answers.every((answer) => answer.trim() !== "");
  //   if (allAnswered) {
  //     setIsQuestionAnswered(true);
  //     SetIsDisabled(false);
  //     toast.success("All questions answered successfully.");
  //   } else {
  //     toast.error("Please answer all questions before proceeding.");
  //   }
  // };

  // const handleAnswerChange = (index, answer) => {
  //   const newAnswers = [...answers];
  //   newAnswers[index] = answer;
  //   setAnswers(newAnswers);
  //   SetIsDisabled(!newAnswers.every((ans) => ans.trim() !== ""));
  // };

  const getQueryParams = () => {
    let queryStr = '?';
    if (!!jobId && !!appId && !!testProcessId) {
      queryStr += `jobId=${jobId}&appId=${appId}&testProcessId=${testProcessId}&`;
    }
    return queryStr;
  };

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const session = localStorage.getItem('session');
      const accessToken = session ? JSON.parse(session).accessToken : null;

      if (!accessToken) {
        throw new Error('Access token not found in local storage');
      }

      const url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/${personalityTestId}/questionnaire?type=MOTIVATORS`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };

      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from API:', errorData);
        toast.error(errorData);
        navigate('/');
        throw new Error(errorData.message || 'Failed to fetch questionnaire');
      }

      const data = await response.json();

      if (data && data.data.length) {
        setLoading(false);

        setQuestions(data.data);
      }
    } catch (error) {
      console.error('Error fetching Questions', error);
      toast.error(error.message);
      navigate('/404');
    }
  };

  const submitAnswers = async () => {
    try {
      const session = localStorage.getItem('session');
      const accessToken = session ? JSON.parse(session).accessToken : null;

      if (!accessToken) {
        throw new Error('Access token not found in local storage');
      }

      let bodyData = {
        responses: answers
      };

      if (jobId) {
        bodyData['jobId'] = jobId;
      }
      if (testProcessId) {
        bodyData['testProcessId'] = testProcessId;
      }

      const url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/${personalityTestId}/motivators`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(bodyData)
      };

      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from API:', errorData);
        toast.error(errorData);
        throw new Error(errorData.message || 'Failed to submit answers');
      }

      const data = await response.json();

      window.scrollTo({ top: 0, behavior: 'smooth' });
      // Handle successful submission, e.g., navigate to a success page
    } catch (error) {
      console.error('Error submitting answers', error);
      toast.error(error.message);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // Handle error, e.g., navigate to an error page
    } finally {
      const queryStr = getQueryParams();
      navigate(
        `/${orgName}/analyze-candidate/personality-test${queryStr}personalityTestId=${personalityTestId}&isFinalTest=${isFinalTest}`
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchQuestions();
  }, []);

  useEffect(() => {
    const navBar = document.getElementById('nav-bar');
    if (navBar) {
      if (loading) {
        navBar.classList.add('bg-primary-600', 'pb-5');
        navBar.classList.remove('bg-transparent');
      } else {
        navBar.classList.add('bg-transparent');
        navBar.classList.remove('bg-primary-600', 'pb-5');
      }
    }
  }, [loading]);

  // useEffect(() => {
  //   if (!loading && questions.length > 0 && firstTextareaRef.current) {
  //     firstTextareaRef.current.focus();
  //   }
  // }, [loading, questions]);

  return (
    <>
      <div className="bg-[url('images/headerbg.png')] flex flex-col items-center w-full h-[45vh] bg-primary-700 px-8 py-8 md:px-16" />
      {isGuideLinesModalVisible ? (
        <>
          <GuidelinesModal
            closeModal={() => {
              setIsGuideLinesModalVisible(false);
            }}
          />
        </>
      ) : (
        <>
          <ExamRestrictions isPasted={isPasted} setIsPasted={setIsPasted} />

          <div className="container mx-auto px-5 md:px-0 -mt-[140px] rounded relative z-[0] mb-20">
            <div className="bg-gray-100 flex items-center rounded justify-center min-h-screen">
              <div className="bg-white p-8 max-sm:px-1 rounded shadow-lg w-full min-h-screen">
                <h1 className="text-2xl font-bold mb-4 max-sm:px-2">
                  Motivators Questionnaire
                </h1>

                {questions.length && answers.length && (
                  <Questionnaire
                    questionGroup={questions[page]}
                    page={page}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    totalPages={questions.length}
                    orderedOptions={answers[page]?.orderedOptions}
                    answers={answers}
                    setAnswers={setAnswers}
                    submitAnswers={submitAnswers}
                  />
                )}
              </div>
            </div>
          </div>

          {loading && (
            <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-100">
              <div className="flex flex-col justify-center items-center">
                <div className="w-16 h-16 border-4 mb-3 border-primary-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                <div>
                  <label htmlFor="" className="font-bold">
                    Loading questions for you. Hold Tight!
                  </label>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MotivatorsQuestionnaire;
