import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import jobSpecificDetails from '../../constants/jobSpecificDetails';
import { useHelperBot } from '../HelperBot';

const Jobcard = ({
  job,
  title,
  maxSalary,
  minSalary,
  workLocationType,
  jobId,
  appId,
  status,
  jobDescriptionSummary,
  minimumExperience,
  isHighlighted
}) => {
  const navigate = useNavigate();
  const { orgName } = useParams();
  const { helperMessage } = useHelperBot();

  const jobStatus =
    status === 'REJECTED'
      ? 'Rejected'
      : status === 'PRE_ASSESSMENT_PASSED'
        ? 'Accepted'
        : 'In Process';
  const textStatusColor =
    jobStatus === 'Accepted'
      ? 'text-green-700'
      : jobStatus === 'Rejected'
        ? 'text-red-700'
        : 'text-yellow-400';
  const borderStatusColor =
    jobStatus === 'Accepted'
      ? 'border-green-700'
      : jobStatus === 'Rejected'
        ? 'border-red-700'
        : 'border-yellow-400';

  const handleApplyClick = () => {
    if (status) {
      navigate(`/${orgName}/appliedjob/${jobId}/${appId}`, {
        state: {
          title,
          jobDescriptionSummary,
          job,
          workLocationType,
          jobId
        }
      });
    } else {
      navigate(`/${orgName}/job/${jobId}`);
    }
  };

  const handleResumeClick = () => {
    if (status) {
      navigate(`/${orgName}/appliedjob/${jobId}/${appId}`);
      let arr = [
        'I’m matching up your info with the job. Hang on just a little longer, we’re getting there!',
        'Still with me? Awesome! I’m just making sure you’re a great fit.',
        'Almost done! We’ll be moving on in just a sec.'
      ];
      helperMessage(arr[Math.floor(Math.random() * arr.length)]);
    }
  };

  const isResumable =
    status &&
    [
      'APPLICATION_SUBMITTED',
      'PROFILE_REVIEW_COMPLETED',
      'AI_REVIEW_COMPLETED',
      'ASSESSMENTS_IN_PROGRESS'
    ].includes(status);

  return (
    <div
      className={`rounded-xl p-6 border border-gray-300 hover:bg-gray-50 ${
        isHighlighted ? 'bg-gray-50' : 'bg-white'
      } group  transition-colors duration-500 ${
        isResumable
          ? 'shadow-[0_0_15px_rgba(59,130,246,1.5)]'
          : 'shadow-lg shadow-gray-300/50'
      }`}
    >
      <div className="flex flex-col gap-4 items-start sm:flex-row sm:items-center sm:justify-between">
        <div className="flex flex-col items-center justify-items-center sm:items-start">
          <h2
            className="mt-1 text-xl font-semibold text-gray-900 hover:underline cursor-pointer"
            onClick={handleApplyClick}
            style={{ width: 'fit-content' }}
          >
            {title}
          </h2>

          <div className="mt-4 flex items-center justify-center gap-2.5 flex-wrap sm:justify-start">
            {jobSpecificDetails.map((field, i) => {
              if (i < jobSpecificDetails.length - 1) {
                if (field.name === 'experience') {
                  const minExperience = job['minimumExperience'];
                  const maxExperience = job['maximumExperience'];

                  // Check if minExperience exists (0 or greater) and maxExperience exists (truthy value)
                  if ((minExperience || minExperience === 0) && maxExperience) {
                    return (
                      <span className="bg-gray-100 px-3 py-1 w-fit rounded text-sm text-gray-700 job-tag">
                        {field.valueRenderingFunction(
                          minExperience,
                          maxExperience
                        )}
                      </span>
                    );
                  }
                  // If only minExperience exists, render it alone
                  else if (minExperience || minExperience === 0) {
                    return (
                      <span className="bg-gray-100 px-3 py-1 w-fit rounded text-sm text-gray-700 job-tag">
                        {field.valueRenderingFunction(minExperience)}
                      </span>
                    );
                  }
                }
                return (
                  <span className="bg-gray-100 px-3 py-1 w-fit rounded text-sm text-gray-700 job-tag">
                    {field.valueRenderingFunction(
                      job[field.name],
                      field.name === 'jobType'
                        ? job['workHours']
                        : field.name === 'maximumSalary'
                          ? job['minimumSalary']
                          : field.name === 'minimumExperience'
                            ? job['maximumExperience']
                            : ''
                    )}
                  </span>
                );
              }
            })}
          </div>
        </div>
        <div className="flex justify-center sm:justify-end h-[50%] w-[100%] sm:h-[50%] sm:w-fit">
          {isResumable ? (
            <button
              onClick={handleResumeClick}
              className={`flex items-center justify-center min-w-[122px] gap-1.5 py-2.5 px-4 rounded cursor-pointer text-white transition-all duration-300 ${
                isHighlighted
                  ? 'bg-primary-700 border-primary-600'
                  : 'bg-primary-600 group-hover:bg-primary-700 group-hover:border-primary-600'
              }`}
            >
              Resume
            </button>
          ) : (
            <button
              className="flex items-center min-w-[122px] gap-1.5 py-2.5 px-4 bg-primary-600 rounded cursor-pointer group-hover:bg-primary-700 text-white group-hover:border-primary-600 transition-all duration-300"
              onClick={handleApplyClick}
            >
              View Details
            </button>
          )}
        </div>
      </div>
      <p className="mt-5 text-justify	text-gray-600">{jobDescriptionSummary}</p>
      <div
        className={`flex pt-4 items-center justify-${
          status ? 'between' : 'end'
        }`}
      >
        {status && (
          <div
            className={` rounded font-semibold px-2 h-fit border ${textStatusColor} ${borderStatusColor}`}
          >
            {jobStatus}
          </div>
        )}
        <span className="bg-transparent text-gray-400 w-fit rounded text-xs">
          Posted{' '}
          {jobSpecificDetails[
            jobSpecificDetails.length - 1
          ].valueRenderingFunction(
            job[jobSpecificDetails[jobSpecificDetails.length - 1].name]
          )}
        </span>
      </div>
    </div>
  );
};

export default Jobcard;
